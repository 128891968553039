import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';

HoursFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function HoursFilter(props) {
  const { location, history, timestamp } = props;

  const connect = useConnect();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      [1, '0'],
      [2, '1+'],
      [5, '5+'],
      [10, '10+'],
      [25, '25+'],
      [50, '50+'],
      [100, '100+']
    ].map(function(value) {
      // const weeks = Number(value[0].split('_')[1]);
      return {
        // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
        id: value[0],
        data: {
          name: value[1]
        }
      }
    })
  };

  const users = function(model) {
    const hours = {
      1: 0,
      2: 1
    }[model.id] || Number(model.id);

    return connect('user.find', {
      where: {
        eager: {
          $aggregations: [
            {
              type: 'sum',
              alias: 'numberOfHoursServed',
              field: 'hours',
              relation: 'evaluations'
            }
          ],
          $where: {
            // numberOfHoursServed: 0,
            numberOfHoursServed: model.id === 1 ? 0 : {
              $gte: hours,
            },
            isEducator: false
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Hours served"
      // dropdownLabel1="They should teach"
      // dropdownLabel2="of the following subjects:"
      queryUtils={getQueryUtils}
      filterName="hour"
      queryParamName="hours"
      tags={models}
      related={users}
      timestamp={timestamp}
    />
  );
};
