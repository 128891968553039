import React from 'react';
import PropTypes from 'prop-types';
import Frequency from './Frequency';
import Location from './Location';
import Status from './Status';
import Request from './Request';
import School from './School';
import StartDate from './StartDate';
import Community from './Community';

Filters.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Filters(props) {
  const { location, history, timestamp } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <Frequency location={location} history={history} />
          <Location location={location} history={history} />
          <Status location={location} history={history} />
          <StartDate location={location} history={history} timestamp={timestamp} />
          <Community location={location} history={history} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Request location={location} history={history} />
          <School location={location} history={history} />
        </div>
      </div>
    </div>
  );
};
