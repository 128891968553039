import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../TextField';
import Section from '../Section';

export default function Resources(props) {
  const { request } = props;

  const connect = useConnect();

  const params = {
    where: {
      eager: {
        $where: {
          requestId: request.id
        }
      }
    }
  };

  const requestUploads = useConnect('requestUpload.find', params);

  if (
    requestUploads.state === PayloadStates.FETCHING ||
    requestUploads.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Resources"
      subtitle="Documents uploaded by the educator with more information about the request."
    >
      {requestUploads.data.map(function(requestUpload) {
        const upload = connect('upload.byId', {
          id: requestUpload.data.uploadId
        });

        return (
          <TextField
            key={requestUpload.id || requestUpload.cid}
            label={upload.data.name}
          >
            <a href={upload.data.url} target="_blank">
              View
            </a>
          </TextField>
        );
      })}
    </Section>
  )
}
