import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon } from '@heroicons/react/outline';

SleepAlert.propTypes = {
  type: PropTypes.string,
  heading: PropTypes.node
};

SleepAlert.defaultProps = {
  type: 'info'
};

export default function SleepAlert(props) {
  const { title, description, action, onClick, Icon = CheckCircleIcon } = props;

  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            {title}
          </h3>
          <div className="mt-1 text-sm text-yellow-700">
            <p>
              {description}
            </p>
          </div>
          <div className="mt-6 mb-1">
            <div className="-mx-2 -my-1.5 flex">
              <button
                type="button"
                className="bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                onClick={onClick}
              >
                {action}
              </button>
              {/*<button*/}
              {/*  type="button"*/}
              {/*  className="ml-3 bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"*/}
              {/*>*/}
              {/*  Dismiss*/}
              {/*</button>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
