import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../../components/_icons/Checkmark';

export default function CheckboxField(props) {
  CheckboxField.propTypes = {
    checked: PropTypes.bool.isRequired,
    label: PropTypes.node,
    description: PropTypes.node,
    error: PropTypes.node,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
  };

  CheckboxField.defaultProps = {
    checked: false,
    disabled: false
  };

  const {
    checked,
    label,
    description,
    error,
    disabled,
    // onChange
    className
  } = props;

  // position: relative;
  // display: block;
  // min-height: 1.5rem;
  // padding-left: 1.5rem;

  function onChange() {
    props.onChange({
      target: {
        checked: !checked
      }
    });
  }

  return (
    <div className={`form-group ${className || ''}`}>
      <div className="form-check-x">
        <div className="relative flex mb-2">
          <input
            className="absolute -z-1 opacity-0"
            type="checkbox"
            checked={checked}
            onChange={props.onChange}
          />
          <div className="checkbox inline-block border border-gray-500 rounded relative cursor-pointer mr-3" onClick={onChange}>
            <div className={`checkbox-checkmark h-full ${checked ? '' : 'hidden'}`}>
              <Checkmark />
            </div>
          </div>
          <label className="cursor-pointer" onClick={onChange}>
            {label}
          </label>
        </div>
        {error ? (
          <div className="invalid-feedback">
            {error}
          </div>
        ) : null}
        {description ? (
          <small className="form-text text-muted">
            {description}
          </small>
        ) : null}
      </div>
    </div>
  );
}
