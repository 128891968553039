import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import avatar from '../../../../assets/images/avatar.svg';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Assignee(props) {
  const { request } = props;

  const connect = useConnect();

  if (!request.data.assigneeId) {
    return (
      <TextColumn />
    );
  }

  const assignee = connect('user.byId', {
    id: request.data.assigneeId
  });

  if (assignee.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={assignee.data.avatarUrl || avatar} alt=""/>
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {assignee.data.firstName} {assignee.data.lastName}
          </div>
          {/*<div className="text-sm text-gray-500">*/}
          {/*  {assignee.data.email}*/}
          {/*</div>*/}
        </div>
      </div>
    </td>
  );
}
