import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';
import StringField from '../../../forms/_fields/StringField';
import classNames from '../../../utils/classNames';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

LocationSearchField.propTypes = {
  address: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

LocationSearchField.defaultProps = {
  condition: {}
};

export default function LocationSearchField(props) {
  const {
    label,
    error,
    description,
    disabled,
    placeholder = "Enter a name or address",
    onChange,
    displayMap = true,
    className,
    name,
    location = {}
  } = props;

  const [address, setAddress] = useState(_.remove([
    location.name,
    location.address
  ]).join(', '));

  const inputRef = useRef(null);
  const inputWindowContentRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    // const map = new google.maps.Map(document.getElementById('map'));
    const map = new google.maps.Map(mapRef.current, {
      center: {
        lat: location.latitude || 37.923483,
        lng: location.longitude || -99.650695
      },
      zoom: 17
      // zoom: location.address ? 17 : 3
    });
    const input = inputRef.current;
    // const infoWindowContent = inputWindowContentRef.current;
    const marker = new google.maps.Marker({
      map: map,
      position: location.latitude ? {
        lat: location.latitude,
        lng: location.longitude
      } : undefined
      // anchorPoint: new google.maps.Point(0, -29)
    });

    const autocomplete = new google.maps.places.Autocomplete(input, {
      // types: ['address']
    });

    autocomplete.setFields([
      'place_id',
      'name',
      'formatted_address',
      'address_components',
      'geometry',
      // 'icon'
    ]);

    // const infoWindow = new google.maps.InfoWindow();
    // infoWindow.setContent(infoWindowContent);

    autocomplete.addListener('place_changed', function() {
      // infoWindow.close();
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);  // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      let address = '';
      if (place.address_components) {
        address = [
          (place.address_components[0] && place.address_components[0].short_name || ''),
          (place.address_components[1] && place.address_components[1].short_name || ''),
          (place.address_components[2] && place.address_components[2].short_name || '')
        ].join(' ');
      }

      // infoWindowContent.children['place-icon'].src = place.icon;
      // infoWindowContent.children['place-name'].textContent = place.name;
      // infoWindowContent.children['place-address'].textContent = address;
      // infoWindow.open(map, marker);

      const latlng = place.geometry.location;

      const street_number = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'street_number';
        })
      });

      const route = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'route';
        })
      });

      const locality = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'locality';
        })
      });

      const administrative_area_level_1 = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'administrative_area_level_1';
        })
      });

      const postal_code = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'postal_code';
        })
      });

      onChange({
        address: place.formatted_address,
        latitude: latlng.lat(),
        longitude: latlng.lng()
      }, place, {
        place_id: place.place_id,
        name: place.name,
        addressLine1: `${street_number && street_number.short_name || ''}${route && ` ${route.short_name}` || ''}`,
        addressLine2: '',
        addressLine3: '',
        city: locality && locality.short_name || '',
        state: administrative_area_level_1 && administrative_area_level_1.short_name || '',
        zipCode: postal_code && postal_code.short_name || ''
      });
    });


    // setTimeout(function(){
    //   $('.pac-container').prependTo("#mapMoveHere");
    // }, 300);

    // Remove the pac-container that Google fails to cleanup
    // when this component unmounts.
    return function() {
      const nodes = $('.pac-container');
      nodes.remove();
    }

  }, []);

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ): null}
      <div className={classNames(
        label ? 'mt-1' : '',
        error ? 'relative rounded-md shadow-sm' : ''
      )}>
        <input
          ref={inputRef}
          type="text"
          name={name}
          className={error ?
            'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' :
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
          }
          placeholder={placeholder}
          readOnly={disabled}
          defaultValue={address}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {error}
        </p>
      )}
      {description && (
        <p className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
      <div ref={mapRef} className={`${displayMap ? 'block' : 'hidden'} w-full mt-6`} style={{ height: '350px' }}/>
      <div id="mapMoveHere" className="relative z-50"/>
    </div>
  );
};
