import React from 'react';
import { MailIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';

export default function Schools(props) {
  const requests = useConnect('request.find', {
    pagination: {
      pageSize: 1
    }
  })

  return (
    <Metric
      name="Total count"
      stat={requests.meta ? requests.meta.totalCount : '...'}
      icon={MailIcon}
    />
  );
}
