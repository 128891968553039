import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

/**
 * The AuthenticatedRoute provides an easy way to redirect the user
 * to a login experience if we don't know who they are.
 */

import AuthenticatedRoute from './src/routes/AuthenticatedRoute';

/**
 * Routes are used to declare your view hierarchy
 * See: https://reacttraining.com/react-router/web/guides/quick-start
 */

import Login from './src/pages/Login';
import Signup from './src/pages/Signup';
import Logout from './src/pages/Logout';
import AuthCallback from './src/pages/AuthCallback';

import NotAuthorized from './src/pages/unauthorized/Layout';
import Verify from './src/pages/verify/Layout';
import NotFoundPage from './src/pages/NotFoundPage';

/*
 *  routes
 */

import DashboardOverviewLayout from './src/pages/dashboard-overview/Layout';
import TroubleshootRequestInvitationsLayout from './src/pages/troubleshoot-invitations-requests/Layout';
import TroubleshootOfferInvitationsLayout from './src/pages/troubleshoot-invitations-offers/Layout';
import TroubleshootExpiringRequestsLayout from './src/pages/troubleshoot-requests-expiring/Layout';
import TroubleshootPartnersRequestsLayout from './src/pages/troubleshoot-requests-partners/Layout';
import TroubleshootDraftRequestsLayout from './src/pages/troubleshoot-requests-draft/Layout';
import TroubleshootDraftOffersLayout from './src/pages/troubleshoot-offers-draft/Layout';
import TroubleshootIncompletePartnersLayout from './src/pages/troubleshoot-partners-incomplete/Layout';
import TroubleshootPartnerInvitationsLayout from './src/pages/troubleshoot-partners-invitations/Layout';
import TroubleshootUnresponsivePartnersLayout from './src/pages/troubleshoot-partners-unresponsive/Layout';
import TroubleshootPartnerEvaluationsLayout from './src/pages/troubleshoot-partners-evaluations/Layout';
import TroubleshootIncompleteEducatorsLayout from './src/pages/troubleshoot-educators-incomplete/Layout';
import TroubleshootEducatorInvitationsLayout from './src/pages/troubleshoot-educators-invitations/Layout';
import TroubleshootUnresponsiveEducatorsLayout from './src/pages/troubleshoot-educators-unresponsive/Layout';
import TroubleshootEducatorEvaluationsLayout from './src/pages/troubleshoot-educators-evaluations/Layout';
import AppreciateLayout from './src/pages/appreciate/Layout';
import CommunicateLayout from './src/pages/communicate/Layout';
import CoordinateLayout from './src/pages/coordinate/Layout';

import TeamStaffLayout from './src/pages/team-staff/Layout';

import ExploreEducatorsLayout from './src/pages/explore-educators/Layout';
import ExplorePartnersLayout from './src/pages/explore-partners/Layout';
import ExploreRequestsLayout from './src/pages/explore-requests/Layout';
import ExploreOffersLayout from './src/pages/explore-offers/Layout';
import ExploreConversationsLayout from './src/pages/explore-conversations/Layout';
import ExploreInteractionsLayout from './src/pages/explore-interactions/Layout';
import ExploreEducatorEvaluationsLayout from './src/pages/explore-evaluations-educators/Layout';
import ExplorePartnerEvaluationsLayout from './src/pages/explore-evaluations-partners/Layout';

import SettingsScreeningLayout from './src/pages/settings-screening/Layout';
import SettingsTrainingLayout from './src/pages/settings-training/Layout';
import SettingsBannerLayout from './src/pages/settings-banner/Layout';

export default (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/logout" component={Logout} />
    <Route path="/auth/callback" component={AuthCallback} />
    <Route path="/unauthorized" component={NotAuthorized} />
    <Route path="/verify" component={Verify} />

    <Redirect exact from="/" to="/explore/partners" />
    <Redirect exact from="/dashboard" to="/dashboard/overview" />
    <Redirect exact from="/troubleshoot" to="/troubleshoot/partners/invitations" />
    <Redirect exact from="/explore" to="/explore/requests" />
    <Redirect exact from="/explore/evaluations" to="/explore/evaluations/partners" />
    <Redirect exact from="/team" to="/team/staff" />
    <Redirect exact from="/settings" to="/settings/screening" />

    <AuthenticatedRoute exact path="/dashboard/overview" component={DashboardOverviewLayout} />
    <AuthenticatedRoute exact path="/dashboard/overview/:id/:tenant" component={DashboardOverviewLayout} />
    {/*<AuthenticatedRoute exact path="/troubleshoot" component={TroubleshootLayout} />*/}
    <AuthenticatedRoute exact path="/troubleshoot/invitations-requests" component={TroubleshootRequestInvitationsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/invitations-offers" component={TroubleshootOfferInvitationsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/requests/expiring" component={TroubleshootExpiringRequestsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/requests/draft" component={TroubleshootDraftRequestsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/requests/partners" component={TroubleshootPartnersRequestsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/offers/draft" component={TroubleshootDraftOffersLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/partners/incomplete" component={TroubleshootIncompletePartnersLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/partners/invitations" component={TroubleshootPartnerInvitationsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/partners/unresponsive" component={TroubleshootUnresponsivePartnersLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/partners/evaluations" component={TroubleshootPartnerEvaluationsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/educators/incomplete" component={TroubleshootIncompleteEducatorsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/educators/invitations" component={TroubleshootEducatorInvitationsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/educators/unresponsive" component={TroubleshootUnresponsiveEducatorsLayout} />
    <AuthenticatedRoute exact path="/troubleshoot/educators/evaluations" component={TroubleshootEducatorEvaluationsLayout} />
    <AuthenticatedRoute exact path="/appreciate" component={AppreciateLayout} />
    <AuthenticatedRoute exact path="/communicate" component={CommunicateLayout} />
    <AuthenticatedRoute exact path="/coordinate" component={CoordinateLayout} />
    <AuthenticatedRoute exact path="/team/staff" component={TeamStaffLayout} />

    <AuthenticatedRoute exact path="/explore/educators" component={ExploreEducatorsLayout} />
    <AuthenticatedRoute exact path="/explore/partners" component={ExplorePartnersLayout} />
    <AuthenticatedRoute exact path="/explore/requests" component={ExploreRequestsLayout} />
    <AuthenticatedRoute exact path="/explore/offers" component={ExploreOffersLayout} />
    <AuthenticatedRoute exact path="/explore/conversations" component={ExploreConversationsLayout} />
    <AuthenticatedRoute exact path="/explore/interactions" component={ExploreInteractionsLayout} />
    <AuthenticatedRoute exact path="/explore/evaluations/educators" component={ExploreEducatorEvaluationsLayout} />
    <AuthenticatedRoute exact path="/explore/evaluations/partners" component={ExplorePartnerEvaluationsLayout} />

    {/*<AuthenticatedRoute exact path="/settings/screening" component={SettingsScreeningLayout} />*/}
    {/*<AuthenticatedRoute exact path="/settings/training" component={SettingsTrainingLayout} />*/}
    {/*<AuthenticatedRoute exact path="/settings/banner" component={SettingsBannerLayout} />*/}

    <Route component={NotFoundPage} />
  </Switch>
);
