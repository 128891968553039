import React from 'react';
import { useConnect } from '@lore/connect';
import TextField from '../TextField';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';

export default function Subjects(props) {
  const { user } = props;

  const connect = useConnect();

  const userSubjects = connect('userSubject.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (
    userSubjects.state === PayloadStates.FETCHING ||
    userSubjects.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Subject areas"
      subtitle="I teach the following subject areas."
    >
      <div>
        {userSubjects.data.map(function(userSubject) {
          const subject = connect('subject.byId', {
            id: userSubject.data.subjectId
          });

          return (
            <TextField
              invert={true}
              showEmpty={true}
              key={userSubject.id}
              label={subject.data.name || '...'}
            >
              {userSubject.data.description}
            </TextField>
          );
        })}
      </div>
    </Section>
  )
}
