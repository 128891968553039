/**
 * This component serves as the root of your application, and should typically be the only
 * component subscribed to the store.
 *
 * It is also a good place to fetch the current user. Once you have configured 'models/currentUser'
 * to fetch the current user (by pointing it to the correct API endpoint) uncomment the commented
 * out code below in order to fetch the user, display a loading experience while they're being
 * fetched, and store the user in the applications context so that components can retrieve it
 * without having to pass it down through props or extract it from the Redux store directly.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import PayloadStates from '../constants/PayloadStates';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import { UserContext } from '@lore/auth';
import { useConfig } from '@lore/config';
import { DialogProvider } from '@lore/dialogs';
import Redirect from '../components/Redirect';
import storage from '../utils/storage';
import isBlacklistedRedirectUrl from '../utils/isBlacklistedRedirectUrl';

export default function Master(props) {
  const { history, location } = props;
  const config = useConfig();
  const user = useConnect('currentUser');

  if (user.state === PayloadStates.FETCHING) {
    return null;
  }

  if (user.state === PayloadStates.ERROR_FETCHING) {
    if (user.error && user.error.statusCode === 401) {
      const redirectUrl = `${window.location.pathname}${window.location.search}`;

      if (!isBlacklistedRedirectUrl(redirectUrl)){
        storage.set('redirectUrl', redirectUrl);
      }

      history.push('/unauthorized');
      return null;
    }

    return (
      <div>
        <RemoveLoadingScreen />
      </div>
    );
  }

  return (
    <UserContext.Provider value={user}>
      <RemoveLoadingScreen />
      <DialogProvider
        domElementId={config.dialogs.domElementId}
        templates={config.dialogs.templates}
        defaultTemplate={config.dialogs.defaultTemplate}
      >
        {(
          user.data.isEducator === null &&
          location.pathname !== '/profile-type'
        ) ? (
          <Redirect to="/profile-type" />
        ) : props.children}
      </DialogProvider>
    </UserContext.Provider>
  );
};
