import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import classNames from '../../utils/classNames';
import sidebarNavigation from '../_utils/primaryNavigationLinks';
import useRouter from '../../hooks/useRouter';
import PrimaryMenuProfile from './PrimaryMenuProfile';

export default function PrimaryNavigation(props) {
  const { location } = useRouter();

  return (
    <div className="hidden w-28 bg-primary overflow-y-auto lg:block">
      <div className="w-full py-6 flex flex-col items-center h-full">
        <div className="flex-shrink-0 flex items-center">
          <Link to="/">
            <img
              className="h-16 w-auto"
              src={Logo}
              alt="CommunityShare"
            />
          </Link>
        </div>
        <div className="flex-1 mt-6 w-full px-2 space-y-1">
          {/*<div className="border-t border-white"/>*/}
          {sidebarNavigation.map((item) => {
            const current = location.pathname.indexOf(item.href) === 0;

            return (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  current ? 'bg-accent text-white' : 'text-orange-100 hover:bg-accent hover:text-white',
                  'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                <item.icon
                  className={classNames(
                    current ? 'text-white' : 'text-orange-100 group-hover:text-white',
                    'h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="mt-2">{item.name}</span>
              </Link>
            );
          })}
        </div>
        <PrimaryMenuProfile/>
      </div>
    </div>
  )
}
