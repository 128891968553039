import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import $ from 'jquery';
import '../../../lib/bootstrap-datepicker';

DateRangeField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
};

DateRangeField.defaultProps = {
  disabled: false
};

export default function DateRangeField(props) {
  const {
    onStartDateChange,
    onEndDateChange,
  } = props;

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  useEffect(() => {
    $(startDateRef.current).datepicker({
      container: '#datepicker-anchor-left',
      forceParse: false,
      orientation: 'bottom left',
      autoclose: true,
      format: {
        toDisplay: function (date, format, language) {
          var d = new Date(date);
          return d.toISOString(true);
        },
        toValue: function (date, format, language) {
          var d = new Date(date);
          return new Date(d);
        }
      }
    }).on('changeDate', function(event) {
      const value = event.date.toISOString(true);
      onStartDateChange(value);
    });

    $(endDateRef.current).datepicker({
      container: '#datepicker-anchor-right',
      forceParse: false,
      orientation: 'bottom left',
      autoclose: true,
      format: {
        toDisplay: function (date, format, language) {
          var d = new Date(date);
          return d.toISOString(true);
        },
        toValue: function (date, format, language) {
          var d = new Date(date);
          return new Date(d);
        }
      }
    }).on('changeDate', function(event) {
      const value = event.date.toISOString(true);
      onEndDateChange(value);
    });
  }, [onStartDateChange, onEndDateChange]);

  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    startDate,
    endDate,
  } = props;

  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {label}
        </label>
      )}
      <div className="mt-1 flex rounded-md shadow-sm">
        <input
          ref={startDateRef}
          type="text"
          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
          value={startDate ? moment(startDate).format('MMM D, YYYY') : ''}
          onChange={() => {}}
        />
        <span className="inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          to
        </span>
        <input
          ref={endDateRef}
          type="text"
          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
          value={endDate ? moment(endDate).format('MMM D, YYYY') : ''}
          onChange={() => {}}
        />
      </div>
      <div id="datepicker-anchor-left" className="relative" />
      <div id="datepicker-anchor-right" className="relative" />
      <div className={`form-control ${error ? 'is-invalid' : ''}`} style={{ display: 'none'}}/>
      {error ? (
        <div className="invalid-feedback">
          {error}
        </div>
      ) : null}
      {description ? (
        <small className="form-text text-muted">
          {description}
        </small>
      ) : null}
    </div>
  );
}
