import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function Partner(props) {
  const { request } = props;

  const currentUser = useUser();
  const connect = useConnect();

  const params = {
    where: {
      eager: {
        $where: {
          requestId: request.id
        }
      }
    }
  };

  const requestGuidances = useConnect('requestGuidance.find', params);
  const requestTrainings = useConnect('requestTraining.find', params);
  const requestExpertiseTypes = useConnect('requestExpertiseType.find', params);

  const requestSubjects = useConnect('requestSubject.find', params);
  const requestGroups = useConnect('requestGroup.find', params);
  const participants = useConnect('participant.find');
  const requestParticipants = useConnect('requestParticipant.find', params);
  const groups = useConnect('group.find');
  const age = request.data.ageId ? connect('age.byId', {
    id: request.data.ageId
  }) : undefined;

  if (
    requestGuidances.state === PayloadStates.FETCHING ||
    requestTrainings.state === PayloadStates.FETCHING ||
    requestExpertiseTypes.state === PayloadStates.FETCHING ||
    requestSubjects.state === PayloadStates.FETCHING ||
    requestGroups.state === PayloadStates.FETCHING ||
    participants.state === PayloadStates.FETCHING ||
    requestParticipants.state === PayloadStates.FETCHING ||
    groups.state === PayloadStates.FETCHING ||
    (age && age.state === PayloadStates.FETCHING)
  ) {
    return null;
  }

  return (
    <Section
      title="Ideal partner"
      subtitle="The ideal partner has experience with some or all of these topics."
    >
      {requestExpertiseTypes.data.length > 0 && (
        <TextField label="Career or volunteer experience with...">
          {requestExpertiseTypes.data.map(function(requestExpertiseType) {
            const expertiseType = connect('expertiseType.byId', {
              id: requestExpertiseType.data.expertiseTypeId
            });

            return (
              <div key={expertiseType.id || expertiseType.cid}>
                {expertiseType.data.name}
              </div>
            );
          })}
        </TextField>
      )}
      {requestGuidances.data.length > 0 && (
        <TextField label="Learners would benefit from partner addressing these topics.">
          {requestGuidances.data.map(function(requestGuidance) {
            const guidance = connect('guidance.byId', {
              id: requestGuidance.data.guidanceId
            });

            return (
              <div key={guidance.id || guidance.cid}>
                {guidance.data.name}
              </div>
            );
          })}
        </TextField>
      )}
      <TextField label="Educator recommends training in the following areas.">
        {request.data.training}
      </TextField>
      <TextField label="This request would be a good fit for a group of partners.">
        {request.data.groupActivity || 'No'}
      </TextField>
    </Section>
  )
}
