import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function ExpertiseType(props) {
  const { user } = props;

  const expertiseTypes = useConnect('expertiseType.find', {
    where: {
      eager: {
        $where: {
          'expertises.users.id': user.id
        }
      }
    }
  });

  if (expertiseTypes.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {expertiseTypes.data.map(function(expertiseType) {
        return expertiseType.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
