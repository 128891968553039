import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';
import { useConnect } from '@lore/connect';

export default function Id(props) {
  const { offer } = props;

  const connect = useConnect();

  const organization = offer.data.organizationId ? connect('organization.byId', {
    id: offer.data.organizationId
  }) : {
    data: {}
  };

  return (
    <TextColumn>
      {organization.data.name}
    </TextColumn>
  );
}
