import React, { useRef, useState } from 'react';
import { useConnect } from '@lore/connect';
import Pagination from './Pagination';
import { parse, stringify } from 'query-string';
import useRouter from '../../../hooks/useRouter';
import { PayloadStates } from '@lore/utils';

export default function Table(props) {
  const {
    pageSize,
    columns,
    row,
    select,
    pageParam = 'page',
    scrollToTop = true
  } = props;

  const node = useRef();
  const { location, history } = useRouter();

  let result = select(useConnect);
  const query = parse(location.search);
  const currentPage = Number(query[pageParam] || '1');

  let [previousResult, setPreviousResult] = useState(null);

  function onNavigate(pageNumber) {
    setPreviousResult(result);

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        [`${pageParam}`]: pageNumber
      }))
    });

    if (scrollToTop) {
      const top = node.current.offsetTop;
      document.getElementById('scrollAnchor').scrollTo({ top: top, behavior: 'smooth' });
    }
  }

  const isLoading = (result.state === PayloadStates.FETCHING);

  if (!previousResult) {
    previousResult = result;
  }

  if (
    isLoading &&
    previousResult.state === PayloadStates.FETCHING
  ) {
    return (
      <div ref={node} className="hidden mt-8x sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <table className="min-w-full">
            <thead>
            <tr className="border-t border-gray-200">
              {columns.map(function(column) {
                return (
                  <th key={column.id} className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {column.name}
                  </th>
                );
              })}
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              <tr className="hover:bg-gray-100 cursor-pointer">
                {columns.map(function(column) {
                  return (
                    <td key={column.id} className="px-6 py-4 whitespace-nowrap">
                      Loading...
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  if (
    isLoading &&
    previousResult.state === PayloadStates.RESOLVED
  ) {
    result = previousResult;
  }

  return (
    <div ref={node} className="hidden mt-8x sm:block">
      <div className="align-middle inline-block min-w-full border-b border-gray-200">
        <table className="min-w-full">
          <thead>
          <tr className="border-t border-gray-200">
            {columns.map(function(column) {
              return (
                <th key={column.id} className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {column.name}
                </th>
              );
            })}
          </tr>
          </thead>
          <tbody className={`bg-white divide-y divide-gray-100 ${isLoading ? 'opacity-50' : ''}`}>
            {result.data.map(row)}
          </tbody>
        </table>
        {result.meta && (
          <Pagination
            totalCount={result.meta.totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onNavigate={onNavigate}
            count={result.data.length}
          />
        )}
      </div>
    </div>
  )
}
