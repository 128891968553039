import React from 'react';
import { CheckCircleIcon, ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import avatar from '../../../../assets/images/avatar.svg';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import UserRole from '../../../components/UserRole';

export default function Users(props) {
  const { name = '', onClick } = props;

  const words = name.split(' ');

  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          isEducator: false,
          firstName: {
            $and: [
              {
                $not: ''
              },
              {
                $not: null
              }
            ]
          },
          lastName: {
            $and: [
              {
                $not: ''
              },
              {
                $not: null
              }
            ]
          },
          $and: [
            {
              $or: words.map(function(word){
                return {
                  firstName: {
                    $ilike: `%${word}%`
                  }
                };
              }).concat(words.map(function(word){
                return {
                  lastName: {
                    $ilike: `%${word}%`
                  }
                };
              }))
            }
          ]
        }
      }
    },
    pagination: {
      pageSize: 5,
      order: 'createdAt desc'
    }
  })

  if (users.state === PayloadStates.FETCHING) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {[1,2,3,4,5].map((user, index) => (
            <li key={index}>
              <div className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-12 w-12 rounded-full object-cover" src={avatar} alt="Avatar" />
                    </div>
                    <div className="min-w-0 flex-1 px-4 ">
                      <div>
                        <p className="text-sm font-medium text-indigo-600x truncate">
                          ...
                        </p>
                        <p className="mt-1 flex items-center text-sm text-gray-500">
                          {/*<MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                          <span className="truncate">
                            ...
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (users.data.length === 0) {
    return (
      <div className="text-gray-500 text-sm">
        There are no partners with a first or last name that matches the term you provided.
      </div>
    )
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {users.data.map((user) => (
          <li key={user.id}>
            <div className="block hover:bg-gray-50 cursor-pointer" onClick={() => onClick(user)}>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-12 w-12 rounded-full object-cover" src={user.data.avatarUrl || avatar} alt="Avatar" />
                  </div>
                  <div className="min-w-0 flex-1 px-4 ">
                    <div>
                      <p className="text-sm font-medium text-indigo-600x truncate">
                        {user.data.firstName} {user.data.lastName}
                      </p>
                      <p className="mt-1 flex items-center text-sm text-gray-500">
                        {/*<MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                        <span className="truncate">
                          <UserRole user={user}>
                            {(role) => {
                              return role || user.data.email;
                            }}
                          </UserRole>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
