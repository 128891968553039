import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function Subjects(props) {
  const { offer } = props;

  const currentUser = useUser();
  const connect = useConnect();

  const params = {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  };

  const offerSubjects = useConnect('offerSubject.find', params);

  if (
    offerSubjects.state === PayloadStates.FETCHING ||
    offerSubjects.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Subjects"
      subtitle="Offer is related to these subject areas."
    >
      <TextField label="">
        {offerSubjects.data.map(function(offerSubject) {
          const subject = connect('subject.byId', {
            id: offerSubject.data.subjectId
          });

          return (
            <div key={`subject-${subject.id || subject.cid}`}>
              {subject.data.name}
            </div>
          )
        })}
      </TextField>
    </Section>
  )
}
