import React from 'react';
import {
  HomeIcon,
  ExclamationIcon,
  SparklesIcon,
  SpeakerphoneIcon,
  OfficeBuildingIcon,
  UserGroupIcon,
  MapIcon,
  CogIcon,
  CheckIcon,
  CheckCircleIcon
} from '@heroicons/react/outline';

export default [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
  { name: 'Actions', href: '/troubleshoot', icon: CheckIcon },
  // { name: 'Appreciate', href: '/appreciate', icon: SparklesIcon },
  // { name: 'Communicate', href: '/communicate', icon: SpeakerphoneIcon },
  // { name: 'Coordinate', href: '/coordinate', icon: OfficeBuildingIcon },
  { name: 'Team', href: '/team', icon: UserGroupIcon },
  { name: 'Explore', href: '/explore', icon: MapIcon },
  // { name: 'Settings', href: '/settings', icon: CogIcon }
];
