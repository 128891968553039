import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function Frequency(props) {
  const { interaction } = props;

  if (
    !interaction.data.bring &&
    !interaction.data.notes
  ) {
    return null;
  }

  return (
    <Section
      title="Extra"
      subtitle="Additional information provided by educator."
    >
      <TextField label="Bring">
        {interaction.data.bring}
      </TextField>
      <TextField label="Notes">
        {interaction.data.notes}
      </TextField>
    </Section>
  );
}
