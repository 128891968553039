import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import moment from 'moment-timezone';

ActiveFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function ActiveFilter(props) {
  const { location, history, timestamp } = props;

  const connect = useConnect();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      {
        id: 1,
        data: {
          name: 'Active'
        }
      },
      {
        id: 2,
        data: {
          name: 'Expired'
        }
      }
    ]
  };

  const related = function(model) {
    return connect('request.find', {
      where: {
        eager: {
          $where: {
            published: true,
            activityId: {
              $not: null
            },
            endDate: model.id === 1 ? {
              $gte: moment(timestamp).toISOString()
            } : {
              $lt: moment(timestamp).toISOString()
            }
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Active status"
      // dropdownLabel="It should be one of the following activities:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="active"
      queryParamName="active"
      tags={models}
      related={related}
      timestamp={timestamp}
    />
  );
};
