import React from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import Metric from '../Metric';
import { PayloadStates } from '@lore/utils';

export default function RecommendPlatform(props) {
  const { account } = props;

  const accountQuery = account ? {
    'interaction.conversation.request.accountId': account.id
  } : {};

  const evaluations = useConnect('evaluation.find', {
    where: {
      eager: {
        $where: {
          type: 'PartnerEval',
          ...accountQuery
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  const yes = _.reduce(evaluations.data, function(result, evaluation) {
    if (evaluation.data.platform === 'Yes') {
      return result + 1;
    }

    return result;
  }, 0);

  const no = _.reduce(evaluations.data, function(result, evaluation) {
    if (evaluation.data.platform === 'No') {
      return result + 1;
    }

    return result;
  }, 0);

  const total = yes + no;

  return (
    <Metric
      name="Recommend platform (partner)"
      stat={
        evaluations.state === PayloadStates.FETCHING ?
          '...' :
          total > 0 ?
            `${Math.ceil(yes/total*100)}%` :
            'No data'
      }
      icon={OfficeBuildingIcon}
    />
  );
}
