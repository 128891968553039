import React from 'react';
import Id from './Columns/Id';
import Request from './Columns/Request';
import Educator from './Columns/Educator';
import School from './Columns/School';
import Assignee from './Columns/Assignee';
import DateWindow from './Columns/DateWindow';
import DateCreated from './Columns/DateCreated';
import Partners from './Columns/Partners';
import Recommendations from './Columns/Recommendations';
import Community from './Columns/Community';

export default [
  { id: 'id',              name: 'ID',              row: Id,              selected: false },
  { id: 'community',       name: 'Community',       row: Community,       selected: false },
  { id: 'title',           name: 'Title',           row: Request,         selected: true },
  { id: 'educator',        name: 'Educator',        row: Educator,        selected: true },
  { id: 'school',          name: 'School',          row: School,          selected: true },
  { id: 'date_window',     name: 'Date window',     row: DateWindow,      selected: true },
  { id: 'partners',        name: 'Partners',        row: Partners,        selected: true },
  { id: 'recommendations', name: 'Recommendations', row: Recommendations, selected: false },
  { id: 'assignee',        name: 'Assignee',        row: Assignee,        selected: true },
  { id: 'date_created',    name: 'Date created',    row: DateCreated,     selected: false },
];
