import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function Social(props) {
  const { user } = props;

  if (
    user.data.website ||
    user.data.twitterHandle ||
    user.data.linkedInUrl
  ) {
    return (
      <Section
        title="Social Links"
        subtitle="Places where you can learn more about them."
      >
        <TextField label="Website">
          {user.data.website}
        </TextField>
        <TextField label="Twitter">
          {user.data.twitterHandle}
        </TextField>
        <TextField label="LinkedIn">
          {user.data.linkedInUrl}
        </TextField>
      </Section>
    );
  }

  return null;
}
