import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function About(props) {
  const { offer } = props;

  return (
    <Section
      // title="Personal information"
      // subtitle="Contact info and demographics."
    >
      <TextField label="Description">
        {offer.data.description || '-'}
      </TextField>
      <TextField label="Audience">
        {offer.data.audience || '-'}
      </TextField>
      <TextField label="Objective">
        {offer.data.objective || '-'}
      </TextField>
    </Section>
  );
}
