import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import About from '../../_components/sidePanel/offer/About';
import Personal from '../../_components/sidePanel/offer/Personal';
import Organizations from '../../_components/sidePanel/offer/Organizations';
import DateTime from '../../_components/sidePanel/offer/DateTime';
import Location from '../../_components/sidePanel/offer/Location';
import Subjects from '../../_components/sidePanel/offer/Subjects';
import Competencies from '../../_components/sidePanel/offer/Competencies';
import Audience from '../../_components/sidePanel/offer/Audience';
import Flexibility from '../../_components/sidePanel/offer/Flexibility';
import Website from '../../_components/sidePanel/offer/Website';
import Resources from '../../_components/sidePanel/offer/Resources';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import Conversations from './Conversations';

export default function SidePanel(props) {
  const {
    offer,
    primaryAction = '',
    secondaryAction = '',
    extraActions = [],
  } = props;

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'conversations', name: 'Conversations', selected: false },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const activity = useConnect('activity.byId', {
    id: offer.data.activityId
  })

  return (
    <Panel title="Offer" onClose={props.onClose}>
      <div>
        <Header
          src={activity.data.imageUrl}
          title={offer.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          {/*<Actions*/}
          {/*  offer={offer}*/}
          {/*  primaryAction={primaryAction}*/}
          {/*  secondaryAction={secondaryAction}*/}
          {/*  extraActions={extraActions}*/}
          {/*/>*/}
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <About offer={offer} />
            <Personal offer={offer} />
            <Organizations offer={offer} />
            <DateTime offer={offer} />
            <Location offer={offer} />
            <Audience offer={offer} />
            <Subjects offer={offer} />
            <Competencies offer={offer} />
            <Flexibility offer={offer} />
            <Website offer={offer} />
            <Resources offer={offer} />
          </div>
        )}
        {selectedTab.id === 'conversations' && (
          <div className="mb-6x">
            <Conversations offer={offer} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <div className="px-6">
              <Notes offer={offer} />
            </div>
          </div>
        )}
      </div>
    </Panel>
  )
}
