import React from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';

export default function Stage(props) {
  const { account } = props;

  const accountQuery = account ? {
    accountId: account.id
  } : {};

  const connect = useConnect();

  const stages = [
    'No invites',
    'Invited',
    'Interested',
    'Scheduling',
    'Attending'
  ];

  const stageMap = {
    'No invites': {
      numberOfConversationsInvited: 0,
      numberOfConversationsInterested: 0,
      numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: 0
    },
    'Invited': {
      numberOfConversationsInvited: {
        $gte: 1
      },
      numberOfConversationsInterested: 0,
      numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: 0
    },
    'Interested': {
      // numberOfConversationsInvited: 0,
      numberOfConversationsInterested: {
        $gte: 1
      },
      numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: 0
    },
    'Scheduling': {
      // numberOfConversationsInvited: 0,
      // numberOfConversationsInterested: 0,
      numberOfConversationsScheduling: {
        $gte: 1
      },
      numberOfConversationsAttending: 0
    },
    'Attending': {
      // numberOfConversationsInvited: 0,
      // numberOfConversationsInterested: 0,
      // numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: {
        $gte: 1
      }
    }
  }

  const requests = stages.map(function(stage) {
    return {
      label: stage,
      requests: connect('request.find', {
        where: {
          eager: {
            $aggregations: [
              'Invited',
              'Interested',
              'Scheduling',
              'Attending'
            ].map(function(stage) {
              return {
                type: 'count',
                alias: `numberOfConversations${stage}`,
                relation: 'conversations',
                $where: {
                  stage: stage
                }
              };
            }),
            $where: {
              published: true,
              activityId: {
                $not: null
              },
              ...stageMap[stage],
              ...accountQuery
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.requests.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <Chart name="Requests by Fulfillment Stage">
        <Loader />
      </Chart>
    );
  }

  const series = requests.map(r => r.requests.meta.totalCount);
  const labels = requests.map(r => r.label);

  const data = {
    series: series,
    options: {
      title: {
        text: 'Requests by Fulfillment Stage',
        align: 'center'
      },
      subtitle: {
        text: 'For all requests',
        align: 'center',
        offsetY: 20
      },
      labels: labels,
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true
              }
            }
          }
        }
      }
    }
  };

  return (
    <Chart
      // name="Request activity types"
      className="col-span-1 pb-2"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={350}
      />
    </Chart>
  );
}
