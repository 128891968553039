import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const statuses = {
    state: PayloadStates.RESOLVED,
    data: [
      {
        id: 1,
        data: {
          name: 'Attending'
        }
      },
      {
        id: 2,
        data: {
          name: 'Pending'
        }
      }
    ]
  };

  const users = function(status) {
    return connect('interaction.find', {
      where: {
        eager: {
          $where: {
            accepted: status.data.name === 'Attending' ? true : false
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Status"
      dropdownLabel="The status is:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="status"
      queryParamName="statuses"
      tags={statuses}
      related={users}
    />
  );
};
