import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function Audience(props) {
  const { request } = props;

  const currentUser = useUser();
  const connect = useConnect();

  const params = {
    where: {
      eager: {
        $where: {
          requestId: request.id
        }
      }
    }
  };

  const requestSubjects = useConnect('requestSubject.find', params);
  const requestGroups = useConnect('requestGroup.find', params);
  const participants = useConnect('participant.find');
  const requestParticipants = useConnect('requestParticipant.find', params);
  const groups = useConnect('group.find');
  const age = request.data.ageId ? connect('age.byId', {
    id: request.data.ageId
  }) : undefined;

  if (
    requestSubjects.state === PayloadStates.FETCHING ||
    requestGroups.state === PayloadStates.FETCHING ||
    participants.state === PayloadStates.FETCHING ||
    requestParticipants.state === PayloadStates.FETCHING ||
    groups.state === PayloadStates.FETCHING ||
    (age && age.state === PayloadStates.FETCHING)
  ) {
    return null;
  }

  const workingWithStudents = _.find(requestParticipants.data, model => model.data.participantId === 2);

  return (
    <Section
      title="Audience"
      subtitle="Learners the activity is designed for."
    >
      <TextField label="The partner will primarily be working with">
        {requestParticipants.data.map(function(requestParticipant) {
          const participant = _.find(participants.data, model => model.id === requestParticipant.data.participantId);

          return (
            <div key={participant.id || participant.cid}>
              {participant.data.name}
            </div>
          )
        })}
      </TextField>
      {workingWithStudents && (
        <>
          <TextField label="Age group">
            {age && age.data.name}
          </TextField>
          <TextField label="Student group size">
            {request.data.groupSize}
          </TextField>
          {requestGroups.data.length > 0 && (
            <TextField label="Students identify as...">
              {requestGroups.data.map(function(requestGroup) {
                const group = _.find(groups.data, model => model.id === requestGroup.data.groupId);

                return (
                  <div key={`group-${group.id || group.cid}`}>
                    {group.data.name}
                  </div>
                )
              })}
            </TextField>
          )}
        </>
      )}
      {requestSubjects.data.length > 0 && (
        <TextField label="Request is related to these subject areas.">
          {requestSubjects.data.map(function(requestSubject) {
            const subject = connect('subject.byId', {
              id: requestSubject.data.subjectId
            });

            return (
              <div key={`subject-${subject.id || subject.cid}`}>
                {subject.data.name}
              </div>
            )
          })}
        </TextField>
      )}
    </Section>
  )
}
