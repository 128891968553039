import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import TextField from '../TextField';
import Section from '../Section';

export default function Location(props) {
  const { request } = props;

  const currentUser = useUser();

  const params = {
    where: {
      eager: {
        $where: {
          requestId: request.id
        }
      }
    }
  };

  const interactionTypes = useConnect('interactionType.find')
  const requestInteractionTypes = useConnect('requestInteractionType.find', params)

  const locations = useConnect('location.find')
  const requestLocations = useConnect('requestLocation.find', params)

  if (
    interactionTypes.state === PayloadStates.FETCHING ||
    requestInteractionTypes.state === PayloadStates.FETCHING ||
    locations.state === PayloadStates.FETCHING ||
    requestLocations.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const isInPerson = _.find(requestInteractionTypes.data, model => model.data.interactionTypeId === 1);

  return (
    <Section
      title="Location"
      subtitle="Virtual, in-person, possible locations."
    >
      {requestInteractionTypes.data.length > 0 && (
        <TextField label="Location Type">
          {requestInteractionTypes.data.map(function(requestInteractionType) {
            const interactionType = _.find(interactionTypes.data, model => model.id === requestInteractionType.data.interactionTypeId);

            return (
              <div key={interactionType.id || interactionType.cid}>
                {interactionType.data.name}
              </div>
            )
          })}
        </TextField>
      )}
      {isInPerson && (
        <TextField label="Possible Locations">
          {requestLocations.data.map(function(requestLocation) {
            const location = _.find(locations.data, model => model.id === requestLocation.data.locationId);

            return (
              <div key={location.id || location.cid}>
                {location.data.name}
              </div>
            )
          })}
        </TextField>
      )}
    </Section>
  )
}
