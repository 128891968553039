import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function Frequency(props) {
  const { interaction } = props;

  return (
    <Section
      title="Frequency"
      subtitle="How often educator and partner will connect."
    >
      <TextField label="Frequency">
        {interaction.data.type}
      </TextField>
      {interaction.data.type === 'Recurring' && (
        <TextField label="Interval">
          {
            interaction.data.frequency === 'Other' ?
              interaction.data.customFrequency :
              interaction.data.frequency
          }
        </TextField>
      )}
    </Section>
  );
}
