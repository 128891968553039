import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Outcome(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Outcome"
      subtitle="Please rate any changes you observed with your learner(s) as a result of the interaction."
    >
      <TextField label="Learner(s) more engaged.">
        {evaluation.data.statement6}
      </TextField>
      <TextField label="Learner(s) built social-emotional skills.">
        {evaluation.data.statement7}
      </TextField>
      <TextField label="Learner(s) increased awareness of future academic and career opportunities.">
        {evaluation.data.statement8}
      </TextField>
      <TextField label="Learner(s) increased understanding of the real-world application of topics/content.">
        {evaluation.data.statement9}
      </TextField>
      <TextField label="Learner(s) acquired a new skill or mindset.">
        {evaluation.data.statement10}
      </TextField>
      <TextField label="Learner(s) practiced critical thinking, problem-solving, and/or working as a team.">
        {evaluation.data.statement11}
      </TextField>
    </Section>
  );
}
