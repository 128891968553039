import React from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';

export default function Activities(props) {
  const { account } = props;

  const accountQuery = account ? {
    accountId: account.id
  } : {};

  const connect = useConnect();

  const activities = connect('activity.find', {
    pagination: {
      pageSize: 100
    }
  });

  if (activities.state === PayloadStates.FETCHING) {
    return (
      <Chart name="Request activity types">
        <Loader />
      </Chart>
    );
  }

  const requests = _.sortBy(activities.data, a => a.data.name).map(function(activity) {
    return {
      label: activity.data.name,
      requests: connect('request.find', {
        where: {
          eager: {
            $where: {
              published: true,
              activityId: activity.id,
              ...accountQuery
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.requests.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <Chart name="Request activity types">
        <Loader />
      </Chart>
    );
  }

  const series = requests.map(function(request) {
    return {
      x: request.label,
      y: request.requests.meta.totalCount
    }
  }).filter(result => result.y > 0);
  const labels = requests.map(r => r.label);

  const data = {
    series: [
      {
        name: 'Desktops',
        // data: [
        //   {
        //     x: 'ABC',
        //     y: 10
        //   },
        //   {
        //     x: 'DEF',
        //     y: 60
        //   },
        //   {
        //     x: 'XYZ',
        //     y: 41
        //   }
        // ],
        data: series
      },
      // {
      //   name: 'Mobile',
      //   data: [
      //     {
      //       x: 'ABCD',
      //       y: 10
      //     },
      //     {
      //       x: 'DEFG',
      //       y: 20
      //     },
      //     {
      //       x: 'WXYZ',
      //       y: 51
      //     },
      //     {
      //       x: 'PQR',
      //       y: 30
      //     },
      //     {
      //       x: 'MNO',
      //       y: 20
      //     },
      //     {
      //       x: 'CDE',
      //       y: 30
      //     }
      //   ]
      // }
    ],
    options: {
      title: {
        text: 'Requests by Activity',
        align: 'center'
      },
      subtitle: {
        text: 'For all requests',
        align: 'center',
        offsetY: 20
      },
      legend: {
        show: false
      },
      // chart: {
      //   height: 350,
      //   type: 'treemap'
      // },
    }
  }

  return (
    <Chart
      // name="Request activity types"
      className="col-span-6"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="treemap"
        height={350}
      />
    </Chart>
  );
}
