import React  from 'react';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function EducatorStatements(props) {
  const { evaluation } = props;

  return (
    <SupportingTextColumn
      title={evaluation.data.platform}
      subtitle={evaluation.data.platformMessage}
    />
  );
}
