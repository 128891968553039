import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { parse, stringify } from 'query-string';
import PaginationLinks from './PaginationLinks';
import useRouter from '../../../hooks/useRouter';
import classNames from '../../../utils/classNames';

export default function MobilePagination(props) {
  const {
    totalCount,
    pageSize,
    // currentPage,
    count,
    onNavigate = () => {},
    className,
    start,
    end
  } = props;

  const currentPage = Number(props.currentPage);

  const totalPages = Math.ceil(totalCount/pageSize);

  if (totalCount === 0) {
    return null;
  }

  return (
    <div className={className}>
      <div
        className={classNames(
          'relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
          currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
        )}
        onClick={currentPage > 1 ? () => onNavigate(currentPage - 1) : null}
      >
        Previous
      </div>
      <div>
        <p className="text-sm text-gray-700">
          <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{' '}
          <span className="font-medium">{totalCount}</span>
        </p>
      </div>
      <div
        className={classNames(
          'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
          currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
        )}
        onClick={currentPage < totalPages ? () => onNavigate(currentPage + 1) : null}
      >
        Next
      </div>
    </div>
  );
}
