import React from 'react';
import Id from './Table/Id';
import User from './Table/User';
import Request from './Table/Request';
import Date from './Table/Date';
import Status from './Table/Status';
import Training from './Table/Training';
import Served from './Table/Served';
import EducatorStatements from './Table/EducatorStatements';
import PartnerStatements from './Table/PartnerStatements';
import Experience from './Table/Experience';
import Recommend from './Table/Recommend';
import Contribution from './Table/Contribution';
import Occurrences from './Table/Occurrences';
import Hours from './Table/Hours';
import Platform from './Table/Platform';
import Testimonial from './Table/Testimonial';
import Support from './Table/Support';
import Educator from './Table/Educator';
import Partner from './Table/Partner';
import PartnerPhone from './Table/PartnerPhone';
import EducatorPhone from './Table/EducatorPhone';
import Community from './Table/Community';

export default [
  { id: 'id',                  name: 'ID',                   row: Id,                 selected: false },
  { id: 'community',           name: 'Community',            row: Community,          selected: false },
  { id: 'user',                name: 'Educator',             row: User,               selected: true },
  { id: 'educator_phone',      name: 'Educator phone',       row: EducatorPhone,      selected: false },
  { id: 'partner',             name: 'Partner',              row: Partner,            selected: true },
  { id: 'partner_phone',       name: 'Partner phone',        row: PartnerPhone,       selected: false },
  { id: 'request',             name: 'Request',              row: Request,            selected: true },
  { id: 'date',                name: 'Interaction date',     row: Date,               selected: false },
  { id: 'status',              name: 'Status',               row: Status,             selected: true },
  { id: 'training',            name: 'Training',             row: Training,           selected: false },
  { id: 'served',              name: 'Number served',        row: Served,             selected: true },
  { id: 'statements_educator', name: 'Statements',           row: EducatorStatements, selected: false },
  // { id: 'statements_partner',  name: 'Statements (Partner)', row: PartnerStatements,  selected: false },
  { id: 'experience',          name: 'Experience',           row: Experience,         selected: false },
  { id: 'recommend',           name: 'Recommend partner',    row: Recommend,          selected: true },
  // { id: 'contribution',        name: 'Contributed supplies', row: Contribution,  selected: false },
  // { id: 'occurrences',         name: 'Number occurrences',   row: Occurrences,   selected: false },
  // { id: 'hours',               name: 'Total hours',          row: Hours,         selected: false },
  // { id: 'platform',            name: 'Recommend platform',   row: Platform,      selected: true },
  { id: 'testimonial',         name: 'Testimonial',          row: Testimonial,        selected: true },
  { id: 'support',             name: 'Support',              row: Support,            selected: false },
];
