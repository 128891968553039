import React  from 'react';
import TextColumn from '../TextColumn';

export default function About(props) {
  const { user } = props;

  return (
    <TextColumn>
      {user.data.about}
    </TextColumn>
  );
}
