import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ActionTypes } from '@lore/utils';
import { useConfig } from '@lore/config';
import Auth0 from 'auth0-js';
import { Helmet } from 'react-helmet';
import auth from '../utils/auth';
import ShowLoadingScreen from './ShowLoadingScreen';

export default function Logout(props) {
  const config = useConfig();

  useEffect(() => {
    auth.deleteToken();

    lore.store.dispatch({
      type: ActionTypes.RESET_STORE,
      payload: {}
    });

    const auth0 = new Auth0.WebAuth(config.auth0);
    auth0.logout({
      returnTo: window.location.origin,
      client_id: config.auth0.clientID
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Logout</title>
      </Helmet>
      <ShowLoadingScreen />
    </>
  );
};
