import _ from 'lodash';
import moment from 'moment';

const timestamp = moment().toISOString(true);

const REQUIRED_FIELDS = {
  firstName: {
    $and: [
      {
        $not: ''
      },
      {
        $not: null
      }
    ]
  },
  lastName: {
    $and: [
      {
        $not: ''
      },
      {
        $not: null
      }
    ]
  },
  biography: {
    $and: [
      {
        $not: ''
      },
      {
        $not: null
      }
    ]
  },
  zipCode: {
    $and: [
      {
        $not: ''
      },
      {
        $not: null
      }
    ]
  },
  userAges: {
    $count_gte: 1
  },
  userGrades: {
    $count_gte: 1
  },
  userActivityTypes: {
    $count_gte: 1
  },
  userSubjects: {
    $count_gte: 1
  },
  userExpertises: {
    $count_gte: 1
  },
  $or: [
    {
      // both dates are in the future (not yet sleeping)
      $and: [
        { inactiveStartingAt: { $gt: timestamp } },
        { inactiveEndingAt: { $gt: timestamp } },
      ]
    },
    {
      // both dates are in the past (no longer sleeping)
      $and: [
        { inactiveStartingAt: { $lt: timestamp } },
        { inactiveEndingAt: { $lt: timestamp } },
      ]
    }
  ]
}

export default REQUIRED_FIELDS;

export const REQUIRED_EDUCATOR_FIELDS = _.omit(REQUIRED_FIELDS, [
  'userActivityTypes',
  'userAges',
  'userExpertises'
])

export const REQUIRED_EDUCATOR_FIELDS_TUCSON = _.omit(REQUIRED_FIELDS, [
  'userActivityTypes',
  'userAges',
  'userExpertises',
  'userSubjects'
])

export const REQUIRED_PARTNER_FIELDS = _.omit(REQUIRED_FIELDS, [
  'userSubjects',
  'userGrades'
])

export const REQUIRED_PARTNER_FIELDS_TUCSON = _.omit(REQUIRED_FIELDS, [
  'userSubjects',
  'userGrades',
  'userExpertises'
])
