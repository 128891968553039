import React  from 'react';
import StageTag from './StageTag';

export default function Status(props) {
  const { conversation } = props;

  // const stageMap = {
  //   Invited: 'bg-yellow-100 text-yellow-800',
  //   Interested: 'bg-blue-100 text-blue-800',
  //   Scheduling: 'bg-purple-100 text-purple-800',
  //   Attending: 'bg-green-100 text-green-800',
  //   Declined: 'bg-red-100 text-red-800',
  //   Passed: 'bg-red-100 text-red-800'
  // };

  // const color = stageMap[conversation.data.stage] || 'bg-gray-100 text-gray-800';

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-500">
        <StageTag model={conversation} />
        {/*<span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${color}`}>*/}
        {/*  {conversation.data.stage}*/}
        {/*</span>*/}
      </div>
    </td>
  );
}
