import React from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';

export default function ActivityTypes(props) {
  const { account } = props;

  const accountQuery = account ? {
    accountId: account.id
  } : {};

  const connect = useConnect();

  const activityTypes = connect('activityType.find');

  if (activityTypes.state === PayloadStates.FETCHING) {
    return (
      <Chart name="Request activity types">
        <Loader />
      </Chart>
    );
  }

  const requests = _.sortBy(activityTypes.data, at => at.data.name).map(function(activityType) {
    return {
      label: activityType.data.name,
      requests: connect('request.find', {
        where: {
          eager: {
            $where: {
              published: true,
              // 'activity.activityActivityType.activityTypeId': activityType.id,
              'activityTypes.id': activityType.id,
              ...accountQuery
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.requests.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <Chart name="Requests by Activity Type">
        <Loader />
      </Chart>
    );
  }

  const series = requests.map(r => r.requests.meta.totalCount);
  const labels = requests.map(r => r.label);

  const data = {
    series: series,
    options: {
      title: {
        text: 'Requests by Activity Type',
        align: 'center'
      },
      subtitle: {
        text: 'For all requests',
        align: 'center',
        offsetY: 20
      },
      labels: labels,
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true
              }
            }
          }
        }
      }
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     chart: {
      //       width: 200
      //     },
      //     legend: {
      //       position: 'bottom'
      //     }
      //   }
      // }]
    }
  };

  return (
    <Chart
      // name="Request activity types"
      className="col-span-1 pb-2"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={350}
      />
    </Chart>
  );
}
