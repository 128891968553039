import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import moment from 'moment-timezone';

StartDateFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function StartDateFilter(props) {
  const { location, history, timestamp } = props;

  const connect = useConnect();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      [-4, 'Within past 1 month'],
      [-2, 'Within past 2 weeks'],
      [-1, 'Within past 1 week'],
      [1,  'Within next 1 week'],
      [2,  'Within next 2 weeks'],
      [4,  'Within next 1 month']
    ].map(function(value) {
      return {
        id: value[0],
        data: {
          name: value[1]
        }
      }
    })
  };

  const users = function(model) {
    const weeks = model.id;

    return connect('interaction.find', {
      where: {
        eager: {
          $where: {
            startDate: weeks > 0 ? {
              $gte: moment(timestamp).toISOString(),
              $lte: moment(timestamp).add(weeks, 'weeks').toISOString()
            } : {
              $gte: moment(timestamp).add(weeks, 'weeks').toISOString(),
              $lte: moment(timestamp).toISOString()
            }
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Start date"
      // dropdownLabel1="They should teach"
      // dropdownLabel2="of the following subjects:"
      queryUtils={getQueryUtils}
      filterName="startDate"
      queryParamName="startDates"
      tags={models}
      related={users}
      timestamp={timestamp}
    />
  );
};
