import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), [
    'accounts',
    'educator',
    'partner',
    'stages',
    'title'
  ], timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      <Tags
        type="Community"
        query={searchParams.accounts}
        models={connect('account.find', params)}
        onChange={onChangeArray('accounts', { location, history })}
        label={tag => tag.data.subdomain}
      />
      <Tag
        type="Educator"
        query={searchParams.educator}
        onChange={onChange('educator', { location, history })}
      />
      <Tag
        type="Partner"
        query={searchParams.partner}
        onChange={onChange('partner', { location, history })}
      />
      <Tags
        type="Stage"
        query={searchParams.stages}
        models={mapArray([
          'Invited',
          'Interested',
          'Scheduling',
          'Attending',
          'Declined',
          'Passed'
        ])}
        onChange={onChangeArray('stages', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
    </div>
  );
};
