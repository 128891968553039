import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

TagChip.propTypes = {
  tag: PropTypes.object.isRequired
};

export default function TagChip(props) {
  const {
    label,
    tooltip,
    tag,
    selected = false,
    onClick,
    onCancel,
    showType,
    small,
    type
  } = props;

  const colorMap = {
    Age: {
      border: 'border-red-600',
      button: 'text-red-600 hover:bg-red-200 hover:text-red-500 focus:bg-red-500'
    },
    Expertise: {
      border: 'border-purple-600',
      button: 'text-purple-600 hover:bg-purple-200 hover:text-purple-500 focus:bg-purple-500'
    },
    ExpertiseType: {
      border: 'border-purple-600',
      button: 'text-purple-600 hover:bg-purple-200 hover:text-purple-500 focus:bg-purple-500'
    },
    Subject: {
      border: 'border-purple-600',
      button: 'text-purple-600 hover:bg-purple-200 hover:text-purple-500 focus:bg-purple-500'
    },
    Activity: {
      border: 'border-green-600',
      button: 'text-green-600 hover:bg-green-200 hover:text-green-500 focus:bg-green-500'
    },
    ActivityType: {
      border: 'border-green-600',
      button: 'text-green-600 hover:bg-green-200 hover:text-green-500 focus:bg-green-500'
    },
    Group: {
      border: 'border-orange-600',
      button: 'text-orange-600 hover:bg-orange-200 hover:text-orange-500 focus:bg-orange-500'
    },
    Experience: {
      border: 'border-blue-600',
      button: 'text-blue-600 hover:bg-blue-200 hover:text-blue-500 focus:bg-blue-500'
    },
    Practice: {
      border: 'border-yellow-600',
      button: 'text-yellow-600 hover:bg-yellow-200 hover:text-yellow-500 focus:bg-yellow-500'
    },
    Guidance: {
      border: 'border-pink-600',
      button: 'text-pink-600 hover:bg-pink-200 hover:text-pink-500 focus:bg-pink-500'
    },
  };

  const color = colorMap[tag.data.type] || {
    border: 'border-gray-600',
    button: 'text-gray-600 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500'
  };

  if (type) {
    return (
      <div className="inline-block mr-2">
        <div className="px-4 text-gray-700 font-normal text-xs mb-0.5">
          {type}
        </div>
        <span className={`inline-flex rounded-full items-center py-1 pl-3.5 pr-1 text-sm font-medium border ${color.border} text-gray-700`}>
          {label || tag.data.name}
          <button
            type="button"
            className={`flex-shrink-0 ml-0.5 h-6 w-6 rounded-full inline-flex items-center justify-center ${color.button} focus:outline-none focus:text-white`}
            onClick={onClick}
          >
            <span className="sr-only">Remove</span>
            <svg className="h-2.5 w-2.5" stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          </button>
        </span>
      </div>
    );
  }

  return (
    <span className={`mr-2 inline-flex rounded-full items-center py-1 pl-2.5 pr-1 text-sm font-medium border ${color.border} text-gray-700`}>
      {label || tag.data.name}
      <button
        type="button"
        className={`flex-shrink-0 ml-0.5 h-6 w-6 rounded-full inline-flex items-center justify-center ${color.button} focus:outline-none focus:text-white`}
        onClick={onClick}
      >
        <span className="sr-only">Remove</span>
        <svg className="h-2.5 w-2.5" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
};
