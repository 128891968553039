import React  from 'react';
import { useConnect } from '@lore/connect';

export default function Place(props) {
  const { interaction } = props;

  const connect = useConnect();

  const place = interaction.data.placeId ? connect('place.byId', {
    id: interaction.data.placeId
  }) : { data: {} };

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-500 line-clamp-1">
        {interaction.data.location === 'In-Person' ?
          place.data.name :
          interaction.data.location
        }
      </div>
    </td>
  );
}
