import React from 'react';

export default function PrimaryAction(props) {
  const { onClick, children } = props;

  return (
    <button
      type="button"
      className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
