import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';

AssigneeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AssigneeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const models = connect('user.find', {
    where: {
      eager: {
        $where: {
          isStaff: true
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'firstName asc'
    }
  });

  const relatedModels = function(user) {
    return connect('request.find', {
      where: {
        eager: {
          $where: {
            assigneeId: user.id
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Assignee"
      // dropdownLabel1="It should be focused on serving learners in"
      // dropdownLabel2="of the following groups:"
      queryUtils={getQueryUtils}
      filterName="assignee"
      queryParamName="assignees"
      tags={models}
      related={relatedModels}
      display={function(model) {
        if (model.data.firstName || model.data.lastName) {
          return `${model.data.firstName} ${model.data.lastName}`;
        }

        return model.data.email;
      }}
    />
  );
};
