import React from 'react';
import { useDialog } from '@lore/dialogs';
import CreateNoteDialog from '../../../dialogs/requestNote/create';
import PlaceholderDialog from '../../../dialogs/Placeholder';
import Actions from '../../_components/actions/Actions';
import PrimaryAction from '../../_components/actions/PrimaryAction';
import SecondaryAction from '../../_components/actions/SecondaryAction';

export default function _Actions(props) {
  const { request } = props;

  const show = useDialog();

  function onPlaceholder() {
    show(
      <PlaceholderDialog>
        Show an input field where you can type in the name of a request and
        select a request to recommend to that partner.
      </PlaceholderDialog>, { template: 'dialog' }
    );
  }

  function onCreateNote() {
    show(
      <CreateNoteDialog
        request={request}
      />, { template: 'dialog' }
    );
  }

  return (
    <Actions>
      <PrimaryAction onClick={onPlaceholder}>
        Recommend to partner
      </PrimaryAction>
      <SecondaryAction onClick={onCreateNote}>
        Leave note
      </SecondaryAction>
    </Actions>
  );
}
