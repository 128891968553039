import React  from 'react';
import { useConnect } from '@lore/connect';
import TextColumn from '../../_components/table/columns/TextColumn';
import { PayloadStates } from '@lore/utils';

export default function Recommendations(props) {
  const { request } = props;

  const recommendations = useConnect('recommendation.find', {
    where: {
      eager: {
        $where: {
          requestId: request.id
        }
      }
    }
  });

  if (recommendations.state === PayloadStates.FETCHING) {
    return (
      <TextColumn loading={true} />
    );
  }

  return (
    <TextColumn>
      {recommendations.data.length}
    </TextColumn>
  );
}
