import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Age(props) {
  const { user } = props;

  const connect = useConnect();

  const userAges = connect('userAge.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (userAges.state === PayloadStates.FETCHING) {
    return (
      <TextColumn loading={true} />
    );
  }

  return (
    <TextColumn>
      {userAges.data.map(function(userAge) {
        const age = connect('age.byId', {
          id: userAge.data.ageId
        });

        return age.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
