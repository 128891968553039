export default {

  /**
   * Email Addresses
   */

  email: {
    support: 'support@communityshare.us',
    team: 'team@communityshare.us'
  }

};
