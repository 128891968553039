import React from 'react';
import { useDialog } from '@lore/dialogs';
import Actions from '../../_components/actions/Actions';
import PrimaryAction from '../../_components/actions/PrimaryAction';
import SecondaryAction from '../../_components/actions/SecondaryAction';
import ExtraActions from '../../_components/actions/ExtraActions';
import PlaceholderDialog from '../../../dialogs/Placeholder';
import CompleteProfileReminder from '../../../dialogs/user/CompleteProfileReminder';
import ContactUserDialog from '../../../dialogs/user/contact';
import SleepUserDialog from '../../../dialogs/user/sleep';

export default function _Actions(props) {
  const {
    user,
    primaryAction = '',
    secondaryAction = '',
    extraActions = []
  } = props;

  const show = useDialog();

  function showPlaceholder(text) {
    show(
      <PlaceholderDialog>
        {text}
      </PlaceholderDialog>, { template: 'dialog' }
    );
  }

  const actionMap = {
    'complete_profile': {
      label: 'Send reminder to complete profile',
      callback: function() {
        show(
          <CompleteProfileReminder
            user={user}
          />, { template: 'dialog' }
        );
      }
    },
    'contact': {
      label: 'Contact',
      callback: function() {
        show(
          <ContactUserDialog
            user={user}
          />, { template: 'dialog' }
        );
      }
    },
    'sleep_profile': {
      label: 'Sleep profile',
      callback: function() {
        show(
          <SleepUserDialog
            user={user}
          />, { template: 'dialog' }
        );
      }
    }
  }

  const actions = {
    primary: actionMap[primaryAction],
    secondary: actionMap[secondaryAction],
    extra: extraActions.map(function(extraAction) {
      return actionMap[extraAction];
    })
  }

  return (
    <Actions>
      {actions.primary && (
        <PrimaryAction onClick={actions.primary.callback}>
          {actions.primary.label}
        </PrimaryAction>
      )}
      {actions.secondary && (
        <SecondaryAction onClick={actions.secondary.callback}>
          {actions.secondary.label}
        </SecondaryAction>
      )}
      {actions.extra.length > 0 && (
        <ExtraActions
          actions={actions.extra.map(function(action) {
            return {
              name: action.label,
              onClick: action.callback
            }
          })}
        />
      )}
    </Actions>
  );
}
