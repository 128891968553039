import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import User, { UserLoader } from './User';

export default function Partner(props) {
  const { evaluation } = props;

  const connect = useConnect();

  const interaction = connect('interaction.byId', {
    id: evaluation.data.interactionId
  });

  if (interaction.state === PayloadStates.FETCHING) {
    return (
      <UserLoader />
    );
  }

  const conversation = connect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <UserLoader />
    );
  }

  return (
    <User
      evaluation={{
        data: {
          userId: conversation.data.partnerId
        }
      }}
    />
  );
}
