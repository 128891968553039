import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function Audience(props) {
  const { offer } = props;

  const currentUser = useUser();
  const connect = useConnect();

  const params = {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  };

  const offerSubjects = useConnect('offerSubject.find', params);
  const offerGroups = useConnect('offerGroup.find', params);
  const offerAges = useConnect('offerAge.find', params);
  const offerSizes = useConnect('offerSize.find', params);
  const participants = useConnect('participant.find');
  const offerParticipants = useConnect('offerParticipant.find', params);
  const groups = useConnect('group.find');
  const ages = useConnect('age.find');
  const sizes = useConnect('size.find');

  if (
    offerSubjects.state === PayloadStates.FETCHING ||
    offerGroups.state === PayloadStates.FETCHING ||
    offerAges.state === PayloadStates.FETCHING ||
    participants.state === PayloadStates.FETCHING ||
    offerParticipants.state === PayloadStates.FETCHING ||
    groups.state === PayloadStates.FETCHING ||
    ages.state === PayloadStates.FETCHING ||
    sizes.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const workingWithStudents = _.find(offerParticipants.data, model => model.data.participantId === 2);

  return (
    <Section
      title="Audience"
      subtitle="Learners the activity is designed for."
    >
      <TextField label="This offer is primarily for">
        {offerParticipants.data.map(function(offerParticipant) {
          const participant = _.find(participants.data, model => model.id === offerParticipant.data.participantId);

          return (
            <div key={participant.id || participant.cid}>
              {participant.data.name}
            </div>
          )
        })}
      </TextField>
      {workingWithStudents && (
        <>
          {offerAges.data.length > 0 && (
            <TextField label="Age groups">
              {offerAges.data.map(function(offerAge) {
                const age = _.find(ages.data, model => model.id === offerAge.data.ageId);

                return (
                  <div key={`age-${age.id || age.cid}`}>
                    {age.data.name}
                  </div>
                )
              })}
            </TextField>
          )}
          {offerGroups.data.length > 0 && (
            <TextField label="Student groups">
              {offerGroups.data.map(function(offerGroup) {
                const group = _.find(groups.data, model => model.id === offerGroup.data.groupId);

                return (
                  <div key={`group-${group.id || group.cid}`}>
                    {group.data.name}
                  </div>
                )
              })}
            </TextField>
          )}
          {offerSizes.data.length > 0 && (
            <TextField label="Size of group">
              {offerSizes.data.map(function(offerSize) {
                const size = _.find(sizes.data, model => model.id === offerSize.data.sizeId);

                return (
                  <div key={`size-${size.id || size.cid}`}>
                    {size.data.name}
                  </div>
                )
              })}
            </TextField>
          )}
        </>
      )}
      {offerSubjects.data.length > 0 && (
        <TextField label="Offer is related to these subject areas.">
          {offerSubjects.data.map(function(offerSubject) {
            const subject = connect('subject.byId', {
              id: offerSubject.data.subjectId
            });

            return (
              <div key={`subject-${subject.id || subject.cid}`}>
                {subject.data.name}
              </div>
            )
          })}
        </TextField>
      )}
      {offer.data.minNumberParticipants && offer.data.maxNumberParticipants && (
        <TextField label="Min/max number of participants allowed">
          {offer.data.minNumberParticipants} - {offer.data.maxNumberParticipants}
        </TextField>
      )}
    </Section>
  )
}
