import React from 'react';
import { useConnect } from '@lore/connect';
import TextField from '../TextField';
import Section from '../Section';

export default function Organizations(props) {
  const { user } = props;

  const connect = useConnect();

  const userOrganizations = connect('userOrganization.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (userOrganizations.data.length === 0) {
    return null;
  }

  return (
    <Section
      title="Work"
      subtitle="Organizational affiliations."
    >
      {userOrganizations.data.map(function(userOrganization) {
        const organization = connect('organization.byId', {
          id: userOrganization.data.organizationId
        });

        return (
          <TextField
            invert={true}
            key={userOrganization.id}
            label={organization.data.name || '...'}
          >
            {userOrganization.data.role}
          </TextField>
        )
      })}
    </Section>
  )
}
