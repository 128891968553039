import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../_common/FormField';
import OutlineButton from '../../components/buttons/OutlineButton';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import TextAreaField from '../../forms/_fields/TextAreaField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <>
      {alert && (
        <div className="mb-4">
          {alert}
        </div>
      )}
      <FormField name="text" data={data} errors={showErrors && errors} onChange={onChange}>
        {(field) => (
          <TextAreaField
            className="sm:col-span-6"
            label="Leave a note"
            placeholder="Write something..."
            // description="Some text about a thing I like"
            rows={3}
            value={field.value}
            error={field.error}
            onChange={field.onChange}
          />
        )}
      </FormField>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <PrimaryButton onClick={onSubmit}>
          Save note
        </PrimaryButton>
      </div>
    </>
  );
}
