import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../dashboard/Layout';
import Metrics from './Metrics';
import Charts from './Charts';
import TotalEducators from './stats/TotalEducators';
import CompleteEducators from './stats/CompleteEducators';
import RequestEducators from './stats/RequestEducators';
import RequestPublishedEducators from './stats/RequestPublishedEducators';
import TotalPartners from './stats/TotalPartners';
import CompletePartners from './stats/CompletePartners';
import Organizations from './stats/Organizations';
import Schools from './stats/Schools';
import SupportedSchools from './stats/SupportedSchools';
import TotalRequests from './stats/TotalRequests';
import PublishedRequests from './stats/PublishedRequests';
import DraftRequests from './stats/DraftRequests';
import OfferPublishedPartners from './stats/OfferPublishedPartners';
import OfferPartners from './stats/OfferPartners';
import ConversationPartners from './stats/ConversationPartners';
import ConversationRespondedPartners from './stats/ConversationRespondedPartners';
import InitiatedConversationPartners from './stats/InitiatedConversationPartners';
import ContributionsPartners from './stats/ContributionsPartners';
import EducatorEvaluations from './stats/EducatorEvaluations';
import PartnerEvaluations from './stats/PartnerEvaluations';
import StudentsServed from './stats/StudentsServed';
import RecommendPlatform from './stats/RecommendPlatform';
import ActivityTypes from './charts/ActivityTypes';
import Activity from './charts/Activity';
import ActivityByType from './charts/ActivityByType';
import Stage from './charts/Stage';
import Educators from './charts/Educators';
import Partners from './charts/Partners';
import Requests from './charts/Requests';
import useAccount from '../../hooks/useAccount';
import { useConnect } from '@lore/connect';
import PayloadStates from '../../constants/PayloadStates';
import Loader from '../_components/Loader';
import AccountSwitcher from './AccountSwitcher';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  // const accounts = useConnect('account.find');
  //
  // const { tenant, account } = useAccount(accounts);
  //
  // if (accounts.state === PayloadStates.FETCHING) {
  //   return (
  //     <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
  //       <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
  //         <PageHeading
  //           breadcrumbs={['Dashboard']}
  //           title="Overview"
  //         />
  //         <div className="px-2 py-4 sm:px-3 lg:px-4">
  //           <Loader />
  //         </div>
  //       </main>
  //     </ParentLayout>
  //   );
  // }

  const { location, match } = useRouter();

  const account = match.params.id ? useConnect('account.byId', {
    id: Number(match.params.id)
  }) : undefined;

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Dashboard']}
          title="Overview"
        >
          {/*<div className="ml-4 mt-4 flex-shrink-0 relative z-50">*/}
          {/*  <AccountSwitcher />*/}
          {/*</div>*/}
        </PageHeading>
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        <div className="px-2 py-4 sm:px-3 lg:px-4">
          <Metrics title="Educators">
            <Educators account={account} />
            {/*<TotalEducators />*/}
            {/*<CompleteEducators />*/}
            {/*<RequestEducators />*/}
            {/*<RequestPublishedEducators />*/}
          </Metrics>
          <Metrics title="Partners">
            <Partners account={account} />
          </Metrics>
          <Metrics>
            {/*<Partners />*/}
            {/*<TotalPartners />*/}
            {/*<CompletePartners />*/}
            <OfferPartners account={account} />
            <OfferPublishedPartners account={account} />
            <ConversationPartners account={account} />
            {/*<ConversationRespondedPartners />*/}
            <InitiatedConversationPartners account={account} />
            <ContributionsPartners account={account} />
          </Metrics>
          <Metrics title="Requests">
            <Requests account={account} />
            <ActivityTypes account={account} />
            <Stage account={account} />
            <Activity account={account} />
            <ActivityByType account={account} />
            {/*<TotalRequests />*/}
            {/*<PublishedRequests />*/}
            {/*<DraftRequests />*/}
          </Metrics>
          <Metrics title="Schools">
            <Schools account={account} />
            <SupportedSchools account={account} />
          </Metrics>
          <Metrics title="Organizations">
            <Organizations account={account} />
          </Metrics>
          <Metrics title="Evaluations">
            <EducatorEvaluations account={account} />
            <PartnerEvaluations account={account} />
            <StudentsServed account={account} />
            <RecommendPlatform account={account} />
          </Metrics>
        </div>
      </main>
    </ParentLayout>
  );
}
