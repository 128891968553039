import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function Social(props) {
  const { user } = props;

  return (
    <Section
      // title="Personal information"
      // subtitle="Contact info and demographics."
    >
      <TextField label="About">
        {user.data.biography || '-'}
      </TextField>
      <TextField label="Motivation">
        {user.data.motivation || '-'}
      </TextField>
    </Section>
  );
}
