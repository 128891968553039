import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Audience(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Audience"
      // subtitle="Additional information provided by educator."
    >
      <TextField label="How many learners or students did this partner interact with?">
        {evaluation.data.served}
      </TextField>
    </Section>
  );
}
