import React from 'react';
import { XIcon } from '@heroicons/react/outline';

export default function Panel(props) {
  const {
    onClose,
    title,
    children
  } = props;

  return (
    <div className="w-screen max-w-md">
      <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
        <div className="px-4 py-6 sm:px-6">
          <div className="flex items-start justify-between">
            <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
              {title}
            </h2>
            <div className="ml-3 h-7 flex items-center">
              <button
                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                onClick={onClose}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        {/* Main */}
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}
