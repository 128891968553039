import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../TextField';
import Section from '../Section';

export default function Resources(props) {
  const { offer } = props;

  const connect = useConnect();

  const params = {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  };

  const offerUploads = useConnect('offerUpload.find', params);

  if (
    offerUploads.state === PayloadStates.FETCHING ||
    offerUploads.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Resources"
      subtitle="Documents uploaded by the partner with more information about the offer."
    >
      {offerUploads.data.map(function(offerUpload) {
        const upload = connect('upload.byId', {
          id: offerUpload.data.uploadId
        });

        return (
          <TextField
            key={offerUpload.id || offerUpload.cid}
            label={upload.data.name}
          >
            <a className="text-orange-500 underline hover:text-orange-400" href={upload.data.url} target="_blank">
              View
            </a>
          </TextField>
        );
      })}
    </Section>
  )
}
