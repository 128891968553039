import React from 'react';
import TextField from '../TextField';
import Section from '../Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import EducatorSidePanel from '../../../explore-educators/SidePanel';
import PartnerSidePanel from '../../../explore-partners/SidePanel';
import { PayloadStates } from '@lore/utils';

export default function People(props) {
  const { interaction } = props;

  const connect = useConnect();

  const conversation = connect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <Section>
        <TextField label="Educator">
          ...
        </TextField>
        <TextField label="Partner">
          ...
        </TextField>
      </Section>
    );
  }

  const educator = connect('user.byId', {
    id: conversation.data.educatorId
  });

  const partner = connect('user.byId', {
    id: conversation.data.partnerId
  });

  const show = useDialog();

  function onClickEducator() {
    show(
      <EducatorSidePanel user={educator} />
    );
  }

  function onClickPartner() {
    show(
      <PartnerSidePanel user={partner} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Educator">
        <span onClick={onClickEducator} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {educator.data.firstName} {educator.data.lastName}
        </span>
      </TextField>
      <TextField label="Partner">
        <span onClick={onClickPartner} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {partner.data.firstName} {partner.data.lastName}
        </span>
      </TextField>
    </Section>
  );
}
