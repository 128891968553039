import React from 'react';
import { useDialog } from '@lore/dialogs';
import CreateNoteDialog from '../../dialogs/requestNote/create';
import CompleteRequestReminderDialog from '../../dialogs/request/CompleteRequestReminder';
import Actions from '../_components/actions/Actions';
import PrimaryAction from '../_components/actions/PrimaryAction';
import SecondaryAction from '../_components/actions/SecondaryAction';

export default function _Actions(props) {
  const { request } = props;

  const show = useDialog();

  function onPlaceholder() {
    show(
      <CompleteRequestReminderDialog
        request={request}
      />, { template: 'dialog' }
    );
  }

  function onCreateNote() {
    show(
      <CreateNoteDialog
        request={request}
      />, { template: 'dialog' }
    );
  }

  return (
    <Actions>
      <PrimaryAction onClick={onPlaceholder}>
        Send reminder to complete request
      </PrimaryAction>
      {/*<SecondaryAction onClick={onCreateNote}>*/}
      {/*  Leave note*/}
      {/*</SecondaryAction>*/}
    </Actions>
  );
}
