import { mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import frequencies from './frequencies';
import locations from './locations';
import school from './school';
import startDates from './startDates';
import statuses from './statuses';
import title from './title';

export default mapAttributes({
  accounts,
  frequencies,
  locations,
  school,
  startDates,
  statuses,
  title
});
