import React from 'react';
import PropTypes from 'prop-types';
import ActiveFilter from './Active';
import ActivityFilter from './Activity';
import ActivityTypeFilter from './ActivityType';
import ExpertiseTypeFilter from './ExpertiseType';
import GuidanceFilter from './Guidance';
import GroupFilter from './Group';
import SubjectFilter from './Subject';
import AgeFilter from './Age';
import DayFilter from './Day';
import InteractionTypeFilter from './InteractionType';
import ParticipantFilter from './Participant';
import TimeFilter from './Time';
import CommitmentFilter from './Commitment';
import TitleFilter from './Title';
import SchoolFilter from './School';
import EducatorFilter from './Educator';
import AssigneeFilter from './Assignee';
import CreatedFilter from './Created';
import PublishedFilter from './Published';
import StageFilter from './Stage';
import CommunityFilter from './Community';

Filters.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Filters(props) {
  const { location, history, timestamp } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <ActivityTypeFilter location={location} history={history}/>
          <ActivityFilter location={location} history={history}/>
          <ExpertiseTypeFilter location={location} history={history}/>
          <GuidanceFilter location={location} history={history}/>
          <GroupFilter location={location} history={history}/>
          <SubjectFilter location={location} history={history}/>
          {/*<AgeFilter location={location} history={history}/>*/}
          <DayFilter location={location} history={history}/>
          <InteractionTypeFilter location={location} history={history}/>
          <ParticipantFilter location={location} history={history}/>
          <TimeFilter location={location} history={history}/>
          <CommitmentFilter location={location} history={history}/>
          <AssigneeFilter location={location} history={history}/>
          <CreatedFilter location={location} history={history} timestamp={timestamp}/>
          <PublishedFilter location={location} history={history} />
          <ActiveFilter location={location} history={history} timestamp={timestamp}/>
          <StageFilter location={location} history={history} />
          <CommunityFilter location={location} history={history} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <TitleFilter location={location} history={history} />
          <EducatorFilter location={location} history={history} />
          <SchoolFilter location={location} history={history} />
        </div>
      </div>
    </div>
  );
};
