import React  from 'react';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function EducatorStatements(props) {
  const { evaluation } = props;

  const statements = _.pick(evaluation.data, [
    'recommendYes',
    'recommendNo',
    'recommendNoMessage'
  ]);

  const result = _.reduce(statements, function(result, value, key) {
    if (value) {
      result.push(value);
    }

    return result;
  }, []).join(', ');

  return (
    <SupportingTextColumn
      title={evaluation.data.recommend}
      subtitle={result}
    />
  );
}
