import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/connect';
import useRouter from './useRouter';

const tenants = [
  'elevateedaz',
  'lascruces',
  'tucson',
  'yumaabec'
];

export default function useAccount(accounts) {
  const { location } = useRouter();

  const subdomain = window.location.host.split('.')[0];
  const token = subdomain.split('-')[0];
  const tenant = tenants.indexOf(token) >= 0 ? token : undefined;

  // const account = _.find(accounts.data, account => account.data.subdomain === tenant);

  return {
    tenant,
    // account
  };
}
