import React from 'react';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import classNames from '../../utils/classNames';
import { Link } from 'react-router-dom';

export default function Metric(props) {
  const {
    // icon,
    name,
    stat,
    changeType,
    change,
    to,
    className
  } = props;

  const icon = null;

  return (
    <div className={classNames(
      to ? 'pb-12' : '',
      'relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden',
      className
    )}>
      <dt>
        {icon && (
          <div className="absolute bg-indigo-500 rounded-md p-3">
            <props.icon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
        )}
        <p
          className={classNames(
            icon ? 'ml-16' : '',
            'text-sm font-medium text-gray-500 truncate'
          )}
        >
          {name}
        </p>
      </dt>
      <dd
        className={classNames(
          icon ? 'ml-16' : '',
          'pb-6 flex items-baseline sm:pb-7'
        )}
      >
        <p className="text-2xl font-semibold text-gray-900">
          {stat}
        </p>
        {(changeType && change) && (
          <p
            className={classNames(
              changeType === 'increase' ? 'text-green-600' : 'text-red-600',
              'ml-2 flex items-baseline text-sm font-semibold'
            )}
          >
            {changeType === 'increase' ? (
              <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
            ) : (
              <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
            )}

            <span className="sr-only">{changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
            {change}
          </p>
        )}
        {to && (
          <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <Link to={to} className="font-medium text-indigo-600 hover:text-indigo-500">
                {' '}
                View all<span className="sr-only"> {name} stats</span>
              </Link>
            </div>
          </div>
        )}
      </dd>
    </div>
  );
}
