import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { parse, stringify } from 'query-string';
import PaginationLinks from './PaginationLinks';
import useRouter from '../../../hooks/useRouter';
import classNames from '../../../utils/classNames';
import MobilePagination from './MobilePagination';
import { CloudDownloadIcon } from '@heroicons/react/outline';
import View from '../../explore-educators/Views/View';

export default function Pagination(props) {
  const {
    totalCount,
    pageSize,
    currentPage,
    count,
    onNavigate = () => {},
    hideIfNoResults = false,
    csvQuery
  } = props;

  const start = currentPage * pageSize - (pageSize - 1);
  // const end = currentPage * pageSize;
  const end = start + count - 1;

  if (totalCount === 0) {
    return hideIfNoResults ? null : (
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              There are no results.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <MobilePagination
        className="flex-1 flex justify-between items-center sm:hidden"
        totalCount={totalCount}
        pageSize={pageSize}
        onNavigate={onNavigate}
        currentPage={currentPage}
        start={start}
        end={end}
      />
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{' '}
            <span className="font-medium">{totalCount}</span> results
          </p>
          {csvQuery && (
            <>
              <span className="ml-2"/>
              <View
                icon={CloudDownloadIcon}
                onClick={csvQuery}
              >
                Download results as CSV
              </View>
            </>
          )}
        </div>
        <div>
          <PaginationLinks
            totalCount={totalCount}
            pageSize={pageSize}
            onNavigate={onNavigate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
