import React  from 'react';
import { useConnect } from '@lore/connect';
import avatar from '../../../../assets/images/avatar.svg';
import { PayloadStates } from '@lore/utils';

export default function Partner(props) {
  const { interaction } = props;

  const connect = useConnect();

  const conversation = connect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img className="h-10 w-10 rounded-full" src={avatar} alt=""/>
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">
              ...
            </div>
            <div className="text-sm text-gray-500">
              ...
            </div>
          </div>
        </div>
      </td>
    )
  }

  const user = connect('user.byId', {
    id: conversation.data.partnerId
  });

  const userOrganizations = connect('userOrganization.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  const userOrganization = userOrganizations.data[0];

  const organization = userOrganization ? connect('organization.byId', {
    id: userOrganization.data.organizationId
  }) : { data: {} };

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={user.data.avatarUrl || avatar} alt=""/>
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {user.data.firstName} {user.data.lastName}
          </div>
          <div className="text-sm text-gray-500 line-clamp-1">
            {organization.data.name}
          </div>
        </div>
      </div>
    </td>
  );
}
