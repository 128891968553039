import React, { useState } from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import moment from 'moment-timezone';
import { REQUIRED_PARTNER_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';

export default function Partners(props) {
  const { account } = props;

  const accountQuery = account ? {
    'accounts.id': account.id
  } : {};

  const [ts] = useState(moment().toISOString());

  const connect = useConnect();

  const timestamps = [0,1,2,3,4,5,6,7,8,9,10,11].reverse().map(function(value) {
  // const timestamps = [0].map(function(value) {
    return {
      start: moment(ts).subtract(value + 1, 'months').toISOString(),
      end: moment(ts).subtract(value, 'months').toISOString(),
      label: moment(ts).subtract(value, 'months').format('MMM')
    }
  });

  const partners = timestamps.map(function(timestamp) {
    return {
      ...timestamp,
      results: {
        all: connect('user.find', {
          where: {
            eager: {
              $where: {
                isEducator: false,
                createdAt: {
                  $lte: timestamp.end
                },
                ...accountQuery
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        complete: connect('user.find', {
          where: {
            eager: {
              $where: {
                isEducator: false,
                createdAt: {
                  $lte: timestamp.end
                },
                ...REQUIRED_PARTNER_FIELDS,
                ...accountQuery
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        new: connect('user.find', {
          where: {
            eager: {
              $where: {
                isEducator: false,
                createdAt: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                ...accountQuery
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        engaged: connect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfOffers',
                  relation: 'offers',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    activityId: {
                      $not: null
                    }
                  }
                },
                {
                  type: 'count',
                  alias: 'numberOfSentMessages',
                  relation: 'sentMessages',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    }
                  }
                },
                {
                  type: 'count',
                  alias: 'numberOfReceivedMessages',
                  relation: 'receivedMessages',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    }
                  }
                },
                account && {
                  type: 'count',
                  alias: 'numberOfAccounts',
                  relation: 'accounts',
                  $where: {
                    id: account.id
                  }
                }
              ]),
              $where: {
                isEducator: false,
                $or: {
                  numberOfOffers: {
                    $gte: 1
                  },
                  numberOfSentMessages: {
                    $gte: 1
                  },
                  numberOfReceivedMessages: {
                    $gte: 1
                  }
                },
                numberOfAccounts: account ? {
                  $gte: 1
                } : undefined
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        })
      }
    }
  });

  const loading = _.reduce(partners, function(result, partner) {
    return (
      result ||
      partner.results.all.state === PayloadStates.FETCHING ||
      partner.results.complete.state === PayloadStates.FETCHING ||
      partner.results.new.state === PayloadStates.FETCHING ||
      partner.results.engaged.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <Chart name="Educators" className="col-span-6">
        <Loader />
      </Chart>
    );
  }

  const data = {
    series: [
      {
        name: 'Total',
        data: partners.map(e => e.results.all.meta.totalCount)
      },
      {
        name: 'Complete',
        data: partners.map(e => e.results.complete.meta.totalCount)
      },
      {
        name: 'New',
        data: partners.map(e => e.results.new.meta.totalCount)
      },
      {
        name: 'Engaged',
        data: partners.map(e => e.results.engaged.meta.totalCount)
      }
    ],
    options: {
      title: {
        text: 'Partner growth and engagement',
        align: 'center'
      },
      subtitle: {
        text: 'Over last 12 months',
        align: 'center'
      },
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: partners.map(e => e.label)
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands"
      //     }
      //   }
      // }
    }
  };

  return (
    <Chart
      // name="Partner growth"
      className="col-span-6"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    </Chart>
  );
}
