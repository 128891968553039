import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Experience(props) {
  const { user } = props;

  const connect = useConnect();

  const userExperiences = connect('userExperience.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (userExperiences.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userExperiences.data.map(function(userExperience) {
        const experience = connect('experience.byId', {
          id: userExperience.data.experienceId
        });

        return experience.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
