/*
 * Root component
 */

import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import { ConfigContext } from '@lore/config';
import { ConnectProvider } from '@lore/connect';
import { ActionsContext } from '@lore/actions';
import routes from './routes';

function App(props) {
  const {
    config,
    actions,
    store
  } = props;

  return (
    <ConfigContext.Provider value={config}>
      <ActionsContext.Provider value={actions}>
        <ConnectProvider
          store={store}
          actions={actions}
          blueprints={config.connect.blueprints}
          reducerActionMap={config.connect.reducerActionMap}
        >
          <BrowserRouter basename={__BASENAME__}>
            {routes}
          </BrowserRouter>
        </ConnectProvider>
      </ActionsContext.Provider>
    </ConfigContext.Provider>
  )
}

export default hot(module)(App);
