import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import { parse, stringify } from 'query-string';
import { extractArrayFromQuery, mapAttributes } from '../../utils/query';
import TagsFilter from '../people-educators/Filters/TagsFilter';
import useRouter from '../../hooks/useRouter';

const getQueryUtils = mapAttributes({
  accounts: {
    param: extractArrayFromQuery,
    query: () => {}
  }
});

export default function AccountSwitcher(props) {
  const { location, history } = useRouter();

  const connect = useConnect();

  const accounts = connect('account.find', {
    pagination: {
      pageSize: 100,
      order: 'subdomain asc'
    }
  });

  // const related = function(account) {
  //   return connect('conversation.find', {
  //     where: {
  //       eager: {
  //         $where: {
  //           'request.accountId': account.id
  //         },
  //       }
  //     },
  //     pagination: {
  //       pageSize: 1
  //     }
  //   })
  // }

  const query = parse(location.search);

  let selected = '';

  if (query.accounts) {
    selected = query.accounts.split(',').map(function(value) {
      const account = _.find(accounts.data, account => account.id === Number(value));
      return account ? account.data.subdomain : '';
    }).join(', ');
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label={selected ? `Community (${selected})` : 'Community'}
      dropdownLabel1="They should be interested in collaborating on"
      dropdownLabel2="of the following activity types:"
      queryUtils={getQueryUtils}
      filterName="account"
      queryParamName="accounts"
      tags={accounts}
      // related={related}
      display={account => account.data.subdomain}
      backgroundColor="bg-yellow-100"
    />
  );
};
