import React from 'react';
import { useConnect } from '@lore/connect';
import TextField from '../TextField';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';

export default function Experiences(props) {
  const { user } = props;

  const connect = useConnect();

  const userExperiences = connect('userExperience.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (
    userExperiences.state === PayloadStates.FETCHING ||
    userExperiences.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Excited to talk about..."
    >
      {userExperiences.data.map(function(userExperience) {
        const experience = connect('experience.byId', {
          id: userExperience.data.experienceId
        });

        return (
          <TextField
            invert={true}
            key={userExperience.id}
            label={experience.data.name || '...'}
          >
            {userExperience.data.description}
          </TextField>
        );
      })}
    </Section>
  )
}
