import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const models = connect('account.find', {
    pagination: {
      pageSize: 100,
      order: 'subdomain asc'
    }
  });

  const related = function(account) {
    return connect('user.find', {
      where: {
        eager: {
          $where: {
            'accounts.id': account.id,
            isEducator: true,
            // isHidden: false
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Community"
      dropdownLabel1="They should be interested in collaborating on"
      dropdownLabel2="of the following activity types:"
      queryUtils={getQueryUtils}
      filterName="account"
      queryParamName="accounts"
      tags={models}
      related={related}
      display={tag => tag.data.subdomain}
      backgroundColor="bg-yellow-100"
    />
  );
};
