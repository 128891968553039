import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), [
    'accounts',
    'activities',
    'ages',
    'commitments',
    'costs',
    'createdDates',
    'days',
    'expertiseTypes',
    'groups',
    'guidances',
    'instructions',
    'interactionTypes',
    'name',
    'organization',
    'participants',
    'published',
    'searchOffer',
    'subjects',
    'supplies',
    'times',
    'title',
    'trainings'
  ], timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      <Tags
        type="Community"
        query={searchParams.accounts}
        models={connect('account.find', params)}
        onChange={onChangeArray('accounts', { location, history })}
        label={tag => tag.data.subdomain}
      />
      <Tags
        type="Activity"
        query={searchParams.activities}
        models={connect('activity.find', params)}
        onChange={onChangeArray('activities', { location, history })}
      />
      <Tags
        type="Age group"
        query={searchParams.ages}
        models={connect('age.find', params)}
        onChange={onChangeArray('ages', { location, history })}
      />
      <Tags
        type="Commitment"
        query={searchParams.commitments}
        models={connect('commitment.find', params)}
        onChange={onChangeArray('commitments', { location, history })}
      />
      <Tags
        type="Cost"
        query={searchParams.costs}
        models={mapArray([
          'Yes',
          'Free'
        ])}
        onChange={onChangeArray('costs', { location, history })}
      />
      <Tags
        type="Created"
        query={searchParams.createdDates}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            // ['lte_1_weeks', 'Within 1 week'],
            // ['lte_2_weeks', 'Within 2 weeks'],
            // ['lte_4_weeks', 'Within 1 month'],
            // ['lte_8_weeks', 'Within 2 months'],
            // ['lte_13_week', 'Within 3 months'],
            // ['lte_26_week', 'Within 6 months'],
            // ['lte_52_week', 'Within the year']
            [1, 'Within 1 week'],
            [2, 'Within 2 weeks'],
            [4, 'Within 1 month'],
            [8, 'Within 2 months'],
            [13, 'Within 3 months'],
            [26, 'Within 6 months'],
            [52, 'Within the year']
          ].map(function(value) {
            // const weeks = Number(value[0].split('_')[1]);

            return {
              // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('createdDates', { location, history })}
      />
      <Tags
        type="Day"
        query={searchParams.days}
        models={connect('day.find', params)}
        onChange={onChangeArray('days', { location, history })}
      />
      <Tags
        type="Expertise type"
        query={searchParams.expertiseTypes}
        models={connect('expertiseType.find', params)}
        onChange={onChangeArray('expertiseTypes', { location, history })}
      />
      <Tags
        type="Youth"
        query={searchParams.groups}
        models={connect('group.find', params)}
        onChange={onChangeArray('groups', { location, history })}
      />
      <Tags
        type="Professional competency"
        query={searchParams.guidances}
        models={connect('guidance.find', params)}
        onChange={onChangeArray('guidances', { location, history })}
      />
      <Tags
        type="Instruction"
        query={searchParams.instructions}
        models={mapArray([
          'Led by partner',
          'Provided to the teacher to share'
        ])}
        onChange={onChangeArray('instructions', { location, history })}
      />
      <Tags
        type="Interaction type"
        query={searchParams.interactionTypes}
        models={connect('interactionType.find', params)}
        onChange={onChangeArray('interactionTypes', { location, history })}
      />
      <Tag
        type="Name"
        query={searchParams.name}
        onChange={onChange('name', { location, history })}
      />
      <Tag
        type="Organization"
        query={searchParams.organization}
        onChange={onChange('organization', { location, history })}
      />
      <Tags
        type="Participant"
        query={searchParams.participants}
        models={connect('participant.find', params)}
        onChange={onChangeArray('participants', { location, history })}
      />
      <Tags
        type="Published status"
        query={searchParams.published}
        models={mapArray([
          'Draft',
          'Published'
        ])}
        onChange={onChangeArray('published', { location, history })}
      />
      <Tag
        type="Search"
        query={searchParams.searchOffer}
        onChange={onChange('searchOffer', { location, history })}
      />
      <Tags
        type="Subject"
        query={searchParams.subjects}
        models={connect('subject.find', params)}
        onChange={onChangeArray('subjects', { location, history })}
      />
      <Tags
        type="Supply"
        query={searchParams.supplies}
        models={connect('supply.find', params)}
        onChange={onChangeArray('supplies', { location, history })}
      />
      <Tags
        type="Time"
        query={searchParams.times}
        models={connect('time.find', params)}
        onChange={onChangeArray('times', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
      <Tags
        type="Training"
        query={searchParams.trainings}
        models={mapArray([
          'Yes',
          'No'
        ])}
        onChange={onChangeArray('trainings', { location, history })}
      />
    </div>
  );
};
