import _mapKeys from "lodash/mapKeys";
import normalizeModel from './model';
import normalizeModels from './models';
import normalizeCollection from './collection';
export function getNormalizer(modelName, options) {
  var Models = options.models,
      Collections = options.collections,
      shouldNormalize = options.normalize,
      _options$attributes = options.attributes,
      attributes = _options$attributes === void 0 ? {} : _options$attributes;

  function normalize(actions, model) {
    // if normalization is disabled, bail out
    if (!shouldNormalize) return; // iterate over attributes in the model config, looking for any models that need to
    // be normalized

    _mapKeys(attributes, function (attribute, attributeName) {
      if (attribute.type !== 'model' && attribute.type !== 'models' && attribute.type !== 'collection') {
        return;
      } // get the name and instance of the model we need to normalize the attribute to


      var normalizedModelName = attribute.model; // log an error if no model was specified

      if (!normalizedModelName) {
        console.error("Attempted to normalize a [".concat(modelName, "] model but failed. No model name provided for the [").concat(attributeName, "] attribute"));
        return;
      } // get the model this attribute should be normalized to


      var NormalizedModel = Models[normalizedModelName]; // log an error if the model doesn't exist

      if (!NormalizedModel) {
        console.error("Attempted to normalize the [".concat(attributeName, "] for a [").concat(modelName, "] model but failed. No model exists named [").concat(normalizedModelName, "]"));
        return;
      }

      if (attribute.type === 'model') {
        normalizeModel(NormalizedModel, normalizedModelName, attributeName)(actions, model);
      }

      if (attribute.type === 'models') {
        normalizeModels(NormalizedModel, normalizedModelName, attributeName)(actions, model);
      }

      if (attribute.type === 'collection') {
        // get the name and instance of the model we need to normalize the attribute to
        var normalizedCollectionName = attribute.model || attribute.collection; // log an error if no model was specified

        if (!normalizedCollectionName) {
          console.error("Attempted to normalize a [".concat(modelName, "] collection but failed. No collection name provided for the [").concat(attributeName, "] attribute"));
          return;
        } // get the model this attribute should be normalized to


        var NormalizedCollection = Collections[normalizedCollectionName]; // log an error if the model doesn't exist

        if (!NormalizedCollection) {
          console.error("Attempted to normalize the [".concat(attributeName, "] for a [").concat(modelName, "] collection but failed. No model exists named [").concat(normalizedCollectionName, "]"));
          return;
        }

        normalizeCollection(NormalizedCollection, normalizedCollectionName, attribute, attributeName)(actions, model);
      }
    });
  }

  return {
    model: function model(_model) {
      var actions = [];
      normalize(actions, _model);
      return actions;
    },
    collection: function collection(_collection) {
      var actions = [];

      _collection.models.forEach(function (model) {
        normalize(actions, model);
      });

      return actions;
    }
  };
}
export default getNormalizer;