import _isPlainObject from "lodash/isPlainObject";
import { ActionTypes, PayloadStates, payload } from '@lore/utils';
export default function (NormalizedModel, normalizedModelName, attributeName) {
  return function normalize(actions, model) {
    // get the data we need to inspect for normalization
    var data = model.attributes[attributeName]; // if the data is NOT a plain object, we can't normalize it, so bail out

    if (!_isPlainObject(data)) return; // create an instance of the normalized model populated with the nested data

    var normalizedModel = new NormalizedModel(data); // replace the attribute with it's normalized form (a reference to the id)

    model.set(attributeName, normalizedModel.id); // add an action to be dispatched

    actions.push({
      type: ActionTypes.update(normalizedModelName),
      payload: payload(normalizedModel, PayloadStates.RESOLVED)
    });
  };
}