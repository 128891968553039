import React, { useState } from 'react';
import avatar from '../../../../assets/images/avatar.svg';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import Personal from '../../_components/sidePanel/user/Personal';
import About from '../../_components/sidePanel/user/About';
import Organizations from '../../_components/sidePanel/user/Organizations';
import Social from '../../_components/sidePanel/user/Social';
import Skills from '../../_components/sidePanel/user/Skills';
import Interests from '../../_components/sidePanel/user/Interests';
import Experiences from '../../_components/sidePanel/user/Experiences';
import Guidances from '../../_components/sidePanel/user/Guidance';
import Requests from '../../_components/sidePanel/user/Requests';
import Projects from '../../_components/sidePanel/user/Projects';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';

export default function SidePanel(props) {
  const {
    user,
    primaryAction = '',
    secondaryAction = '',
    extraActions = []
  } = props;

  const [tabs, setTabs] = useState([
    { id: 'profile', name: 'Profile', selected: true },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  return (
    <Panel title="Partner" onClose={props.onClose}>
      <div>
        <Header
          src={user.data.avatarUrl || avatar}
          title={`${user.data.firstName} ${user.data.lastName}`}
          subtitle={user.data.email}
        >
          {/*<Actions*/}
          {/*  user={user}*/}
          {/*  primaryAction={primaryAction}*/}
          {/*  secondaryAction={secondaryAction}*/}
          {/*  extraActions={extraActions}*/}
          {/*/>*/}
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'profile' && (
          <div className="divide-y divide-gray-200">
            <About user={user} />
            <Organizations user={user} />
            <Personal user={user} />
            <Social user={user} />
            <Skills user={user} />
            <Interests user={user} />
            <Experiences user={user} />
            <Guidances user={user} />
            <Requests user={user} />
            <Projects user={user} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes user={user} />
          </div>
        )}
      </div>
    </Panel>
  )
}
