import React from 'react';
import PropTypes from 'prop-types';
import TagChip from './_chips/TagChip';

Tag.propTypes = {
  type: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function Tag(props) {
  const { type, query, onChange } = props;

  if (!query) {
    return null;
  }

  return (
    <TagChip
      tag={{
        data: {
          name: query
        }
      }}
      type={type}
      showType={true}
      onClick={() => onChange(undefined)}
      // onCancel={onChange}
    />
  );
};
