import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function Request(props) {
  const { conversation } = props;

  const connect = useConnect();

  const request = connect('request.byId', {
    id: conversation.data.requestId
  });

  if (request.state === PayloadStates.FETCHING) {
    return (
      <SupportingTextColumn loading={true} />
    );
  }

  const activity = connect('activity.byId', {
    id: request.data.activityId
  });

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900 line-clamp-1">
        {request.data.title || activity.data.name}
      </div>
      <div className="text-sm text-gray-500">
        {activity.data.name} {!request.data.published && (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
            DRAFT
          </span>
        )}
      </div>
    </td>
  );
}
