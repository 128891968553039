export function getModuleFromContext(context, fallback) {
  var result = context.keys().map(function (fileName) {
    var module = context(fileName); // Babel hack, required to get ES5 and ES6 to place nice together
    // by extracting the module from .default per Babel 6 behavior

    if (module["default"] && module.__esModule) {
      return module["default"];
    }

    return module;
  });
  return result[0] || fallback;
}
export default getModuleFromContext;