import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function Competencies(props) {
  const { offer } = props;

  const currentUser = useUser();
  const connect = useConnect();

  const params = {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  };

  const offerGuidances = useConnect('offerGuidance.find', params);

  if (
    offerGuidances.state === PayloadStates.FETCHING ||
    offerGuidances.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Professional Competencies"
      subtitle="Offer addresses these topics."
    >
      <TextField label="">
        {offerGuidances.data.map(function(offerGuidance) {
          const guidance = connect('guidance.byId', {
            id: offerGuidance.data.guidanceId
          });

          return (
            <div key={`guidance-${guidance.id || guidance.cid}`}>
              {guidance.data.name}
            </div>
          )
        })}
      </TextField>
    </Section>
  )
}
