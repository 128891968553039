import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';

SubjectFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function SubjectFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const subjects = connect('subject.find', {
    pagination: {
      pageSize: 100,
      order: 'name asc'
    }
  });

  const users = function(subject) {
    return connect('user.find', {
      where: {
        eager: {
          $where: {
            "id": {
              $includes_any: {
                "userSubjects.subjectId": [subject.id]
              }
            },
            isEducator: true
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Subjects"
      dropdownLabel1="They should teach"
      dropdownLabel2="of the following subjects:"
      queryUtils={getQueryUtils}
      filterName="subject"
      queryParamName="subjects"
      tags={subjects}
      related={users}
    />
  );
};
