import { extractArrayFromQuery } from '../../../utils/query';

const in_map = {
  1: ['No invites'],
  2: ['Invited'],
  3: ['Interested'],
  4: ['Scheduling'],
  5: ['Attending']
};

const stageMap = {
  'No invites': {
    numberOfConversationsInvited: 0,
    numberOfConversationsInterested: 0,
    numberOfConversationsScheduling: 0,
    numberOfConversationsAttending: 0,
  },
  'Invited': {
    numberOfConversationsInvited: {
      $gte: 1
    },
    numberOfConversationsInterested: 0,
    numberOfConversationsScheduling: 0,
    numberOfConversationsAttending: 0,
  },
  'Interested': {
    // numberOfConversationsInvited: 0,
    numberOfConversationsInterested: {
      $gte: 1
    },
    numberOfConversationsScheduling: 0,
    numberOfConversationsAttending: 0,
  },
  'Scheduling': {
    // numberOfConversationsInvited: 0,
    // numberOfConversationsInterested: 0,
    numberOfConversationsScheduling: {
      $gte: 1
    },
    numberOfConversationsAttending: 0,
  },
  'Attending': {
    // numberOfConversationsInvited: 0,
    // numberOfConversationsInterested: 0,
    // numberOfConversationsScheduling: 0,
    numberOfConversationsAttending: {
      $gte: 1
    },
  }
}

export default {
  param: extractArrayFromQuery,
  query: function (values, timestamp) {
    if (values.length > 0) {
      return {
        $or: values.map(function (value) {
          const stage = in_map[value];
          return {
            activityId: {
              $not: null
            },
            ...stageMap[stage]
          }
        })
      }
    }
  }
}
