/**
 * Configuration file for Auth0
 *
 * This file is where you define overrides for the default auth0 parameters.
 */

export default {
  clientID: 'eZ9lszWHIfxgzxjxNgM1DmZOU566xS8V',
  domain: 'communityshare.auth0.com',
  redirectUri: `http://internal.communityshareapp.test/auth/callback`,
  audience: 'https://communityshare.auth0.com/userinfo',
  responseType: 'token id_token',
  scope: 'openid profile email'
};
