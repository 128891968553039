import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default function PrimaryButton(props) {
  const {
    children,
    onClick,
    className,
    disabled,
    ...other
  } = props;

  return (
    <button
      type="button"
      className={classNames(
        `w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm`,
        disabled ? 'opacity-50 cursor-not-allowed' : '',
        className
      )}
      onClick={disabled ? null : onClick}
    >
      {children}
    </button>
  );
};
