import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../explore/Layout';
import Tabs from '../_components/Tabs';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen, children } = props;

  const { location, history } = useRouter();

  function isSelected() {

  }

  const [tabs, setTabs] = useState([
    {
      id: 'partners',
      name: 'Partners',
      to: '/explore/evaluations/partners',
      selected: location.pathname.indexOf('/explore/evaluations/partners') === 0
    },
    {
      id: 'educators',
      name: 'Educators',
      to: '/explore/evaluations/educators',
      selected: location.pathname.indexOf('/explore/evaluations/educators') === 0
    }
  ]);

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore']}
          title="Evaluations"
        />
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
          onChange={tab => history.push(tab.to)}
        />
        {children}
      </main>
    </ParentLayout>
  );
}
