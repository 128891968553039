import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const times = connect('time.find');

  const users = function(time) {
    return connect('request.find', {
      where: {
        eager: {
          $where: {
            "id": {
              $includes_any: {
                "requestTimes.timeId": [time.id]
              }
            }
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Time"
      dropdownLabel1="It should occur during"
      dropdownLabel2="of the following times:"
      queryUtils={getQueryUtils}
      filterName="time"
      queryParamName="times"
      tags={times}
      related={users}
    />
  );
};
