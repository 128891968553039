import { mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import activityTypes from './activityTypes';
import createdDates from './createdDates';
import educatorInteractions from './educatorInteractions';
import grades from './grades';
import locationCondition from './locationCondition';
import name from './name';
import organization from './organization';
import practices from './practices';
import requests from './requests';
import searchEducator from './searchEducator';
import subjects from './subjects';

export default mapAttributes({
  accounts,
  activityTypes,
  createdDates,
  educatorInteractions,
  grades,
  locationCondition,
  name,
  organization,
  practices,
  requests,
  searchEducator,
  subjects
});
