import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';
import { REQUIRED_PARTNER_FIELDS, REQUIRED_PARTNER_FIELDS_TUCSON } from '../../../utils/user/REQUIRED_FIELDS';

export default function CompletePartners(props) {
  function getRequiredFields() {
    const tenant = window.location.host.split('.')[0];

    if (tenant === 'tucson') {
      return {
        ...REQUIRED_PARTNER_FIELDS_TUCSON
      }
    }

    return {
      ...REQUIRED_PARTNER_FIELDS
    }
  }

  const requiredFields = getRequiredFields();

  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          isEducator: false,
          ...requiredFields
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="With complete profiles"
      stat={users.meta ? users.meta.totalCount : '...'}
      icon={UsersIcon}
    />
  );
}
