import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate';
import SearchFilterLabel from '../../../pages/_components/search/_search/SearchFilterLabel';
import SearchFilterLabelDropdown from '../../../pages/_components/search/_search/SearchFilterLabelDropdown';
import _ from 'lodash';
import LocationSearchField from '../../../pages/_components/search/LocationSearchField';
import SearchFilter from '../../../pages/_components/search/_search/SearchFilter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title="Location"
      subtitle="Modify the search radius."
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="location" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => {
              return (
                <div className="inline-block align-top w-full">
                  <div className="leading-loose mb-4 text-sm">
                    <SearchFilterLabel>
                      Show results within
                      <div className="inline-block mx-3 relative">
                        <SearchFilterLabelDropdown
                          value={field.value.radius}
                          options={[1, 2, 5, 10, 15, 20, 30, 50].map(function (value) {
                            return {
                              id: value,
                              name: value
                            };
                          })}
                          onChange={function(radius) {
                            field.onChange(_.extend({}, field.value, {
                              radius: Number(radius)
                            }))
                          }}
                        />
                      </div>
                      miles of:
                    </SearchFilterLabel>
                  </div>
                  <div className="">
                    <LocationSearchField
                      label="Location"
                      address={field.value.address}
                      location={field.value}
                      onChange={function (addressLatitudeLongitude, place) {
                        field.onChange(_.extend({}, field.value, {
                          ...addressLatitudeLongitude,
                          name: place.name
                        }))
                      }}
                    />
                  </div>
                </div>
              );

              // return (
              //   <TextAreaField
              //     className="sm:col-span-6"
              //     label="Note"
              //     placeholder="Write something..."
              //     // description="Some text about a thing I like"
              //     rows={3}
              //     value={field.value}
              //     error={field.error}
              //     onChange={field.onChange}
              //   />
              // );
            }}
          </FormField>
        </>
      )}
      footer={(
        <>
          <PrimaryButton onClick={onSubmit}>
            Save
          </PrimaryButton>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
        </>
      )}
    />
  );
}
