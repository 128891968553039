import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import useRouter from '../hooks/useRouter';

export default function Redirect(props) {
  const { to, replace = false } = props;
  const { history } = useRouter();

  useEffect(() => {
    if (replace) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }, []);

  return null;
};
