import React from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';

export default function Activities(props) {
  const { account } = props;

  const accountQuery = account ? {
    accountId: account.id
  } : {};

  const connect = useConnect();

  const params = {
    pagination: {
      pageSize: 100
    }
  };

  const activities = connect('activity.find', params);
  const activityActivityTypes = connect('activityActivityType.find', params);
  const activityTypes = connect('activityType.find', params);

  if (
    activities.state === PayloadStates.FETCHING ||
    activityActivityTypes.state === PayloadStates.FETCHING ||
    activityTypes.state === PayloadStates.FETCHING
  ) {
    return (
      <Chart name="Request activity types">
        <Loader />
      </Chart>
    );
  }

  const requests = _.sortBy(activities.data, a => a.data.name).map(function(activity) {
    return {
      label: activity.data.name,
      requests: connect('request.find', {
        where: {
          eager: {
            $where: {
              published: true,
              activityId: activity.id,
              ...accountQuery
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.requests.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <Chart name="Request activity types">
        <Loader />
      </Chart>
    );
  }

  const series = activityTypes.data.map(function(activityType) {
    const _activities = activities.data.filter(function(activity) {
      return _.find(activityActivityTypes.data, function(activityActivityType) {
        return (
          activityActivityType.data.activityTypeId === activityType.id &&
          activityActivityType.data.activityId === activity.id
        );
      });
    });

    return {
      name: activityType.data.name,
      data: requests.filter(function(request) {
        return _.find(_activities, function(activity) {
          return activity.data.name === request.label
        })
      }).map(function(request) {
        return {
          x: request.label,
          y: request.requests.meta.totalCount
        }
      }).filter(function(request) {
        return request.y > 0;
      })
    }
  }).filter(function(series) {
    return series.data.length > 0;
  });

  const data = {
    series: series,
    // series: [
    //   {
    //     name: 'Desktops',
    //     data: [
    //       {
    //         x: 'ABC',
    //         y: 10
    //       },
    //       {
    //         x: 'DEF',
    //         y: 60
    //       },
    //       {
    //         x: 'XYZ',
    //         y: 41
    //       }
    //     ],
    //   },
    //   {
    //     name: 'Mobile',
    //     data: [
    //       {
    //         x: 'ABCD',
    //         y: 10
    //       },
    //       {
    //         x: 'DEFG',
    //         y: 20
    //       },
    //       {
    //         x: 'WXYZ',
    //         y: 51
    //       },
    //       {
    //         x: 'PQR',
    //         y: 30
    //       },
    //       {
    //         x: 'MNO',
    //         y: 20
    //       },
    //       {
    //         x: 'CDE',
    //         y: 30
    //       }
    //     ]
    //   }
    // ],
    options: {
      title: {
        text: 'Requests by Activity Type',
        align: 'center'
      },
      subtitle: {
        text: 'For all requests',
        align: 'center',
        offsetY: 20
      },
      // colors: ['#6B7280', '#EF4444', '#F59E0B', '#10B981', '#3B82F6', '#4F46E5', '#8B5CF6', '#EC4899'],
      colors: ['#06B6D4', '#EF4444', '#F59E0B', '#10B981', '#3B82F6', '#8B5CF6', '#EC4899', '#F43F5E'],
      legend: {
        position: 'top',
        show: true,
        floating: false,
        offsetY: -10
      },
      // chart: {
      //   // height: 'auto',
      //   // type: 'treemap',
      //   offsetY: 0,
      //   parentHeightOffset: 0
      // },
    }
  }

  return (
    <Chart
      // name="Request activity types"
      className="col-span-6 overflow-auto"
    >
      <ReactApexChart
        className="pb-2"
        options={data.options}
        series={data.series}
        type="treemap"
        height={400}
      />
    </Chart>
  );
}
