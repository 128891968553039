import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { useConnect } from '@lore/connect';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../settings/Layout';
import useRouter from '../../hooks/useRouter';
// import Screening from './Screening';
import Screening from './Form';
import useAccount from '../../hooks/useAccount';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { tenant } = useAccount();
  const account = useConnect('account.first', {
    where: {
      eager: {
        $where: {
          subdomain: tenant
        }
      }
    }
  });

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Settings']}
          title="Screening"
          description={(
            <>
              <span className="block mb-2">
                Some districts have specific background checks, paperwork, agreements, and
                information that all volunteer community partners need to complete and return
                to Human Resources.
              </span>
              <span className="block mb-2">
                In the box below, enter the URL where this screening information is located. CommunityShare
                will automatically send an email with this link when a partner signs up inviting them to
                start the screening process with your district and again when they accept an interaction
                request as a reminder that they need to complete your required screening.
              </span>
              <span className="block text-left">
                As we grow, we'll add the ability to track screening progress of individual community
                partners in this Admin system.
              </span>
            </>
          )}
        />
        {account.state === PayloadStates.FETCHING ? (
          <div className="px-2 py-4 sm:px-3 lg:px-4s space-y-6">
            <Loader />
          </div>
        ) : (
          <div className="px-2 py-4 sm:px-3 lg:px-4">
            <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
              <Screening account={account} />
            </div>
          </div>
        )}
      </main>
    </ParentLayout>
  );
}
