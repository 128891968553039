import React from 'react';
import { Link } from 'react-router-dom';
import useRouter from '../../hooks/useRouter';
import classNames from '../../utils/classNames';
import SidebarNavigation from './SidebarNavigation';

export default function Sidebar(props) {
  const { navigation = [], title, grouped = false } = props;

  const { location } = useRouter();

  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-50">
        <div className="px-6">
          <div className="text-2xl leading-6 font-medium text-gray-900">
            {title}
          </div>
        </div>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="h-0 flex-1 flex flex-col overflow-y-auto mt-8">
          <SidebarNavigation
            navigation={navigation}
            grouped={grouped}
          />
        </div>
      </div>
    </div>
  )
}
