import React from 'react';

export default [
  { name: 'Educators', href: '/explore/educators' },
  { name: 'Partners', href: '/explore/partners' },
  { name: 'Requests', href: '/explore/requests' },
  { name: 'Offers', href: '/explore/offers' },
  { name: 'Conversations', href: '/explore/conversations' },
  { name: 'Interactions', href: '/explore/interactions' },
  { name: 'Evaluations', href: '/explore/evaluations' }
];
