import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExclamationIcon, CheckIcon } from '@heroicons/react/outline';

SuccessMessage.propTypes = {
  children: PropTypes.node.isRequired,
  timeout: PropTypes.number,
  className: PropTypes.string
};

SuccessMessage.defaultProps = {
  timeout: 3000,
  className: ''
};

export default function SuccessMessage(props) {
  const { title, description, timeout, children, className } = props;

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let mounted = true;

    if (timeout < 0) {
      return;
    }

    setTimeout(() => {
      if (mounted) {
        setIsVisible(false);
      }
    }, timeout);

    return () => {
      mounted = false;
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`rounded-md bg-green-50 p-4 ${isVisible ? 'block' : 'hidden'}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            {title || props.children}
          </h3>
          {description && (
            <div className="mt-2 text-sm text-green-700">
              <p>
                {description}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`bg-green-200 text-green-900 py-6 px-20 my-5 ${className} ${isVisible ? 'block' : 'hidden'}`}>
      {children}
    </div>
  );
}
