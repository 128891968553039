import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';

export default function RequestPublishedEducators(props) {
  const users = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfRequests',
            relation: 'requests',
            $where: {
              'published': true
            }
          }
        ],
        $where: {
          numberOfRequests: {
            $gte: 1
          },
          isEducator: true,
          // ...REQUIRED_EDUCATOR_FIELDS
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Published a request"
      stat={users.meta ? users.meta.totalCount : '...'}
      icon={UsersIcon}
    />
  );
}
