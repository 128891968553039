import React from 'react';
import Sidebar from '../_components/Sidebar';
import SearchHeader from '../_components/SearchHeader';
import navigationLinks from './navigationLinks';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const accounts = useConnect('account.find', {
    pagination: {
      order: 'name asc'
    }
  });

  const groupedNavigationLinks = [
    {
      title: '',
      links: navigationLinks
    },
    {
      title: 'Communities',
      links: accounts.data.map(function(account) {
        return {
          name: account.data.subdomain,
          href: `/dashboard/overview/${account.id}/${account.data.subdomain}`
        }
      })
    }
  ];

  return (
    <>
      {false ? (
        <Sidebar
          title="Dashboard"
          navigation={navigationLinks}
        />
      ) : (
        <Sidebar
          title="Dashboard"
          navigation={groupedNavigationLinks}
          grouped={true}
        />
      )}
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <SearchHeader setSidebarOpen={setSidebarOpen} />
        {props.children || (
          <h3 className="font-bold text-xl text-gray-900 sm:text-2xl px-5 py-4">
            Placeholder: Dashboard
          </h3>
        )}
      </div>
    </>
  )
}
