import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Recommend(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Recommend"
      // subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="Would you recommend this educator to another community partner?">
        {evaluation.data.recommend}
      </TextField>
      <TextField label="Why not?">
        {evaluation.data.recommendNoMessage}
      </TextField>
    </Section>
  );
}
