import React from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon } from '@heroicons/react/outline';
import moment from 'moment-timezone';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import DateRangeField from '../../../forms/_fields/DateRangeField';
import RadioButtonField from '../../../forms/_fields/RadioButtonField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import SleepAlert from './SleepAlert';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onChangeMultiple,
    onSubmit,
    onCancel,
    user,
    isSleeping,
    isScheduledForSleep,
    AWAY_MESSAGE_MAX_LENGTH,
    wakeUpProfile
  } = props;

  return (
    <FormTemplate
      title="Sleep user profile?"
      subtitle="Putting a profile to sleep will remove that profile from search results"
      icon={CalendarIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="my-8 space-y-8">
          {isScheduledForSleep && (
            <SleepAlert
              title="Profile is scheduled for sleep."
              description={`This profile will go to sleep on ${moment(user.data.inactiveStartingAt).format('MMMM Do, YYYY')}.`}
              action="Prevent profile from sleeping"
              onClick={wakeUpProfile}
              Icon={CalendarIcon}
            />
          )}
          {isSleeping && (
            <SleepAlert
              title="Profile is currently asleep."
              description={`It will wake up on ${moment(user.data.inactiveEndingAt).format('MMMM Do, YYYY')}.`}
              action="Wake up profile now"
              onClick={wakeUpProfile}
            />
          )}
          {!(isSleeping || isScheduledForSleep) && (
            <>
              <FormField name="interval" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <>
                    <RadioButtonField
                      label="Sleep profile for..."
                      value={field.value}
                      error={field.error}
                      placeholder="Select a role..."
                      options={{
                        data: [
                          [3, '3 months'],
                          [6, '6 months'],
                          [12, '1 year'],
                          [-1, 'Custom date range']
                        ].map((option) => {
                          return {
                            id: option[0],
                            data: {
                              name: option[1]
                            }
                          }
                        })
                      }}
                      optionLabel="name"
                      onChange={(value) => {
                        if (value > 0) {
                          onChangeMultiple({
                            inactiveStartingAt: moment().toISOString(),
                            inactiveEndingAt: moment().add(value, 'months').toISOString(),
                            interval: value
                          })
                        } else {
                          field.onChange(value)
                        }
                      }}
                    />
                    {field.value === -1 && (
                      <DateRangeField
                        key={`${data.inactiveStartingAt}${data.inactiveEndingAt}`}
                        label="Put profile to sleep from..."
                        startDate={data.inactiveStartingAt}
                        endDate={data.inactiveEndingAt}
                        error={showErrors && (errors.inactiveStartingAt || errors.inactiveEndingAt)}
                        onStartDateChange={(value) => {
                          return onChange('inactiveStartingAt', value);
                        }}
                        onEndDateChange={(value) => {
                          return onChange('inactiveEndingAt', value);
                        }}
                      />
                    )}
                  </>
                )}
              </FormField>
              <FormField name="awayMessage" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <TextAreaField
                    label="Away message"
                    explanation="If someone sends a message they will receive the response below."
                    placeholder="Away message..."
                    rows={5}
                    description={`${data.awayMessage ? data.awayMessage.length : 0}/${AWAY_MESSAGE_MAX_LENGTH} characters`}
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
            </>
          )}
        </div>
      )}
      footer={(isSleeping || isScheduledForSleep) ? (
        <>
          <OutlineButton onClick={onCancel}>
            Close
          </OutlineButton>
        </>
      ) : (
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Sleep profile
          </PrimaryButton>
        </>
      )}
    />
  );
}
