import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Request(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Request"
      // subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="Would you consider this request complete?">
        {evaluation.data.finished}
      </TextField>
      <TextField label="Please give us a sentence or two about the interaction that we can use to inspire others to volunteer.">
        {evaluation.data.testimonial}
      </TextField>
      <TextField label="Do you need any additional support?">
        {evaluation.data.support}
      </TextField>
    </Section>
  );
}
