import React from 'react';

export default function Header(props) {
  const { src, title, subtitle, children } = props;

  return (
    <div className="pb-1 sm:pb-6">
      <div>
        <div className="relative h-40 sm:h-56">
          <img
            className="absolute h-full w-full object-cover bg-gray-300"
            src={src}
            alt=""
          />
        </div>
        <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
          <div className="sm:flex-1">
            <div>
              <div className="flex items-center">
                <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">
                  {title}
                </h3>
                {/*<span className="ml-2.5 bg-green-400 flex-shrink-0 inline-block h-2 w-2 rounded-full">*/}
                {/*  <span className="sr-only">Online</span>*/}
                {/*</span>*/}
              </div>
              <p className="text-sm text-gray-500">
                {subtitle}
              </p>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
