export default [
  31, // Afterschool Program
  28, // Apprenticeship
  // Competition
  11, //	Cultural Celebration
  43, //	Demonstration
  42, //	Educator Externship
  13, //	Festival
  14, //	Field Trip
  27, //	Internship
  22, //	Job Fair
  23, //	Job Shadow
  16, //	Resource Fair
  17, //	Science Fair
  18, //	Volunteer/Service Fair
      // ...replacement for Service Learning/Volunteer Opportunity?
  25, //	Speaker
  26, //	Speaker Panel
  // Tour
  32, //	Tutor
  44, //  Workshop
  45, //  Community Improvement Project
  46, //  Competition
  47, //  Tour
  48, //  Volunteer Opportunity
  49 //  Challenge
]
