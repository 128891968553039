import React  from 'react';
import TextColumn from '../TextColumn';

export default function LinkedIn(props) {
  const { user } = props;

  if (!user.data.linkedinUrl) {
    return (
      <TextColumn />
    );
  }

  return (
    <TextColumn>
      <a href={user.data.linkedinUrl} target="_blank">
        {user.data.linkedinUrl}
      </a>
    </TextColumn>
  );
}
