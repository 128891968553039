import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        id: {
          $includes_expertise_type_any: {
            'userExpertises.expertise.expertiseTypeId': values
          }
        }
      }
    }
  }
}
