import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../_common/DialogTemplate';
import { PhoneIcon } from '@heroicons/react/outline';
import OutlineButton from '../../../components/buttons/OutlineButton';
import FormTemplate from '../../_common/FormTemplate.vertical';

Dialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { user, onClose } = props;

  return (
    <DialogTemplate>
      <FormTemplate
        title="Contact Information"
        subtitle={(
          <span>
          {user.data.firstName || (user.data.isEducator ? 'The Educator' : 'The Partner')} has provided the following ways to contact them.
        </span>
        )}
        icon={PhoneIcon}
        onClose={onClose}
        body={(
          <>
            <div className="mt-6">
              <dl className="sm:grid sm:grid-cols-2 sm:gap-6">
                <div>
                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                    Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    {user.data.firstName} {user.data.lastName}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                    Email
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    <a className="text-accent underline hover:cursor-pointer" href={`mailto:${user.data.email}`}>
                      {user.data.email}
                    </a>
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                    Phone
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                    {user.data.phoneNumber || '-'}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
              <OutlineButton onClick={onClose}>
                Close
              </OutlineButton>
            </div>
          </>
        )}
      />
    </DialogTemplate>
  );
};
