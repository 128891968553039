import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRequest } from '@lore/request';
import { useUser } from '@lore/auth';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SuccessMessage from '../../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../_common/DialogTemplate';

Dialog.propTypes = {
  location: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { location } = props;

  const [data, setData] = useState({
    location: {
      name: location.name || '',
      radius: location.radius || 5,
      address: location.address || '421 N Arcadia Ave, Tucson, AZ 85711, USA',
      latitude: location.latitude || 32.2276914,
      longitude: location.longitude || -110.888591
    }
  });
  const [showErrors, setShowErrors] = useState(false);
  // const [_request, setRequest] = useState(null);
  // const [finished, setFinished] = useState(false);

  // const { request, saving, success, error } = useRequest('requestNote.', _request);

  // useEffect(() => {
  //   if (success && !finished) {
  //     setFinished(true);
  //     props.onClose();
  //     props.onSubmit && props.onSubmit(request);
  //   }
  // });

  function onSubmit() {
    props.onClose();
    props.onSubmit && props.onSubmit(data.location);
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={false}>
        <FormErrors data={data} validators={{
          // location: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onClose}
              alert={showErrors && (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              )}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
