import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import TextField from '../TextField';
import Section from '../Section';

export default function Location(props) {
  const { offer } = props;

  const currentUser = useUser();

  const params = {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  };

  const interactionTypes = useConnect('interactionType.find')
  const offerInteractionTypes = useConnect('offerInteractionType.find', params)

  const locations = useConnect('location.find')
  const offerLocations = useConnect('offerLocation.find', params)

  if (
    interactionTypes.state === PayloadStates.FETCHING ||
    offerInteractionTypes.state === PayloadStates.FETCHING ||
    locations.state === PayloadStates.FETCHING ||
    offerLocations.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const isInPerson = _.find(offerInteractionTypes.data, model => model.data.interactionTypeId === 1);

  return (
    <Section
      title="Location"
      subtitle="Virtual, in-person, possible locations."
    >
      <TextField label="Location Type">
        {offerInteractionTypes.data.map(function(offerInteractionType) {
          const interactionType = _.find(interactionTypes.data, model => model.id === offerInteractionType.data.interactionTypeId);

          return (
            <div key={interactionType.id || interactionType.cid}>
              {interactionType.data.name}
            </div>
          )
        })}
      </TextField>
      {isInPerson && (
        <TextField label="Possible Locations">
          {offerLocations.data.map(function(offerLocation) {
            const location = _.find(locations.data, model => model.id === offerLocation.data.locationId);

            return (
              <div key={location.id || location.cid}>
                {location.data.name}
              </div>
            )
          })}
        </TextField>
      )}
    </Section>
  )
}
