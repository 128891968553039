import React from 'react';
import Id from './Table/Id';
import Request from './Table/Request';
import Partner from './Table/Partner';
import Organization from './Table/Organization';
import DateWindow from './Table/DateWindow';
import Educators from './Table/Educators';
import Community from './Table/Community';

export default [
  { id: 'id',           name: 'ID',           row: Id,           selected: false },
  { id: 'community',    name: 'Community',    row: Community,    selected: false },
  { id: 'title',        name: 'Title',        row: Request,      selected: true },
  { id: 'partner',      name: 'Partner',      row: Partner,      selected: true },
  { id: 'organization', name: 'Organization', row: Organization, selected: true },
  { id: 'date_window',  name: 'Date window',  row: DateWindow,   selected: true },
  { id: 'educators',    name: 'Educators',    row: Educators,    selected: true }
];
