import React from 'react';
import { useConnect } from '@lore/connect';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';

export default function Requests(props) {
  const { user } = props;

  const connect = useConnect();

  const projectUsers = connect('projectUser.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (
    projectUsers.state === PayloadStates.FETCHING ||
    projectUsers.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Projects"
      subtitle="Past projects I’ve been involved with."
      className="bg-gray-50"
    >
      <div>
        <ul className="space-y-12">
          {projectUsers.data.map((projectUser) => {
            const project = connect('project.byId', {
              id: projectUser.data.projectId
            });

            return (
              <li key={projectUser.id}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img className="object-cover shadow-lg rounded-lg" src={project.data.imageUrl} alt="" />
                  </div>
                  <div className="space-y-2">
                    <div className="text-md leading-6 font-medium space-y-1">
                      <h3>{project.data.title}</h3>
                      {/*<p className="text-indigo-600">{projectUser.role}</p>*/}
                    </div>
                    <div className="text-sm">
                      <p className="text-gray-500 line-clamp-5">
                        {project.data.description}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  )
}
