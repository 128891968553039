import { mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import active from './active';
import activities from './activities';
import activityTypes from './activityTypes';
import ages from './ages';
import assignees from './assignees';
import commitments from './commitments';
import createdDates from './createdDates';
import days from './days';
import expertiseTypes from './expertiseTypes';
import groups from './groups';
import guidances from './guidances';
import interactionTypes from './interactionTypes';
import name from './name';
import participants from './participants';
import published from './published';
import school from './school';
import search from './search';
import stage from './stage';
import subjects from './subjects';
import times from './times';
import title from './title';
import trainings from './trainings';

export default mapAttributes({
  accounts,
  active,
  activities,
  activityTypes,
  ages,
  assignees,
  commitments,
  createdDates,
  days,
  expertiseTypes,
  groups,
  guidances,
  interactionTypes,
  name,
  participants,
  published,
  school,
  search,
  stage,
  subjects,
  times,
  title,
  trainings
});
