import React from 'react';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../troubleshoot/Layout';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Actions']}
          title="Placeholder: Offer invitations"
        />
      </main>
    </ParentLayout>
  );
}
