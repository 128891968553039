import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';

export default function Date(props) {
  const { evaluation } = props;

  const interaction = useConnect('interaction.byId', {
    id: evaluation.data.interactionId
  });

  if (interaction.state === PayloadStates.FETCHING) {
    return (
      <SupportingTextColumn loading={true} />
    );
  }

  const startDate = moment(interaction.data.startDate).format('MMM Do, YYYY');
  const startTime = moment(interaction.data.startTime).format('h:mm A');
  const endTime = moment(interaction.data.endTime).format('h:mm A');

  return (
    <SupportingTextColumn
      title={startDate}
      subtitle={`${startTime} - ${endTime}`}
    />
  );
}
