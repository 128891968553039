import React from 'react';
import PropTypes from 'prop-types';
import ActivityFilter from './Activity';
import GuidanceFilter from './Guidance';
import GroupFilter from './Group';
import SubjectFilter from './Subject';
import AgeFilter from './Age';
import DayFilter from './Day';
import InteractionTypeFilter from './InteractionType';
import ParticipantFilter from './Participant';
import TimeFilter from './Time';
import TitleFilter from './Title';
import OrganizationFilter from './Organization';
import NameFilter from './Name';
import SupplyFilter from './Supply';
import CostFilter from './Cost';
import InstructionFilter from './Instruction';
import SearchFilter from './Search';
import CreatedFilter from './Created';
import PublishedFilter from './Published';
import CommunityFilter from './Community';

Filters.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Filters(props) {
  const { location, history, timestamp } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <ActivityFilter location={location} history={history}/>
          <GuidanceFilter location={location} history={history}/>
          <GroupFilter location={location} history={history}/>
          <SubjectFilter location={location} history={history}/>
          <AgeFilter location={location} history={history}/>
          <DayFilter location={location} history={history}/>
          <InteractionTypeFilter location={location} history={history}/>
          <ParticipantFilter location={location} history={history}/>
          <TimeFilter location={location} history={history}/>
          <SupplyFilter location={location} history={history}/>
          <CostFilter location={location} history={history}/>
          <InstructionFilter location={location} history={history}/>
          <CreatedFilter location={location} history={history} timestamp={timestamp}/>
          <PublishedFilter location={location} history={history} />
          <CommunityFilter location={location} history={history} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <TitleFilter location={location} history={history} />
          <NameFilter location={location} history={history}/>
          <OrganizationFilter location={location} history={history}/>
          <SearchFilter location={location} history={history}/>
        </div>
      </div>
    </div>
  );
};
