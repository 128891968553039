import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import Requests from './Requests';
import StringField from '../../../forms/_fields/StringField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    user
  } = props;

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');

  function onChangeTitle(text) {
    setTitle(text);
  }

  function onChangeName(text) {
    setName(text);
  }

  function onClick(request) {
    onChange('request', request);
  }

  return (
    <FormTemplate
      title="Recommend a request to this partner?"
      subtitle={(
        <span>
          Is there a request you think would be a good fit for this partner? Search for it below!
        </span>
      )}
      showIcon={false}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="mt-4 space-y-4">
          <StringField
            label="Request title"
            placeholder="Enter title..."
            value={title}
            onChange={onChangeTitle}
          />
          <StringField
            label="Educator name"
            placeholder="Enter name..."
            value={name}
            onChange={onChangeName}
          />
          <Requests
            title={title}
            name={name}
            onClick={onClick}
          />
        </div>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          {/*<PrimaryButton onClick={onSubmit}>*/}
          {/*  Send reminder*/}
          {/*</PrimaryButton>*/}
        </>
      )}
    />
  );
}
