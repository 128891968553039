import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../_common/DialogTemplate';
import Loader from '../../../components/Loader';
import { PayloadStates } from '@lore/utils';
import fileDownload from 'js-file-download';
import _ from 'lodash';
import { CloudDownloadIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import OutlineButton from '../../../components/buttons/OutlineButton';
import FormTemplate from '../../_common/FormTemplate.vertical';

Dialog.propTypes = {
  modelName: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { modelName, query } = props;

  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(false)

  useEffect(function() {
    const Collection = lore.collections[modelName].extend({
      fetch: function(options) {
        options = _.extend({ parse: true }, options);
        return this.sync('read', this, options);
      }
    });
    const collection = new Collection();
    const queryParameters = _.extend({}, query.where, query.pagination);

    collection.fetch({
      data: queryParameters,
      responseType: 'text'
      // success: () => {}
    }).then(function(response) {
      setFinished(true);
      fileDownload(response.data, 'results.csv');
    }).catch(function(response) {
      setError(true);
      console.error(response);
    })
  }, []);

  if (finished) {
    return (
      <DialogTemplate>
        <FormTemplate
          icon={CloudDownloadIcon}
          title="Results downloaded!"
          subtitle="You can close this dialog."
          onClose={props.onClose}
          footer={(
            <>
              <OutlineButton onClick={props.onClose}>
                Close
              </OutlineButton>
            </>
          )}
        />
      </DialogTemplate>
    );
  }

  if (error) {
    return (
      <DialogTemplate>
        <FormTemplate
          icon={ExclamationCircleIcon}
          title="There was an error generating the CSV."
          subtitle="Please contact support so they can resolve the issue."
          onClose={props.onClose}
          footer={(
            <>
              <OutlineButton onClick={props.onClose}>
                Close
              </OutlineButton>
            </>
          )}
        />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate>
      <FormTemplate
        icon={CloudDownloadIcon}
        title="Downloading results to CSV"
        subtitle="This may take a few seconds..."
        onClose={props.onClose}
        // alert={alert}
        body={(
          <div className="py-2">
            <Loader/>
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={props.onClose}>
              Close
            </OutlineButton>
          </>
        )}
      />
    </DialogTemplate>
  );
};
