import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Served(props) {
  const { evaluation } = props;

  return (
    <TextColumn>
      {evaluation.data.served}
    </TextColumn>
  );
}
