import React from 'react';
import PropTypes from 'prop-types';
import { PencilIcon } from '@heroicons/react/outline';
import { Dialog } from '@headlessui/react';

FormTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

FormTemplate.defaultProps = {
  showClose: true
};

export default function FormTemplate(props) {
  const {
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className,
    showClose,
    icon = (
      <PencilIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
    )
  } = props;

  return (
    <>
      <div className="sm:flex sm:items-start mb-6">
        {icon && (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
            {icon}
          </div>
        )}
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
      {alert && (
        <div className="mb-4">
          {alert}
        </div>
      )}
      {body}
      {footer ? (
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          {footer}
        </div>
      ) : null}
    </>
  );
}
