import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        accepted: {
          $or: values.map(function(value) {
            return {
              1: true,
              2: false
            }[value];
          })
        }
      }
    }
  }
}
