import { mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import educator from './educator';
import partner from './partner';
import stages from './stages';
import title from './title';

export default mapAttributes({
  accounts,
  educator,
  partner,
  stages,
  title
});
