import React  from 'react';
import PropTypes from 'prop-types';
import useUserProfileFields from './useUserProfileFields';
import checkProfile from '../utils/checkProfile';
import { useUser } from '@lore/auth';
import PayloadStates from '../constants/PayloadStates';

export default function useUserProfileMissingFields(_user) {
  const currentUser = useUser();

  const user = _user || currentUser;

  const {
    userAges,
    userActivityTypes,
    userExpertises,
    userSubjects,
    userGrades
  } = useUserProfileFields(user);

  if (
    userAges.state === PayloadStates.FETCHING ||
    userActivityTypes.state === PayloadStates.FETCHING ||
    userExpertises.state === PayloadStates.FETCHING ||
    userSubjects.state === PayloadStates.FETCHING ||
    userGrades.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const { profile, interests } = checkProfile(user, {
    userAges,
    userActivityTypes,
    userExpertises,
    userSubjects,
    userGrades
  });

  return {
    profile,
    interests,
    relations: {
      userAges,
      userGrades,
      userActivityTypes,
      userExpertises,
      userSubjects
    }
  };
};
