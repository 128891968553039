import _isArray from "lodash/isArray";
import { ActionTypes, PayloadStates, payload } from '@lore/utils';
export default function (NormalizedModel, normalizedModelName, attributeName) {
  return function normalize(actions, model) {
    // get the data we need to inspect for normalization
    var data = model.attributes[attributeName]; // if the data is NOT a plain object, we can't normalize it, so bail out

    if (!_isArray(data)) return;
    data.forEach(function (datum) {
      var normalizedModel = new NormalizedModel(datum);
      actions.push({
        type: ActionTypes.update(normalizedModelName),
        payload: payload(normalizedModel, PayloadStates.RESOLVED)
      });
    });
  };
}