import React from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';

export default function Schools(props) {
  const { account } = props;

  const accountQuery = account ? {
    accountId: account.id
  } : {};

  const schools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfRequests',
            relation: 'users.requests',
            $where: {
              'creator.isEducator': true,
              ...accountQuery
              // stage: {
              //   $not: 'Invited',
              //   // $in: [
              //   //   // 'Invited',
              //   //   'Interested',
              //   //   'Scheduling',
              //   //   'Attending',
              //   //   'Declined',
              //   //   'Passed'
              //   // ]
              // }
            }
          }
        ],
        $where: {
          numberOfRequests: {
            $gte: 1
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  })

  return (
    <Metric
      name="Supported (associated with a request)"
      stat={schools.meta ? schools.meta.totalCount : '...'}
      icon={OfficeBuildingIcon}
    />
  );
}
