import React from 'react';
import PropTypes from 'prop-types';

AgeFilter.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node
};

export default function AgeFilter(props) {
  const {
    header,
    body,
    footer
  } = props;

  return (
    <div className="inline-block align-top w-full">
      <div className="leading-loose mb-4">
        {header}
      </div>
      <div className="">
        {body}
      </div>
      <div className="leading-loose">
        {footer}
      </div>
    </div>
  );
};
