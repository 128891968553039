import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import classNames from '../../utils/classNames';

export default function PageHeading(props) {
  const { breadcrumbs = [], title, description, children } = props;

  return (
    <div className="border-bx border-gray-200x px-4 py-4 sm:px-6 lg:px-8">
      <div>
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            {breadcrumbs.map(function(breadcrumb, index) {
              return (
                <li key={index}>
                  <div className={index > 0 ? 'flex items-center' : ''}>
                    {index > 0 && (
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    )}
                    <a href="#" className={classNames(
                      index > 0 ? 'ml-4' : '',
                      'text-sm font-medium text-gray-500 hover:text-gray-700'
                    )}>
                      {breadcrumb}
                    </a>
                  </div>
                </li>
              )
            })}
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {title}
          </h2>
          {description && (
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              {description}
            </p>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}
