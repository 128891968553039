import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function DateTime(props) {
  const { interaction } = props;

  const currentUser = useUser();

  return (
    <Section
      title="Date and Time"
      subtitle="When the interaction will occur."
    >
      {interaction.data.type === 'One-Time' ? (
        <TextField label="Date">
          {moment(interaction.data.date).format('MMM D, YYYY')}
        </TextField>
      ) : (
        <TextField label="Date Window">
          {moment(interaction.data.startDate).format('MMM Do')} - {moment(interaction.data.endDate).format('MMM Do')}
        </TextField>
      )}
      <TextField label="Check-in time">
        {moment(interaction.data.checkInTime).tz(currentUser.data.timezone).format('h:mm a')}
      </TextField>
      <TextField label="Start time">
        {moment(interaction.data.startTime).tz(currentUser.data.timezone).format('h:mm a')}
      </TextField>
      <TextField label="Check-in time">
        {moment(interaction.data.endTime).tz(currentUser.data.timezone).format('h:mm a')}
      </TextField>
    </Section>
  )
}
