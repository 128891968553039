import React from 'react';
import PropTypes from 'prop-types';

export default function Checkmark(props) {
  return (
    <svg width="15px" height="13px" viewBox="0 0 15 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ICONS/CHECKED-BOX" transform="translate(-6.000000, -6.000000)" fill="#F67F20">
          <path d="M10.2657515,18.6361178 C10.7963842,19.1586277 11.664462,19.112425 12.1360418,18.5361521 L20.715587,8.0557125 C21.1567966,7.51640165 21.0758098,6.72255623 20.5342102,6.2832111 C19.9926106,5.84386596 19.1953962,5.92451057 18.7541865,6.46382143 L11.0680276,15.8790801 L8.15503153,13.0145162 C7.65814341,12.5247682 6.85671097,12.5298085 6.36572814,13.0245968 C5.87390171,13.5193851 5.87896338,14.3174307 6.3758515,14.8063387 L10.2657515,18.6369578" id="Path"></path>
        </g>
      </g>
    </svg>
  );
};
