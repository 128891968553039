import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import useUserProfileMissingFields from '../../../../../hooks/useUserProfileMissingFields';

export default function Grade(props) {
  const { user } = props;

  const missingFields = useUserProfileMissingFields(user);

  if (!missingFields) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {missingFields.profile.map(function(field) {
        return field.label;
      }).concat(missingFields.interests.map(function(field) {
        return field.label;
      })).join(', ')}
    </TextColumn>
  );
}
