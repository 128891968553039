import React from 'react';
import Id from '../_components/table/columns/user/Id';
import Name from '../_components/table/columns/user/Name';
import Organization from '../_components/table/columns/user/Organization';
import Joined from '../_components/table/columns/user/Joined';
import About from '../_components/table/columns/user/About';
import Motivation from '../_components/table/columns/user/Motivation';
import Gender from '../_components/table/columns/user/Gender';
import Ethnicity from '../_components/table/columns/user/Ethnicity';
import Website from '../_components/table/columns/user/Website';
import Twitter from '../_components/table/columns/user/Twitter';
import LinkedIn from '../_components/table/columns/user/LinkedIn';
import Skills from '../_components/table/columns/user/Skills';
import SkillTypes from '../_components/table/columns/user/SkillTypes';
import Experiences from '../_components/table/columns/user/Experiences';
import Groups from '../_components/table/columns/user/Groups';
import Ages from '../_components/table/columns/user/Ages';
import MissingFields from '../_components/table/columns/user/MissingFields';
import Interactions from '../_components/table/columns/user/Interactions';
import Hours from '../_components/table/columns/user/Hours';
import Contributions from '../_components/table/columns/user/Contributions';
import PartnerStudents from '../_components/table/columns/user/PartnerStudents';
import Phone from '../_components/table/columns/user/Phone';
import Communities from '../_components/table/columns/user/Communities';

export default [
  { id: 'id',              name: 'ID',                          row: Id,              selected: false },
  { id: 'communities',     name: 'Communities',                 row: Communities,     selected: false },
  { id: 'name',            name: 'Name',                        row: Name,            selected: true },
  { id: 'organization',    name: 'Organization',                row: Organization,    selected: true },
  { id: 'date_joined',     name: 'Date joined',                 row: Joined,          selected: true },
  { id: 'phone',           name: 'Phone',                       row: Phone,           selected: false },
  { id: 'about',           name: 'About',                       row: About,           selected: false },
  { id: 'motivation',      name: 'Motivation',                  row: Motivation,      selected: false },
  { id: 'skills',          name: 'Skills',                      row: Skills,          selected: false },
  { id: 'skill_types',     name: 'Skill types',                 row: SkillTypes,      selected: false },
  { id: 'experiences',     name: 'Experiences',                 row: Experiences,     selected: false },
  { id: 'groups',          name: 'Groups',                      row: Groups,          selected: false },
  { id: 'ages',            name: 'Ages',                        row: Ages,            selected: false },
  { id: 'gender',          name: 'Gender',                      row: Gender,          selected: false },
  { id: 'ethnicity',       name: 'Ethnicity',                   row: Ethnicity,       selected: false },
  { id: 'website',         name: 'Website',                     row: Website,         selected: false },
  { id: 'twitter',         name: 'Twitter',                     row: Twitter,         selected: false },
  { id: 'linkedin',        name: 'LinkedIn',                    row: LinkedIn,        selected: false },
  { id: 'missing_fields',  name: 'Missing fields',              row: MissingFields,   selected: false },
  { id: 'interactions',    name: 'Interactions',                row: Interactions,    selected: false },
  { id: 'hours_served',    name: 'Hours served',                row: Hours,           selected: false },
  { id: 'contributions',   name: 'Total in-kind contributions', row: Contributions,   selected: false },
  { id: 'students_served', name: 'Students served',             row: PartnerStudents, selected: false }
];
