import React from 'react';

export default function SecondaryAction(props) {
  const { onClick, children } = props;

  return (
    <button
      type="button"
      className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
