/* eslint key-spacing: "off" */
export default {
  INITIAL_STATE: 'INITIAL_STATE',
  RESOLVED: 'RESOLVED',
  NOT_FOUND: 'NOT_FOUND',
  DELETED: 'DELETED',
  CREATING: 'CREATING',
  UPDATING: 'UPDATING',
  DELETING: 'DELETING',
  FETCHING: 'FETCHING',
  ERROR_CREATING: 'ERROR_CREATING',
  ERROR_UPDATING: 'ERROR_UPDATING',
  ERROR_DELETING: 'ERROR_DELETING',
  ERROR_FETCHING: 'ERROR_FETCHING',
  MANAGED: 'MANAGED'
};