import React  from 'react';
import { useConnect } from '@lore/connect';

export default function Request(props) {
  const { request } = props;

  const activity = useConnect('activity.byId', {
    id: request.data.activityId
  });

  const account = useConnect('account.byId', {
    id: request.data.accountId
  });

  const tag = false && (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
      {account.data.subdomain}
    </span>
  );

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900">
        {request.data.title || activity.data.name} {tag}
      </div>
      <div className="text-sm text-gray-500">
        {activity.data.name} {!request.data.published && (
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
          DRAFT
        </span>
      )}
      </div>
    </td>
  );
}
