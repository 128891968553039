import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classNames from '../../../utils/classNames';

export default function Columns(props) {
  const { columns, setColumns } = props;
  // const [columns, setColumns] = useState([
  //   { id: 'id', name: 'ID', selected: false },
  //   { id: 'name', name: 'Name', selected: false },
  //   { id: 'organization', name: 'Organization', selected: false },
  //   { id: 'date_joined', name: 'Date joined', selected: false },
  //   { id: 'about', name: 'About', selected: false },
  //   { id: 'motivation', name: 'Motivation', selected: false },
  //   { id: 'gender', name: 'Gender', selected: false },
  //   { id: 'ethnicity', name: 'Ethnicity', selected: false },
  //   { id: 'website', name: 'Website', selected: false },
  //   { id: 'twitter', name: 'Twitter', selected: false },
  //   { id: 'linkedin', name: 'LinkedIn', selected: false }
  // ]);

  function onChange(column) {
    const nextColumns = [...columns];
    const selectedColumn = _.find(nextColumns, { id: column.id });
    selectedColumn.selected = !selectedColumn.selected;
    setColumns(nextColumns);
  }

  return (
    <Listbox value={null} onChange={onChange}>
      {({ open }) => (
        <>
          {/*<Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>*/}
          <div className="mt-1-x relative w-full">
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">
                Columns
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full w-max-x bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {columns.map((column) => {
                  return (
                    <Listbox.Option
                      key={column.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={column}
                    >
                      {({ active }) => {
                        return (
                          <>
                            <span className={classNames(column.selected ? 'font-semibold' : 'font-normal', 'block truncate-x')}>
                              {column.name}
                            </span>

                            {column.selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
