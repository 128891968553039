/**
 * Production environment settings
 *
 * This file is where you define overrides for any of the config settings that
 * should only be applied in the production environment.
 *
 * The production environment is defined as 'process.env.LORE_ENV=production' and
 * is automatically set when webpack is invoked using the --env.lore=production argument.
 */

export default {

  /**
   * To override a config setting, specify the name of the config file you
   * want to override, followed by the settings you want to change.
   */

  auth0: {
    clientID: 'eZ9lszWHIfxgzxjxNgM1DmZOU566xS8V',
    redirectUri: 'https://internal.communityshareapp.us/auth/callback',
  },

  connections: {
    default: {
      apiRoot: 'https://internal.communityshare.cloud'
    }
  },

  raven: {
    environment: 'production-internal',
    enabled: true
  },

  fullstory: {
    enabled: true
  }

}
