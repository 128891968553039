import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function EducatorStatements(props) {
  const { evaluation } = props;

  const statements = _.pick(evaluation.data, [
    'statement6',
    'statement7',
    'statement8',
    'statement9',
    'statement10',
    'statement11'
  ]);

  const result = _.reduce(statements, function(result, value, key) {
    if (value) {
      result.push(value);
    }

    return result;
  }, []);

  return (
    <TextColumn>
      {result.join(', ')}
    </TextColumn>
  );
}
