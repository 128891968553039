import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Request(props) {
  const { conversation } = props;

  const messages = useConnect('message.find', {
    where: {
      eager: {
        $where: {
          conversationId: conversation.id
        }
      }
    }
  });

  if (messages.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {messages.meta.totalCount}
    </TextColumn>
  );
}
