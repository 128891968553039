import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

TextAreaField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  error: PropTypes.node,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

TextAreaField.defaultProps = {
  rows: 3,
  disabled: false
};

export default function TextAreaField(props) {
  const {
    value,
    label,
    explanation,
    placeholder,
    description,
    error,
    rows,
    disabled,
    onChange,
    className,
    name
  } = props;

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ): null}
      {explanation && (
        <p className="text-sm text-gray-500 mb-4">
          {explanation}
        </p>
      )}
      <div className={label ? 'mt-1' : ''}>
        <textarea
          // className={`form-control ${error ? 'is-invalid' : ''} ${inputClassName}`}
          name={name}
          className={classNames(
            `shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md`,
            error ? 'border-red-500' : 'border-gray-300'
          )}
          rows={rows}
          // style={{ maxWidth: '100%', minWidth: '100%' }}
          value={value}
          placeholder={placeholder}
          readOnly={disabled}
          onChange={(event) => {
            onChange(event.target.value)
          }}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-500">
          {error}
        </p>
      )}
      {description && (
        <p className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
    </div>
  );
}
