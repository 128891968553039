import React from 'react';
import PropTypes from 'prop-types';
import { PencilIcon } from '@heroicons/react/outline';
import { Dialog } from '@headlessui/react';
import classNames from '../../utils/classNames';

FormTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

FormTemplate.defaultProps = {
  showClose: true
};

export default function FormTemplate(props) {
  const {
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className,
    showClose,
    // icon = (
    //   <PencilIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
    // )
    icon: Icon = PencilIcon,
    success = false,
    showIcon = true
  } = props;

  const buttonCount = footer ? React.Children.count(footer.props.children) : 0;

  const _icon = success ? (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
      {/*{icon}*/}
      <Icon className="h-6 w-6 text-green-600" aria-hidden="true" />
    </div>
  ) : (
    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
      {/*{icon}*/}
      <Icon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
    </div>
  );

  return (
    <>
      <div className="sm:align-middle sm:max-w-lg sm:w-full mb-4">
        <div>
          {showIcon && _icon}
          <div className="mt-3 text-center sm:mt-5">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
      {alert && (
        <div className="mb-4">
          {alert}
        </div>
      )}
      {body}
      {footer ? (
        <div className={classNames(
          buttonCount > 1 ? 'sm:grid-cols-2' : 'sm:grid-cols-1',
          'mt-5 sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense'
        )}>
          {footer}
        </div>
      ) : null}
    </>
  );
}
