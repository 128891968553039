import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function PartnerPhone(props) {
  const { evaluation } = props;

  const connect = useConnect();

  const interaction = connect('interaction.byId', {
    id: evaluation.data.interactionId
  });

  if (interaction.state === PayloadStates.FETCHING) {
    return (
      <TextColumn loading={true} />
    );
  }

  const conversation = connect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <TextColumn loading={true} />
    );
  }

  const user = connect('user.byId', {
    id: conversation.data.partnerId
  });

  if (user.state === PayloadStates.FETCHING) {
    return (
      <TextColumn loading={true} />
    );
  }

  return (
    <TextColumn>
      {user.data.phoneNumber}
    </TextColumn>
  );
}
