import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Training(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Platform"
      // subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="Would you recommend CommunityShare to other community members?">
        {evaluation.data.platform}
      </TextField>
      {evaluation.data.platform === 'Yes' && (
        <TextField label="Please give us a sentence or two about the interaction that we can use to inspire others to volunteer.">
          {evaluation.data.platformMessage}
        </TextField>
      )}
      {evaluation.data.platform === 'No' && (
        <TextField label="Tell us how we can improve.">
          {evaluation.data.platformMessage}
        </TextField>
      )}
    </Section>
  );
}
