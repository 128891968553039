import React from 'react';
import { PayloadStates } from '@lore/utils';

export default function Feed(props) {
  const { models, children } = props;

  if (models.state === PayloadStates.FETCHING) {
    return (
      <div>
        <p className="text-sm text-gray-500">
          Fetching notes...
        </p>
      </div>
    );
  }

  if (models.data.length === 0) {
    return (
      <div>
        <p className="text-sm text-gray-500">
          There are no notes.
        </p>
      </div>
    );
  }

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {models.data.map((model, index) => (
          <li key={model.id || model.cid}>
            <div className="relative pb-8">
              {index !== models.data.length - 1 ? (
                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              {children(model)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
