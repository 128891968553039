import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function DateTime(props) {
  const { offer } = props;

  const currentUser = useUser();

  const params = {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  };

  const days = useConnect('day.find');
  const offerDays = useConnect('offerDay.find', params);

  const times = useConnect('time.find');
  const offerTimes = useConnect('offerTime.find', params);

  const commitments = useConnect('commitment.find');
  const offerCommitments = useConnect('offerCommitment.find', params);

  const supplies = useConnect('supply.find');
  const offerSupplies = useConnect('offerSupply.find', params);

  if (
    days.state === PayloadStates.FETCHING ||
    offerDays.state === PayloadStates.FETCHING ||
    times.state === PayloadStates.FETCHING ||
    offerTimes.state === PayloadStates.FETCHING ||
    commitments.state === PayloadStates.FETCHING ||
    offerCommitments.state === PayloadStates.FETCHING ||
    supplies.state === PayloadStates.FETCHING ||
    offerSupplies.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const noSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply.data.supplyId === 1;
  });

  const basicSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply.data.supplyId === 2;
  });

  const specialSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply.data.supplyId === 3;
  });

  return (
    <Section
      title="Commitment"
      subtitle="Date, time, cost, and supplies."
    >
      {offer.data.specificDate === 'Yes' ? (
        <TextField label="Date">
          {moment(offer.data.date).format('MMM D, YYYY')}
        </TextField>
      ) : (
        <>
          <TextField label="Date Window">
            {moment(offer.data.startDate).format('MMM Do')} - {moment(offer.data.endDate).format('MMM Do')}
          </TextField>
          <TextField label="Possible Days">
            {offerDays.data.map(function(offerDay) {
              const day = _.find(days.data, model => model.id === offerDay.data.dayId);

              return (
                <div key={day.id || day.cid}>
                  {day.data.name}
                </div>
              )
            })}
          </TextField>
        </>
      )}
      {offer.data.specificTime === 'Yes' ? (
        <TextField label="Time">
          {`${moment(offer.data.startTime).tz(currentUser.data.timezone).format('h:mm a')} - ${moment(offer.data.endTime).tz(currentUser.data.timezone).format('h:mm a')}`}
        </TextField>
      ) : (
        <TextField label="Possible Times of Day">
          {offerTimes.data.map(function(offerTime) {
            const time = _.find(times.data, model => model.id === offerTime.data.timeId);

            return (
              <div key={time.id || time.cid}>
                {time.data.name}
              </div>
            );
          })}
        </TextField>
      )}
      <TextField label="Cost">
        {offer.data.hasCost === 'Yes' ? offer.data.cost : 'Free'}
      </TextField>
      {noSupplies ? (
        <TextField label="Supplies">
          No supplies needed.
        </TextField>
      ) : (
        <TextField label="Supplies">
          {basicSupplies && (
            <div>
              {'Basic art supplies'}
            </div>
          )}
          {specialSupplies && offer.data.supplies && (
            <div>
              {offer.data.supplies}
            </div>
          )}
          <div className="mt-4">
            *{offer.data.suppliesProvidedBy || 'Partner will provide supplies'}.
          </div>
        </TextField>
      )}
    </Section>
  )
}
