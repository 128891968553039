import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../troubleshoot/Layout';
import useRouter from '../../hooks/useRouter';
// import SearchTags from '../explore-partners/SearchTags';
import Columns from '../explore-partners/Table/Columns';
// import Filters from './Filters';
import SidePanel from '../explore-conversations/SidePanel';
import initialColumns from './columns';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const show = useDialog();

  function onClick(conversation) {
    show(
      <SidePanel conversation={conversation}/>
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Actions']}
          title="Request Invitations"
          description={(
            <span>
              These are invitations waiting for a response. A status of INVITED means the educator
              invited the partner, while a status of INTERESTED means the partner found the request
              on their own and expressed interest.
            </span>
          )}
        />
        <div className="py-2" />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {/*<div className="px-2 py-4 sm:px-3 lg:px-4">*/}
        {/*  <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">*/}
        {/*    <Filters*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*    <SearchTags*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return getState('conversation.find', {
              where: {
                eager: {
                  $aggregations: [
                    {
                      type: 'count',
                      alias: 'numberOfMessages',
                      relation: 'messages'
                    }
                  ],
                  $where: {
                    numberOfMessages: {
                      $lte: 1
                    },
                    requestId: {
                      $not: null
                    }
                  }
                },
              },
              pagination: {
                page: parse(location.search).page || '1',
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              }
            });
          }}
          row={function (conversation) {
            return (
              <tr key={conversation.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(conversation)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      conversation={conversation}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
