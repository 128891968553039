import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';

export default function ConversationPartners(props) {
  const { account } = props;

  const accountQuery = account ? {
    'request.accountId': account.id
  } : {};

  const usersReceived = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfConversations',
            relation: 'partnerConversations',
            $where: {
              requestId: {
                $not: null
              },
              invited: true,
              ...accountQuery
            }
          }
        ],
        $where: {
          numberOfConversations: {
            $gte: 1
          },
          isEducator: false,
          // ...REQUIRED_EDUCATOR_FIELDS
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const usersResponded = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfConversations',
            relation: 'partnerConversations',
            $where: {
              requestId: {
                $not: null
              },
              invited: true,
              stage: {
                $not: 'Invited',
                // $in: [
                //   // 'Invited',
                //   'Interested',
                //   'Scheduling',
                //   'Attending',
                //   'Declined',
                //   'Passed'
                // ]
              },
              ...accountQuery
            }
          }
        ],
        $where: {
          numberOfConversations: {
            $gte: 1
          },
          isEducator: false,
          // ...REQUIRED_EDUCATOR_FIELDS
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Received an invite (responded/received)"
      stat={`${usersResponded.meta ? usersResponded.meta.totalCount : '...'}/${usersReceived.meta ? usersReceived.meta.totalCount : '...'}`}
      icon={UsersIcon}
    />
  );
}
