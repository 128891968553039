import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Recommend(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Recommend"
      // subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="Would you recommend this community partner to another educator?">
        {evaluation.data.recommend}
      </TextField>
      <TextField label="What age group would you recommend them for?">
        {evaluation.data.recommendYes}
      </TextField>
      <TextField label="Why not?">
        {evaluation.data.recommendNo}
      </TextField>
      <TextField label="Would you like to tell us more about why this community partner was not a good fit?">
        {evaluation.data.recommendNoMessage}
      </TextField>
      <TextField label="Would you recommend this community partner receive additional training or coaching before engaging again with learners?">
        {evaluation.data.training}
      </TextField>
      <TextField label="What training would you recommend?">
        {evaluation.data.trainingMessage}
      </TextField>
    </Section>
  );
}
