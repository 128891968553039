import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import Feed from '../../Feed';
import Comment from '../../Comment';
import Section from '../Section';
import StageTag from '../../../explore-conversations/Table/StageTag';

export default function Conversation(props) {
  const { conversation } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString())

  const messages = connect('message.find', {
    where: {
      eager: {
        $where: {
          conversationId: conversation.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      order: 'createdAt asc',
      pageSize: 100
    }
  });

  return (
    <Section
      title="Conversation"
      subtitle="Messages between educator and partner."
    >
      <Feed models={messages}>
        {function(message) {
          const creator = connect('user.byId', {
            id: message.data.authorId
          });

          return (
            <Comment
              model={{
                ...message,
                data: {
                  ...message.data,
                  text: message.data.content
                }
              }}
              system={!(
                message.data.type === 'Message'
                // message.data.type === 'Message' ||
                // message.data.type === 'PartnerInterested' ||
                // message.data.type === 'EducatorInterested'
              )}
              tag={(
                <StageTag model={message}/>
              )}
              title={`${creator.data.firstName} ${creator.data.lastName}`}
              imageSrc={creator.data.avatarUrl}
            />
          );
        }}
      </Feed>
    </Section>
  );
}
