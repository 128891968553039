export default function setSelectedColumns(initialColumns, selectedColumns = []) {
  if (selectedColumns.length === 0) {
    return initialColumns;
  }

  return initialColumns.map(function(initialColumn) {
    const column = {
      ...initialColumn,
      selected: false
    };

    if (selectedColumns.indexOf(column.id) >= 0) {
      column.selected = true;
    }

    return column;
  });
}
