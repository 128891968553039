import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import UserRole from '../../../components/UserRole';
import Loader from '../../../components/Loader';
import avatar from '../../../../assets/images/avatar.svg';
import { MailIcon } from '@heroicons/react/solid';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    onBack,
    offer,
    user
  } = props;

  const currentUser = useUser();
  const [timestamp] = useState(moment().toISOString())

  const reminders = useConnect('recommendation.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          educatorId: user.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    sortBy: function(model) {
      return moment(model.data.createdAt).unix();
    }
  });

  if (reminders.state === PayloadStates.FETCHING) {
    return (
      <FormTemplate
        title={`Recommend this offer to ${user.data.firstName || 'an educator'}?`}
        subtitle={(
          <span>
            This will send {user.data.firstName || 'the educator'} an email with the offer. From there they can review the
            details and let the partner know if they want to discuss further.
          </span>
        )}
        showIcon={false}
        icon={ClockIcon}
        onClose={onCancel}
        alert={alert}
        body={(
          <div className="mt-4">
            <Loader />
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onBack}>
              Back
            </OutlineButton>
            <PrimaryButton onClick={onSubmit} disabled={true}>
              Send recommendation
            </PrimaryButton>
          </>
        )}
      />
    );
  }

  if (reminders.data.length > 0) {
    return (
      <FormTemplate
        title={`Recommend this offer to ${user.data.firstName || 'an educator'}?`}
        subtitle={(
          <span>
            This will send {user.data.firstName || 'the educator'} an email with the offer. From there they can review the
            details and let the partner know if they want to discuss further.
          </span>
        )}
        showIcon={false}
        icon={ClockIcon}
        onClose={onCancel}
        alert={alert}
        body={(
          <div className="mt-4">
            {reminders.data.length > 0 && (
              <div className="text-sm text-gray-700 mb-8">
                <h3 className="mb-2">
                  Recommendation history:
                </h3>
                <ul className="list-disc list-inside ml-4">
                  {reminders.data.map(function (reminder) {
                    return (
                      <li key={reminder.id || reminder.cid}>
                        Recommendation was sent on {moment(reminder.data.createdAt).tz(currentUser.data.timezone).format('MMM Do YYYY, hh:mm a')}
                        <span className="text-sm uppercase font-bold text-accent ml-2">
                        <MessageDeliveryStatus message={reminder} />
                      </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <p className="text-sm text-gray-700 mb-6">
              This offer has already been recommended to this educator. Would you like to recommend
              this offer to a different educator?
            </p>
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onCancel}>
              Close
            </OutlineButton>
            <PrimaryButton onClick={onBack}>
              Select different educator
            </PrimaryButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title={`Recommend this offer to ${user.data.firstName || 'an educator'}?`}
      subtitle={(
        <span>
          This will send {user.data.firstName || 'the educator'} an email with a link to the
          offer. From there they can review the details and let the partner know if they
          are interested.
        </span>
      )}
      showIcon={false}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="mt-4 space-y-6">
          <div className="block bg-white shadow rounded-lg">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                <div className="flex-shrink-0">
                  <img className="h-12 w-12 rounded-full object-cover" src={user.data.avatarUrl || avatar} alt="Avatar" />
                </div>
                <div className="min-w-0 flex-1 px-4 ">
                  <div>
                    <p className="text-sm font-medium text-indigo-600x truncate">
                      {user.data.firstName} {user.data.lastName}
                    </p>
                    <p className="mt-1 flex items-center text-sm text-gray-500">
                      <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="truncate">
                        {user.data.email}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-sm text-gray-700 mb-6">
            Would you like to include a custom message about why you're sending this recommendation?
          </p>
          <FormField name="message" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TextAreaField
                label="Custom Message"
                placeholder="Enter message..."
                rows={11}
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </div>
      )}
      footer={(
        <>
          <OutlineButton onClick={onBack}>
            Back
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Send recommendation
          </PrimaryButton>
        </>
      )}
    />
  );
}
