import React  from 'react';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function Training(props) {
  const { evaluation } = props;

  return (
    <SupportingTextColumn
      title={evaluation.data.training}
      subtitle={evaluation.data.trainingMessage}
    />
  );
}
