import { parse, stringify } from 'query-string';
import { PayloadStates } from '@lore/utils';
import { insertOrRemoveNumberFromArray } from './query';
import _ from 'lodash';

export function onChange(param, { location, history }) {
  return function(search) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        [param]: search,
        page: 1
      }))
    });
  }
}

export function onChangeArray(param, { location, history }) {
  return function(model) {
    const newQuery = insertOrRemoveNumberFromArray(parse(location.search), param, model.id);

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, newQuery, {
        page: 1
      }))
    });
  }
}

export function onChangeLocationCondition(condition, { location, history }) {
  if (!condition.address) {
    delete condition.address;
    delete condition.latitude;
    delete condition.longitude;
  }

  history.push({
    pathname: location.pathname,
    search: stringify(_.extend({}, parse(location.search), {
      locationCondition: condition.address ? JSON.stringify(condition) : undefined
    }))
  });
}

export function mapArray(data) {
  return {
    state: PayloadStates.RESOLVED,
    data: data.map(function(value, index) {
      return {
        id: index + 1,
        data: {
          name: value
        }
      }
    })
  };
}

export default {
  onChange,
  onChangeArray,
  onChangeLocationCondition,
  mapArray
}
