import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        numberOfRequests: {
          $in: values.map(function(value) {
            return Number(value) - 1;
          })
        }
      }
    }
  }
}
