import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import useRouter from '../../../hooks/useRouter';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

PaginationLink.propTypes = {
  page: PropTypes.number,
  text: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDivider: PropTypes.bool,
  onNavigate: PropTypes.func
};

export default function PaginationLink(props) {
  const {
    onNavigate,
    page,
    isDisabled,
    isDivider,
    isActive,
    previous = false,
    next = false
  } = props;

  const { location } = useRouter();

  const query = parse(location.search);
  query.page = page;

  const text = props.text || page;

  function onClick(e) {
    if (onNavigate) {
      e.preventDefault();
      onNavigate(page);
    }
  }

  if(isDivider) {
    return (
      <div
        aria-current="page"
        className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium opacity-50 cursor-not-allowed"
      >
        <span className="sr-only">{text}</span>
        <span dangerouslySetInnerHTML={{__html: text}} />
      </div>
    );
  }

  if (previous) {
    if (isDisabled) {
      return (
        <div
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 opacity-50 cursor-not-allowed"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      );
    } else {
      return (
        <Link
          to={{ pathname: location.pathname, search: stringify(query) }}
          onClick={onClick}
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      );
    }
  }

  if (next) {
    if (isDisabled) {
      return (
        <div
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      );
    } else {
      return (
        <Link
          to={{ pathname: location.pathname, search: stringify(query) }}
          onClick={onClick}
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </Link>
      );
    }
  }

  if(isDisabled) {
    return (
      <div
        className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium opacity-50 cursor-not-allowed"
      >
        <span className="sr-only">{text}</span>
        <span dangerouslySetInnerHTML={{__html: text}} />
      </div>
    );
  }

  if(isActive) {
    return(
      <Link
        to={{ pathname: location.pathname, search: stringify(query) }}
        onClick={onClick}
        aria-current="page"
        className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
        // className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">{text}</span>
        <span dangerouslySetInnerHTML={{__html: text}} />
      </Link>
    );
  }

  return (
    <Link
      to={{ pathname: location.pathname, search: stringify(query) }}
      onClick={onClick}
      className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
    >
      <span className="sr-only">{text}</span>
      <span dangerouslySetInnerHTML={{__html: text}} />
    </Link>
  );
}
