import React  from 'react';
import User from './User';

export default function Partner(props) {
  const { conversation } = props;

  return (
    <User
      conversation={{
        data: {
          userId: conversation.data.partnerId
        }
      }}
    />
  );
}
