import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      const lt_3 = values.filter(v => Number(v) !== 4);
      const gte_3 = values.filter(v => Number(v) === 4).length > 0;
      return {
        numberOfEducatorInteractions: {
          // $in: values.map(function(value) {
          //   return Number(value) - 1;
          // })
          $or: _.remove([
            lt_3.length > 0 ? {
              $in: lt_3.map(function(value) {
                return Number(value) - 1;
              })
            } : null,
            gte_3 ? {
              $gte: 3
            } : null
          ])
        }
      }
    }
  }
}
