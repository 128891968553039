import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';

RequestFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function RequestFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10
    ].map(function(value) {
      return {
        id: value + 1,
        data: {
          name: `${value}`
        }
      }
    })
  };

  const users = function(model) {
    return connect('user.find', {
      where: {
        eager: {
          $aggregations: [
            {
              type: 'count',
              alias: 'numberOfRequests',
              relation: 'requests'
            }
          ],
          $where: {
            numberOfRequests: {
              $equals: model.id - 1,
            },
            isEducator: true
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Requests created"
      // dropdownLabel1="They should teach"
      // dropdownLabel2="of the following subjects:"
      queryUtils={getQueryUtils}
      filterName="request"
      queryParamName="requests"
      tags={models}
      related={users}
    />
  );
};
