import React from 'react';
import moment from 'moment-timezone';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Action(props) {
  const { conversation } = props;

  const days = moment().diff(conversation.data.createdAt, 'days');

  if (days <= 3) {
    return (
      <TextColumn>
        No action is recommended; this is shown only to inform you about recent activity.
      </TextColumn>
    );
  }

  if (days <= 5) {
    return (
      <TextColumn>
        We recommend sending a reminder to each person nudging them to review the invitation
        and express interest or decline.
      </TextColumn>
    );
  }

  if (days <= 7) {
    return (
      <TextColumn>
        We recommend sending a second (and final) reminder to each person nudging them to
        respond to the the invitation.
      </TextColumn>
    );
  }

  if (days <= 10) {
    return (
      <TextColumn>
        Consider reaching out to these people personally via email or a phone call to find
        out if they got the invitation and/or are still interested in being part of the community.
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      If no response has been received at this point, we suggest declining the invitation
      so the educator can move on and look for other partners.
      {/*Decline the invitation*/}
    </TextColumn>
  );
}
