import React  from 'react';
import TextColumn from '../TextColumn';

export default function Twitter(props) {
  const { user } = props;

  if (!user.data.twitterHandle) {
    return (
      <TextColumn />
    );
  }

  return (
    <TextColumn>
      <a href={`https://twitter.com/${user.data.twitterHandle}`} target="_blank">
        {user.data.twitterHandle}
      </a>
    </TextColumn>
  );
}
