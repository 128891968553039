import React from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';

export default function OfferPartners(props) {
  const { account } = props;

  const accountQuery = account ? {
    'interaction.conversation.request.accountId': account.id
  } : {};

  const evaluations = useConnect('evaluation.find', {
    where: {
      eager: {
        $where: {
          type: 'PartnerEval',
          contributionAmount: {
            $gte: 0
          },
          ...accountQuery
        }
      },
    },
    pagination: {
      pageSize: 100
    }
  });

  if (evaluations.state === PayloadStates.FETCHING) {
    return (
      <Metric
        name="Total in-kind contributions"
        stat="..."
        icon={CurrencyDollarIcon}
      />
    )
  }

  const total = _.reduce(evaluations.data, function(result, evaluation) {
    return result + evaluation.data.contributionAmount;
  }, 0);

  return (
    <Metric
      name="Total in-kind contributions"
      stat={`$${total}`}
      icon={CurrencyDollarIcon}
    />
  );
}
