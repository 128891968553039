import React from 'react';
import { parse, stringify } from 'query-string';
import { MISSING_EDUCATOR_FIELDS } from '../../utils/user/MISSING_FIELDS';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect, returnRawQuery = false } = props;

  function getRequiredFields() {
    const tenant = window.location.host.split('.')[0];

    if (tenant === 'tucson') {
      return {
        ...MISSING_EDUCATOR_FIELDS
      }
    }

    return {
      ...MISSING_EDUCATOR_FIELDS
    }
  }

  const requiredFields = getRequiredFields();

  const query = {
    where: {
      eager: {
        $where: {
          isEducator: true,
          // isHidden: false,
          ...requiredFields
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  if (returnRawQuery) {
    return query;
  }

  return connect('user.find', query);
}
