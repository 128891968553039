import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Outcome(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Outcome"
      subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="It helped me realize that I have something valuable to offer to students and teachers.">
        {evaluation.data.statement1}
      </TextField>
      <TextField label="It helped me realize that I have things to learn from others who may be different than me.">
        {evaluation.data.statement2}
      </TextField>
      <TextField label="It increased my understanding of the realities of a teacher's job.">
        {evaluation.data.statement4}
      </TextField>
      <TextField label="I am likely to volunteer again.">
        {evaluation.data.statement5}
      </TextField>
    </Section>
  );
}
