import React from 'react';
import { useConnect } from '@lore/connect';
import _ from 'lodash';
import TextField from '../TextField';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';

function getName(entry, userEntry) {
  if (entry.data.name === 'Other') {
    const keys = userEntry.data.description.split('||');
    return `Other (${keys[0]})`;
  }

  return entry.data.name;
}

function getDescription(entry, userEntry) {
  if (entry.data.name === 'Other') {
    const keys = userEntry.data.description.split('||');
    return keys[1];
  }

  return userEntry.data.description;
}

export default function Skills(props) {
  const { user } = props;

  const connect = useConnect();

  const expertiseTypes = useConnect('expertiseType.find', {
    pagination: {
      pageSize: 100
    }
  });

  const userExpertises = connect('userExpertise.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (
    expertiseTypes.state === PayloadStates.FETCHING ||
    userExpertises.state === PayloadStates.FETCHING ||
    userExpertises.data.length === 0
  ) {
    return null;
  }

  const expertises = userExpertises.data.map(function(userExpertise) {
    return connect('expertise.byId', {
      id: userExpertise.data.expertiseId
    });
  });

  const groups = _.groupBy(expertises, function(expertise) {
    return expertise.data.expertiseTypeId;
  });

  return (
    <Section
      title="Skills and experience"
      subtitle="I can share my work experience and interests related to the topics below with youth and educators."
      subdivide={true}
    >
      {_.map(groups, function(value, key) {
        const expertiseType = _.find(expertiseTypes.data, function(expertiseType) {
          return expertiseType.id == key;
        })

        return (
          <div key={key} className="space-y-8 sm:space-y-6">
            {/*<Heading*/}
            {/*  // title={expertiseType ? expertiseType.data.name : '...'}*/}
            {/*  subtitle={expertiseType ? expertiseType.data.name : '...'}*/}
            {/*/>*/}
            <div className="pt-6">
              <div className="flex items-center">
                <h3 className="font-medium leading-6 text-md text-gray-900">
                  {expertiseType ? expertiseType.data.name : '...'}
                </h3>
              </div>
            </div>
            {value.map(function(entry) {
              const userExpertise = _.find(userExpertises.data, function(userExpertise) {
                return userExpertise.data.expertiseId === entry.id;
              });

              return (
                <TextField
                  invert={true}
                  key={userExpertise.id}
                  label={getName(entry, userExpertise)}
                >
                  {getDescription(entry, userExpertise)}
                </TextField>
              );
            })}
          </div>
        );
      })}
    </Section>
  )
}
