import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react'

export default function SidePanel(props) {
  const modalRef = useRef(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    show();
  }, []);

  function show() {
    setOpen(true);
  }

  function dismiss() {
    setOpen(false);
  }

  function afterLeave() {
    console.log('I left!')
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        // className="fixed inset-0 overflow-hidden"
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
        initialFocus={modalRef}
      >
        <div ref={modalRef}/>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterEnter={props.afterEnter}
            afterLeave={props.afterLeave}
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-400"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-400"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div>
                {React.cloneElement(props.children, {
                  onClose: dismiss
                })}
                {props.dialog}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
