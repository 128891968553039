import React from 'react';
import Id from '../explore-conversations/Table/Id';
import Request from '../explore-conversations/Table/Request';
import Status from '../explore-conversations/Table/Status';
import Days from '../explore-conversations/Table/Days';
import Educator from '../explore-conversations/Table/Educator';
import Partner from '../explore-conversations/Table/Partner';
import Action from './Table/Action';

export default [
  { id: 'id',       name: 'ID',               row: Id,       selected: false },
  { id: 'partner',  name: 'Partner',          row: Partner,  selected: true },
  { id: 'educator', name: 'Educator',         row: Educator, selected: true },
  { id: 'request',  name: 'Request',          row: Request,  selected: true },
  { id: 'status',   name: 'Status',           row: Status,   selected: true },
  { id: 'days',     name: 'Invite sent',      row: Days,     selected: true },
  { id: 'action',   name: 'Suggested action', row: Action,   selected: true }
];
