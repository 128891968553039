import React from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';

export default function Organizations(props) {
  const { account } = props;

  const accountQuery = account ? {
    'accounts.id': account.id
  } : {};

  const schools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfPartners',
            relation: 'users',
            $where: {
              isEducator: false,
              ...accountQuery
            }
          }
        ],
        $where: {
          numberOfPartners: {
            $gt: 0
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Total count"
      stat={schools.meta ? schools.meta.totalCount : '...'}
      icon={OfficeBuildingIcon}
    />
  );
}
