import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import classNames from '../utils/classNames';
import Logo from '../../assets/images/logo_and_name.png';
import primaryNavigationLinks from './_utils/primaryNavigationLinks';
import { Link } from 'react-router-dom';
import useRouter from '../hooks/useRouter';
import MobileNavigationProfile from './MobileNavigationProfile';

import dashboardNavigation from './dashboard/navigationLinks';
import getTroubleshootNavigation from './troubleshoot/navigationLinks';
import teamNavigation from './team/navigationLinks';
import exploreNavigation from './explore/navigationLinks';
import settingsNavigation from './settings/navigationLinks';
import TransitionsSecondaryNavigation from './TransitionsSecondaryNavigation';

// HACK: Prevent wantings in browser
const secondaryNavigationLinks = [];

export default function Transitions(props) {
  const { sidebarOpen, setSidebarOpen } = props;
  const { location } = useRouter();
  const modalRef = useRef(null);

  const troubleshootNavigation = getTroubleshootNavigation();

  const navigationMap = {
    'dashboard': {
      title: 'Dashboard',
      nav: dashboardNavigation
    },
    'troubleshoot': {
      title: '',
      nav: troubleshootNavigation,
      grouped: true
    },
    'team': {
      title: 'Team',
      nav: teamNavigation
    },
    'explore': {
      title: 'Explore',
      nav: exploreNavigation
    },
    // 'settings': {
    //   title: 'Settings',
    //   nav: settingsNavigation
    // }
  };

  const routeRoot = location.pathname.split('/')[1];
  const secondaryNavigation = navigationMap[routeRoot] || {
    title: '',
    nav: []
  };

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 lg:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
        initialFocus={modalRef}
      >
        <div ref={modalRef}/>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center px-4">
              <img
                className="h-8 w-auto"
                // src="https://tailwindui.com/img/logos/workflow-logo-purple-500-mark-gray-700-text.svg"
                src={Logo}
                alt="Workflow"
              />
            </div>
            <MobileNavigationProfile />
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2">
                <div className="space-y-1">
                  {primaryNavigationLinks.map((item) => {
                    const current = location.pathname.indexOf(item.href) >= 0;

                    return (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          current
                            // ? 'bg-gray-100 text-gray-900'
                            ? 'bg-accent text-white'
                            : 'text-orange-100 hover:text-white hover:bg-accent',
                          'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                        )}
                        aria-current={current ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            current ? 'text-white' : 'text-orange-100 group-hover:text-white',
                            'mr-3 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    );
                  })}
                </div>
                <div className="mt-8">
                  {secondaryNavigation.title && (
                    <h3
                      className="px-3 text-xs font-semibold text-white uppercase tracking-wider mb-1"
                      id="teams-headline"
                    >
                      {secondaryNavigation.title}
                    </h3>
                  )}
                  {/*<SidebarNavigation*/}
                  {/*  navigation={exploreNavigation}*/}
                  {/*  grouped={false}*/}
                  {/*/>*/}
                  <TransitionsSecondaryNavigation
                    navigation={secondaryNavigation.nav}
                    grouped={secondaryNavigation.grouped}
                  />

                </div>
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true">
          {/* Dummy element to force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
