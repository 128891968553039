import { extractObjectFromQuery } from '../../../utils/query';

export default {
  param: extractObjectFromQuery({
    radius: 5
  }),
  query: function (value) {
    if (
      value.radius &&
      value.address &&
      value.latitude &&
      value.longitude
    ) {
      return {
        id: {
          $location: {
            radius: value.radius,
            latitude: value.latitude,
            longitude: value.longitude,
            // address: state.value.address
          }
        }
      };
    }
  }
}
