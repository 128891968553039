import _snakeCase from "lodash/snakeCase";

// todo: rename this to something like ActionTypeGenerator...

/**
 * Methods that generate ActionTypes using naming conventions. Used by action and
 * reducer blueprints.
 *
 * TODO: Since these functions generate ActionTypes, maybe rename it to
 * ActionTypeGenerator or ActionTypeFactory?
 *
 * Example Usage:
 * ADD_ITEM    = ActionType.add('item')
 * UPDATE_ITEM = ActionType.update('item')
 * REMOVE_ITEM = ActionType.remove('item')
 * FETCH_ITEM  = ActionType.fetch('item')
 * FETCH_ITEMS = ActionType.fetchPlural('item')
 *
 * @returns {Object} Set of functions to generate Action Types
 */
function formatModelName(modelName) {
  return _snakeCase(modelName).toUpperCase();
}

export default {
  add: function add(modelName) {
    return "ADD_".concat(formatModelName(modelName));
  },
  update: function update(modelName) {
    return "UPDATE_".concat(formatModelName(modelName));
  },
  remove: function remove(modelName) {
    return "REMOVE_".concat(formatModelName(modelName));
  },
  fetch: function fetch(modelName) {
    return "FETCH_".concat(formatModelName(modelName));
  },
  fetchPlural: function fetchPlural(modelName) {
    return "FETCH_".concat(formatModelName(modelName), "S");
  },
  refetchPlural: function refetchPlural(modelName) {
    return "REFETCH_".concat(formatModelName(modelName), "S");
  },
  reset: function reset(modelName) {
    return "RESET_".concat(formatModelName(modelName));
  },
  RESET_STORE: 'RESET_STORE'
};