import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Communities(props) {
  const { user } = props;

  const accounts = useConnect('account.find', {
    where: {
      eager: {
        $where: {
          'users.id': user.id
        }
      }
    },
    pagination: {
      order: 'subdomain asc'
    }
  })

  if (accounts.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {accounts.data.map(function(account) {
        return account.data.subdomain;
      }).join(', ')}
    </TextColumn>
  );
}
