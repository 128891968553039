import { mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import activityTypes from './activityTypes';
import ages from './ages';
import contributions from './contributions';
import createdDates from './createdDates';
import experiences from './experiences';
import expertiseTypes from './expertiseTypes';
import groups from './groups';
import guidances from './guidances';
import hours from './hours';
import locationCondition from './locationCondition';
import name from './name';
import organization from './organization';
import partnerInteractions from './partnerInteractions';
import searchPartner from './searchPartner';
import students from './students';

export default mapAttributes({
  accounts,
  activityTypes,
  ages,
  contributions,
  createdDates,
  experiences,
  expertiseTypes,
  groups,
  guidances,
  hours,
  locationCondition,
  name,
  organization,
  partnerInteractions,
  searchPartner,
  students
});
