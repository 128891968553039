import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import People from '../../_components/sidePanel/conversation/People';
import Conversation from '../../_components/sidePanel/conversation/Conversation';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import { PayloadStates } from '@lore/utils';

export default function SidePanel(props) {
  const {
    conversation,
    primaryAction = '',
    secondaryAction = '',
    extraActions = []
  } = props;

  const connect = useConnect();

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const request_offer = conversation.data.requestId ? connect('request.byId', {
    id: conversation.data.requestId
  }) : connect('offer.byId', {
    id: conversation.data.offerId
  });

  if (request_offer.state === PayloadStates.FETCHING) {
    return (
      <Panel title="Conversation" onClose={props.onClose}>
        Loading...
      </Panel>
    )
  }

  const activity = connect('activity.byId', {
    id: request_offer.data.activityId
  });

  return (
    <Panel title="Conversation" onClose={props.onClose}>
      <div>
        <Header
          src={activity.data.imageUrl}
          title={request_offer.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          {/*<Actions*/}
          {/*  conversation={conversation}*/}
          {/*  primaryAction={primaryAction}*/}
          {/*  secondaryAction={secondaryAction}*/}
          {/*  extraActions={extraActions}*/}
          {/*/>*/}
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <People conversation={conversation} />
            <Conversation conversation={conversation} />
            {/*<Outcome conversation={conversation} />*/}
            {/*<Recommend conversation={conversation} />*/}
            {/*<Training conversation={conversation} />*/}
            {/*<Platform conversation={conversation} />*/}
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <div className="px-6">
              <Notes conversation={conversation} />
            </div>
          </div>
        )}
      </div>
    </Panel>
  );
}
