import React from 'react';
import { Link } from 'react-router-dom';
import useRouter from '../hooks/useRouter';
import classNames from '../utils/classNames';

export default function TransitionsSecondaryNavigation(props) {
  const { navigation = [], grouped = false } = props;

  const { location } = useRouter();

  if (grouped) {
    return (
      <nav className="px-3x py-2 space-y-8">
        {navigation.map(function(item, index) {
          return (
            <div key={index} className="mt-8x">
              <h3 className="px-3 text-xs font-semibold text-orange-100 uppercase tracking-wider" id="projects-headline">
                {item.title}
              </h3>
              <div className="mt-1 space-y-1">
                {item.links.map(function(link) {
                  const current = location.pathname.indexOf(link.href) >= 0;

                  return (
                    <Link
                      key={link.name}
                      to={link.href}
                      // className={classNames(
                      //   current ? 'bg-gray-200 text-gray-900 ' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                      //   'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                      // )}
                      className={classNames(
                        current ? 'bg-accent text-white' : 'text-orange-100 hover:text-white hover:bg-accent',
                        // 'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
                        'group flex items-center px-3 py-2 font-medium rounded-md text-base leading-5'
                      )}
                      aria-current={current ? 'page' : undefined}
                    >
                      {/*<props.icon*/}
                      {/*  className={classNames(*/}
                      {/*    current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',*/}
                      {/*    'mr-3 h-6 w-6'*/}
                      {/*  )}*/}
                      {/*  aria-hidden="true"*/}
                      {/*/>*/}
                      {link.name}
                      {link.count ? (
                        <span
                          className={classNames(
                            current ? 'bg-gray-100 text-gray-600' : 'bg-gray-100 text-gray-600',
                            'ml-auto inline-block py-0.5 px-3 text-xs rounded-full'
                          )}
                        >
                          {link.count}
                        </span>
                      ) : null}
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        })}
      </nav>
    );
  }

  return (
    <nav className="px-3x">
      {/*<h3 className="px-3 text-xs font-semibold text-orange-100 uppercase tracking-wider" id="projects-headline">*/}
      {/*  Explore*/}
      {/*</h3>*/}
      <div className="space-y-1">
        {navigation.map((item) => {
          const current = location.pathname.indexOf(item.href) >= 0;

          return (
            <Link
              key={item.name}
              to={item.href}
              // className={classNames(
              //   current ? 'bg-gray-200 text-gray-900 ' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
              //   'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
              // )}
              className={classNames(
                current ? 'bg-accent text-white' : 'text-orange-100 hover:text-white hover:bg-accent',
                // 'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
                'group flex items-center px-3 py-2 font-medium rounded-md text-base leading-5'
              )}
              // className={classNames(
              //   current
              //     // ? 'bg-gray-100 text-gray-900'
              //     ? ''
              //     : 'text-orange-100 hover:text-white hover:bg-accent',
              //   'group flex items-center px-3 py-2 text-base leading-5 font-medium rounded-md'
              // )}
              aria-current={current ? 'page' : undefined}
            >
              {/*<item.icon*/}
              {/*  className={classNames(*/}
              {/*    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',*/}
              {/*    'mr-3 h-6 w-6'*/}
              {/*  )}*/}
              {/*  aria-hidden="true"*/}
              {/*/>*/}
              {item.name}
              {item.count ? (
                <span
                  className={classNames(
                    current ? 'bg-gray-100 text-gray-600' : 'bg-gray-100 text-gray-600',
                    'ml-auto inline-block py-0.5 px-3 text-xs rounded-full'
                  )}
                >
                  {item.count}
                </span>
              ) : null}
            </Link>
          );
        })}
      </div>
    </nav>
  );
}
