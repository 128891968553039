import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title="Leave a note"
      subtitle="This note will be visible to everyone on your team."
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="text" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TextAreaField
                className="sm:col-span-6"
                label="Note"
                placeholder="Write something..."
                // description="Some text about a thing I like"
                rows={3}
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <>
          <PrimaryButton onClick={onSubmit}>
            Save
          </PrimaryButton>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
        </>
      )}
    />
  );
}
