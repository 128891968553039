import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

SearchFilterLabelDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default function SearchFilterLabelDropdown(props) {
  const {
    value,
    options,
    onChange
  } = props;

  const selectedOption = _.find(options, function(option) {
    return option.id == value;
  });

  return (
    <select
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      value={selectedOption.id}
      onChange={(event) => {
        // onHideDropdown();
        onChange(event.target.value);
      }}
    >
      {options.map(function(option) {
        return (
          <option
            key={option.id}
            value={option.id}
            onClick={() => {
              // onHideDropdown();
              onChange(option.id);
            }}
          >
            {option.name}
          </option>
        );
      })}
    </select>
  );
};
