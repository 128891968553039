import React from 'react';
import { useConnect } from '@lore/connect';
import TextField from '../TextField';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';

export default function Interests(props) {
  const { user } = props;

  const connect = useConnect();

  const userGrades = connect('userGrade.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  const userPractices = connect('userPractice.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (
    userGrades.state === PayloadStates.FETCHING ||
    userPractices.state === PayloadStates.FETCHING ||
    (
      userGrades.data.length === 0 &&
      userPractices.data.length === 0
    )
  ) {
    return null;
  }

  return (
    <Section
      title="Grades and teaching practices"
      // subtitle="Interested in..."
    >
      {userGrades.data.length > 0 && (
        <TextField label="Grade level I teach">
          {userGrades.data.map(function(userGrade) {
            return (
              <div key={userGrade.id}>
                {connect('grade.byId', {
                  id: userGrade.data.gradeId
                }).data.name || '...'}
              </div>
            );
          })}
        </TextField>
      )}
      {userPractices.data.length > 0 && (
        <TextField label="Teaching practices I want to learn more about">
          {userPractices.data.map(function(userPractice) {
            return (
              <div key={userPractice.id}>
                {connect('practice.byId', {
                  id: userPractice.data.practiceId
                }).data.name || '...'}
              </div>
            )
          })}
        </TextField>
      )}
    </Section>
  )
}
