import React from 'react';
import TextField from '../TextField';
import Section from '../Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import { PayloadStates } from '@lore/utils';
import EducatorSidePanel from '../../../explore-educators/SidePanel';
import PartnerSidePanel from '../../../explore-partners/SidePanel';
import RequestSidePanel from '../../../explore-requests/SidePanel';
import InteractionSidePanel from '../../../explore-interactions/SidePanel';
import moment from 'moment-timezone';

function Loader() {
  return (
    <Section>
      <TextField label="Educator">
        ...
      </TextField>
      <TextField label="Partner">
        ...
      </TextField>
      <TextField label="Request">
        ...
      </TextField>
      <TextField label="Interaction">
        ...
      </TextField>
    </Section>
  );
}

export default function People(props) {
  const { evaluation } = props;

  const connect = useConnect();
  const show = useDialog();

  const interaction = connect('interaction.byId', {
    id: evaluation.data.interactionId
  });

  if (interaction.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const conversation = connect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const request = connect('request.byId', {
    id: conversation.data.requestId
  });

  const educator = connect('user.byId', {
    id: conversation.data.educatorId
  });

  const partner = connect('user.byId', {
    id: conversation.data.partnerId
  });

  if (
    request.state === PayloadStates.FETCHING ||
    educator.state === PayloadStates.FETCHING ||
    partner.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const activity = connect('activity.byId', {
    id: request.data.activityId
  });

  function onClickEducator() {
    show(
      <EducatorSidePanel user={educator} />
    );
  }

  function onClickPartner() {
    show(
      <PartnerSidePanel user={partner} />
    );
  }

  function onClickRequest() {
    show(
      <RequestSidePanel request={request} />
    );
  }

  function onClickInteraction() {
    show(
      <InteractionSidePanel interaction={interaction} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Educator">
        <span onClick={onClickEducator} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {educator.data.firstName} {educator.data.lastName}
        </span>
      </TextField>
      <TextField label="Partner">
        <span onClick={onClickPartner} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {partner.data.firstName} {partner.data.lastName}
        </span>
      </TextField>
      <TextField label="Request">
        <span onClick={onClickRequest} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {request.data.title || activity.data.name}
        </span>
      </TextField>
      <TextField label="Interaction">
        <span onClick={onClickInteraction} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {moment(interaction.data.date).format('MMM Do, YYYY')} ({interaction.data.type})
        </span>
      </TextField>
    </Section>
  );
}
