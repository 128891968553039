import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';

export default function TotalEducators(props) {
  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          isEducator: true
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Total count"
      stat={users.meta ? users.meta.totalCount : '...'}
      icon={UsersIcon}
    />
  );
}
