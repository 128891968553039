import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';

UserRole.propTypes = {
  user: PropTypes.object
};

export default function UserRole(props) {
  const { user, children } = props;

  const connect = useConnect();

  const userOrganizations = connect('userOrganization.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  if (userOrganizations.state === PayloadStates.FETCHING) {
    return children ? children('...', {}) : '...';
  }

  if (!userOrganizations.data[0]) {
    return children ? children('', {}) : '';
  }

  const userOrganization = userOrganizations.data[0];

  const organization = connect('organization.byId', {
    id: userOrganization.data.organizationId
  });

  const { role } = userOrganization.data;
  const { name } = organization.data;

  if (children) {
    return children(`${role || ''}${role && name ? ' @ ' : ''}${name}`, {
      userOrganization,
      organization
    });
  }

  return `${role || ''}${role && name ? ' @ ' : ''}${name}`;
}
