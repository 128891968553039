import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function PartnerStudents(props) {
  const { user } = props;

  const connect = useConnect();

  const evaluations = connect('evaluation.find', {
    where: {
      eager: {
        $where: {
          'interaction.conversation.partnerId': user.id
        }
      }
    }
  })

  if (evaluations.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const students = _.reduce(evaluations.data, function(result, evaluation) {
    return result + Number(evaluation.data.served || 0);
  }, 0)

  return (
    <TextColumn>
      {students}
    </TextColumn>
  );
}
