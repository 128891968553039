import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const [timestamp] = useState(moment().toISOString());

  return connect('request.find', {
    where: {
      eager: {
        $aggregations: [
          'Invited',
          'Interested',
          'Scheduling',
          'Attending'
        ].map(function(stage) {
          return {
            type: 'count',
            alias: `numberOfConversations${stage}`,
            relation: 'conversations',
            $where: {
              stage: stage
            }
          };
        }),
        $where: {
          activityId: {
            $not: null
          },
          published: true,
          numberOfConversationsInvited: 0,
          numberOfConversationsInterested: 0,
          numberOfConversationsScheduling: 0,
          numberOfConversationsAttending: 0,
          endDate: {
            $or: {
              $equals: null,
              $gte: timestamp
            }
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });
}
