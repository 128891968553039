import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, onChangeLocationCondition, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), [
    'accounts',
    'activityTypes',
    'ages',
    'contributions',
    'createdDates',
    'experiences',
    'expertiseTypes',
    'groups',
    'guidances',
    'hours',
    'locationCondition',
    'name',
    'organization',
    'partnerInteractions',
    'searchPartner',
    'students'
  ], timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      <Tags
        type="Community"
        query={searchParams.accounts}
        models={connect('account.find', params)}
        onChange={onChangeArray('accounts', { location, history })}
        label={tag => tag.data.subdomain}
      />
      <Tags
        type="Activity type"
        query={searchParams.activityTypes}
        models={connect('activityType.find', params)}
        onChange={onChangeArray('activityTypes', { location, history })}
      />
      <Tags
        type="Age group"
        query={searchParams.ages}
        models={connect('age.find', params)}
        onChange={onChangeArray('ages', { location, history })}
      />
      <Tags
        type="In-kind contribution amount"
        query={searchParams.contributions}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            [1, '0'],
            [2, '$1+'],
            [5, '$5+'],
            [10, '$10+'],
            [25, '$25+'],
            [50, '$50+'],
            [100, '$100+'],
            [250, '$250+'],
            [500, '$500+'],
            [100, '$1000+'],
          ].map(function(value) {
            return {
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('contributions', { location, history })}
      />
      <Tags
        type="Joined"
        query={searchParams.createdDates}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            // ['lte_1_weeks', 'Within 1 week'],
            // ['lte_2_weeks', 'Within 2 weeks'],
            // ['lte_4_weeks', 'Within 1 month'],
            // ['lte_8_weeks', 'Within 2 months'],
            // ['lte_13_week', 'Within 3 months'],
            // ['lte_26_week', 'Within 6 months'],
            // ['lte_52_week', 'Within the year']
            [1, 'Within 1 week'],
            [2, 'Within 2 weeks'],
            [4, 'Within 1 month'],
            [8, 'Within 2 months'],
            [13, 'Within 3 months'],
            [26, 'Within 6 months'],
            [52, 'Within the year']
          ].map(function(value) {
            // const weeks = Number(value[0].split('_')[1]);

            return {
              // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('createdDates', { location, history })}
      />
      <Tags
        type="Experience"
        query={searchParams.experiences}
        models={connect('experience.find', params)}
        onChange={onChangeArray('experiences', { location, history })}
      />
      <Tags
        type="Expertise type"
        query={searchParams.expertiseTypes}
        models={connect('expertiseType.find', params)}
        onChange={onChangeArray('expertiseTypes', { location, history })}
      />
      <Tags
        type="Youth"
        query={searchParams.groups}
        models={connect('group.find', params)}
        onChange={onChangeArray('groups', { location, history })}
      />
      <Tags
        type="Professional competency"
        query={searchParams.guidances}
        models={connect('guidance.find', params)}
        onChange={onChangeArray('guidances', { location, history })}
      />
      <Tags
        type="Hours served"
        query={searchParams.hours}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            [1, '0'],
            [2, '1+'],
            [5, '5+'],
            [10, '10+'],
            [25, '25+'],
            [50, '50+'],
            [100, '100+']
          ].map(function(value) {
            return {
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('hours', { location, history })}
      />
      {searchParams.locationCondition.address && (
        <Tag
          type="Location"
          query={`${searchParams.locationCondition.radius} miles of ${searchParams.locationCondition.address}`}
          onChange={() => onChangeLocationCondition({}, { location, history })}
        />
      )}
      <Tag
        type="Name"
        query={searchParams.name}
        onChange={onChange('name', { location, history })}
      />
      <Tag
        type="Organization"
        query={searchParams.organization}
        onChange={onChange('organization', { location, history })}
      />
      <Tags
        type="Interactions"
        query={searchParams.partnerInteractions}
        models={{
          data: [
            0,
            1,
            2,
            3
          ].map(function(value) {
            return {
              id: value + 1,
              data: {
                name: value === 3 ? `${value}+` :`${value}`
              }
            }
          })
        }}
        onChange={onChangeArray('partnerInteractions', { location, history })}
      />
      <Tag
        type="Search"
        query={searchParams.searchPartner}
        onChange={onChange('searchPartner', { location, history })}
      />
      <Tags
        type="Students served"
        query={searchParams.students}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            [1, '0'],
            [2, '1+'],
            [25, '25+'],
            [50, '50+'],
            [100, '100+'],
            [250, '250+'],
            [500, '500+'],
            [1000, '1000+']
          ].map(function(value) {
            return {
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('students', { location, history })}
      />
    </div>
  );
};
