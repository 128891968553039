import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Subject(props) {
  const { user } = props;

  const connect = useConnect();

  const userSubjects = connect('userSubject.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (userSubjects.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  const userSubject = userSubjects.data[0];

  if (!userSubject) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
        </div>
        <div className="text-sm text-gray-500">
        </div>
      </td>
    );
  }

  const subject = connect('subject.byId', {
    id: userSubject.data.subjectId
  });

  return (
    <TextColumn>
      {userSubjects.data.map(function(userSubject) {
        const subject = connect('subject.byId', {
          id: userSubject.data.subjectId
        });

        return subject.data.name;
      }).join(', ')}
    </TextColumn>
  )

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900 line-clamp-1">
        {subject.data.name} {userSubjects.data.length > 1 && (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
            + {userSubjects.data.length - 1}
          </span>
        )}
      </div>
      <div className="text-sm text-gray-500 line-clamp-1">
        {userSubject.data.role}
      </div>
    </td>
  );
}
