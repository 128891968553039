import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        stage: {
          $or: values.map(function(value) {
            return {
              1: 'Invited',
              2: 'Interested',
              3: 'Scheduling',
              4: 'Attending',
              5: 'Declined',
              6: 'Passed'
            }[value]
          })
        }
      }
    }
  }
}
