import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Auth0 from 'auth0-js';
import { useConfig } from '@lore/config';
import auth from '../utils/auth';
import storage from '../utils/storage';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import Error from './Error';

export default withRouter(function AuthCallback(props) {
  const { history } = props;

  const [error, setError] = useState(null);

  const config = useConfig();

  useEffect(() => {
    const auth0 = new Auth0.WebAuth(config.auth0);

    auth0.parseHash((err, authResult) => {
      try {
        if (
          err &&
          err.error === 'login_required'
        ) {
          return auth0.authorize();
        }

        if (
          err &&
          err.error === 'unauthorized' &&
          err.errorDescription.indexOf('Please verify your email before logging in') >= 0
        ) {
          return history.push('/verify');
        }

        if (err) {
          return setError(err);
        }

        if (
          authResult &&
          authResult.accessToken && authResult.idToken
        ) {
          auth.saveToken(authResult.idToken);

          if (storage.has('redirectUrl')) {
            const redirectUrl = storage.get('redirectUrl');
            storage.delete('redirectUrl');
            return history.push(redirectUrl);
          }

          return history.push('/');
        }

        return setError(authResult);
      } catch(error) {
        setError(error);
      }
    });
  }, []);

  if (error) {
    return (
      <>
        <RemoveLoadingScreen />
        <Error
          title="Login Error"
          description={JSON.stringify(error)}
        />
      </>
    );
  }

  return null;
});
