import React from 'react';
import { useConnect } from '@lore/connect';
import TextField from '../TextField';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';

export default function Interests(props) {
  const { user } = props;

  const connect = useConnect();

  const userActivityTypes = connect('userActivityType.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  const userAges = connect('userAge.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  const userGroups = connect('userGroup.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (
    userActivityTypes.state === PayloadStates.FETCHING ||
    userAges.state === PayloadStates.FETCHING ||
    userGroups.state === PayloadStates.FETCHING ||
    (
      userActivityTypes.data.length === 0 &&
      userAges.data.length === 0 &&
      userGroups.data.length === 0
    )
  ) {
    return null;
  }

  return (
    <Section
      title="Collaboration interests"
      subtitle="Interested in..."
    >
      {userActivityTypes.data.length > 0 && (
        <TextField label="Activity types I want to collaborate on">
          {userActivityTypes.data.map(function(userActivityType) {
            return (
              <div key={userActivityType.id}>
                {connect('activityType.byId', {
                  id: userActivityType.data.activityTypeId
                }).data.name || '...'}
              </div>
            );
          })}
        </TextField>
      )}
      {userAges.data.length > 0 && (
        <TextField label="Age groups I want to work with">
          {userAges.data.map(function(userAge) {
            return (
              <div key={userAge.id}>
                {connect('age.byId', {
                  id: userAge.data.ageId
                }).data.name || '...'}
              </div>
            )
          })}
        </TextField>
      )}
      {userGroups.data.length > 0 && (
        <TextField label="Age groups I want to work with">
          {userGroups.data.map(function(userGroup) {
            return (
              <div key={userGroup.id}>
                {connect('group.byId', {
                  id: userGroup.data.groupId
                }).data.name || '...'}
              </div>
            )
          })}
        </TextField>
      )}
    </Section>
  )
}
