import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import Table from './Table';
import EducatorSidePanel from '../explore-educators/SidePanel';
import PartnerSidePanel from '../explore-partners/SidePanel';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../team/Layout';
import useRouter from '../../hooks/useRouter';
import initialColumns from './columns';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const show = useDialog();

  function onClick(user) {
    if (user.data.isEducator) {
      show(
        <EducatorSidePanel user={user}/>
      );
    } else {
      show(
        <PartnerSidePanel user={user}/>
      );
    }
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Team']}
          title="Staff"
        />
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return getState('user.find', {
              where: {
                eager: {
                  $where: {
                    isSuperuser: true
                  }
                }
              },
              pagination: {
                page: parse(location.search).page || '1',
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              }
            });
          }}
          row={function (user) {
            return (
              <tr key={user.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(user)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      user={user}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
