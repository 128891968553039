import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import getQueryUtils from '../queryUtils';
import StringField from '../../../forms/_fields/StringField';

Organization.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Organization(props) {
  const { location, history } = props;
  const { params: searchParams } = getQueryUtils(parse(location.search), ['organization']);

  function onChangeSchool(organization) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        organization: organization,
        page: 1
      }))
    });
  }

  return (
    <StringField
      label="Partner organization"
      placeholder="Enter organization name..."
      value={searchParams.organization}
      onChange={onChangeSchool}
    />
  );
};
