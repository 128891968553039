import React from 'react';
import { MailIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';

export default function Schools(props) {
  const requests = useConnect('request.find', {
    where: {
      eager: {
        $where: {
          published: true
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  })

  return (
    <Metric
      name="Published requests"
      stat={requests.meta ? requests.meta.totalCount : '...'}
      icon={MailIcon}
    />
  );
}
