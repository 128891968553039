import React  from 'react';
import { useConnect } from '@lore/connect';

export default function Status(props) {
  const { interaction } = props;

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-500">
        {interaction.data.accepted ? (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            ATTENDING
          </span>
        ) : (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
            PENDING
          </span>
        )}
      </div>
    </td>
  );
}
