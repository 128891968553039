import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function Website(props) {
  const { offer } = props;

  return (
    <Section
      title="Website"
      subtitle="More information about this offer can be found at"
    >
      <TextField label="">
        <a className="text-orange-500 underline hover:text-orange-400" href={offer.data.website} target="_blank">
          {offer.data.website}
        </a>
      </TextField>
    </Section>
  )
}
