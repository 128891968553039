import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import offerActivities from './offerActivities';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const ages = connect('activity.find', {
    where: {
      eager: {
        $where: {
          id: {
            $in: offerActivities
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'name asc'
    }
  });

  const users = function(age) {
    return connect('offer.find', {
      where: {
        eager: {
          $where: {
            activityId: age.id
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Activity"
      dropdownLabel="It should be one of the following activities:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="activity"
      queryParamName="activities"
      tags={ages}
      related={users}
    />
  );
};
