import React from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import Metric from '../Metric';
import { PayloadStates } from '@lore/utils';

export default function StudentsServed(props) {
  const { account } = props;

  const accountQuery = account ? {
    'interaction.conversation.request.accountId': account.id
  } : {};

  const evaluations = useConnect('evaluation.find', {
    where: {
      eager: {
        $where: {
          type: 'EducatorEval',
          ...accountQuery
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  const count = _.reduce(evaluations.data, function(result, evaluation) {
    return result + Number(evaluation.data.served || 0);
  }, 0);

  return (
    <Metric
      name="Students served (from educator eval)"
      stat={evaluations.state === PayloadStates.FETCHING ? '...' : count}
      icon={OfficeBuildingIcon}
    />
  );
}
