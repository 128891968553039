import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import getQueryUtils from '../queryUtils';
import Dropdown from '../../_components/search/Dropdown';
import SearchFilter from '../../_components/search/_search/SearchFilter';
import SearchFilterLabel from '../../_components/search/_search/SearchFilterLabel';
import SearchFilterLabelDropdown from '../../_components/search/_search/SearchFilterLabelDropdown';
import LocationSearchField from '../../_components/search/LocationSearchField';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import PayloadStates from '../../../constants/PayloadStates';
import classNames from '../../../utils/classNames';
import { useDialog } from '@lore/dialogs';
import SidePanel from '../../explore-educators/SidePanel';
import LocationDialog from '../../../dialogs/location/create';

Location.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Location(props) {
  const { location, history } = props;
  const { params: searchParams } = getQueryUtils(parse(location.search), ['locationCondition']);

  function onChangeLocationCondition(condition) {
    if (!condition) {
      return;
    }

    if (!condition.address) {
      delete condition.name;
      delete condition.address;
      delete condition.latitude;
      delete condition.longitude;
    }

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        locationCondition: JSON.stringify(condition)
      }))
    });
  }

  const { locationCondition } = searchParams;

  const show = useDialog();

  function onClick(user) {
    show(
      <LocationDialog
        location={locationCondition}
        onSubmit={onChangeLocationCondition}
      />, { template: 'modal' }
    );
  }

  return (
    <Listbox value={null} onChange={onChangeLocationCondition}>
      {({ open }) => (
        <>
          {/*<Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>*/}
          <div className="mt-1-x relative" onClick={onClick}>
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">
                Location
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>
          </div>
        </>
      )}
    </Listbox>
  );
};
