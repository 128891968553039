import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react'

export default function DialogTemplate(props) {
  const modalRef = useRef(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    show();
  }, []);

  function show() {
    setOpen(true);
  }

  function dismiss() {
    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        // className="fixed inset-0 overflow-hidden"
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={(open) => {
          // setOpen(open)
        }}
        initialFocus={modalRef}
      >
        <div ref={modalRef}/>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterEnter={props.afterEnter}
            afterLeave={props.afterLeave}
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <span>
              {React.cloneElement(props.children, {
                onClose: dismiss
              })}
              {props.dialog}
            </span>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
