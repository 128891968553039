import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Training(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Training"
      // subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="Would you like to receive additional training to support your volunteering?">
        {evaluation.data.training}
      </TextField>
      <TextField label="What training would you like?">
        {evaluation.data.trainingMessage}
      </TextField>
    </Section>
  );
}
