import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import About from '../../_components/sidePanel/request/About';
import Personal from '../../_components/sidePanel/request/Personal';
import Organizations from '../../_components/sidePanel/request/Organizations';
import DateTime from '../../_components/sidePanel/request/DateTime';
import Location from '../../_components/sidePanel/request/Location';
import Audience from '../../_components/sidePanel/request/Audience';
import Partner from '../../_components/sidePanel/request/Partner';
import Resources from '../../_components/sidePanel/request/Resources';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import Conversations from './Conversations';

export default function SidePanel(props) {
  const {
    request,
    primaryAction = '',
    secondaryAction = '',
    extraActions = []
  } = props;

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'conversations', name: 'Conversations', selected: false },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const activity = useConnect('activity.byId', {
    id: request.data.activityId
  })

  return (
    <Panel title="Request" onClose={props.onClose}>
      <div>
        <Header
          src={activity.data.imageUrl}
          title={request.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          {/*<Actions*/}
          {/*  request={request}*/}
          {/*  primaryAction={primaryAction}*/}
          {/*  secondaryAction={secondaryAction}*/}
          {/*  extraActions={extraActions}*/}
          {/*  onClose={props.onClose}*/}
          {/*/>*/}
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <About request={request} />
            <Personal request={request} />
            <Organizations request={request} />
            <DateTime request={request} />
            <Location request={request} />
            <Audience request={request} />
            <Partner request={request} />
            <Resources request={request} />
          </div>
        )}
        {selectedTab.id === 'conversations' && (
          <div className="mb-6x">
            <Conversations request={request} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes request={request} />
          </div>
        )}
      </div>
    </Panel>
  )
}
