import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { useConnect } from '@lore/connect';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../settings/Layout';
import useRouter from '../../hooks/useRouter';
// import Screening from './Screening';
import EducatorForm from './Form.educator';
import PartnerForm from './Form.partner';
import Loader from '../../components/Loader';
import { PayloadStates } from '@lore/utils';
import useAccount from '../../hooks/useAccount';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { tenant } = useAccount();
  const account = useConnect('account.first', {
    where: {
      eager: {
        $where: {
          subdomain: tenant
        }
      }
    }
  });

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Settings']}
          title="Banner"
          description={(
            <>
              <span className="block text-left">
                Have something you want to share with all educators or partners on the platform? You
                can add a message here and it will be displayed to each audience.
              </span>
            </>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {account.state === PayloadStates.FETCHING ? (
          <div className="px-2 py-4 sm:px-3 lg:px-4s space-y-6">
            <Loader />
          </div>
        ) : (
          <div className="px-2 py-4 sm:px-3 lg:px-4s space-y-6">
            <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
              <EducatorForm account={account} />
            </div>
            <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
              <PartnerForm account={account} />
            </div>
          </div>
        )}
      </main>
    </ParentLayout>
  );
}
