import React from 'react';
import TextField from '../TextField';
import Section from '../Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import SidePanel from '../../../explore-educators/SidePanel';

export default function Personal(props) {
  const { request } = props;

  const user = useConnect('user.byId', {
    id: request.data.creatorId
  });

  const show = useDialog();

  function onClick() {
    show(
      <SidePanel user={user} />
    );
  }

  return (
    <Section
      title="Educator information"
      subtitle="Name and contact info."
    >
      <TextField label="Educator">
        <span onClick={onClick} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {user.data.firstName} {user.data.lastName}
        </span>
      </TextField>
      <TextField label="Email">
        {user.data.email}
      </TextField>
      <TextField label="Phone">
        {user.data.phoneNumber}
      </TextField>
    </Section>
  );
}
