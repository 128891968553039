import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import PayloadStates from '../constants/PayloadStates';

export default function useUserProfileFields(user) {
  if (!user) {
    throw new Error('user is required')
  }

  function include(model) {
    return model.data.userId === user.id;
  }

  function exclude(model) {
    return model.state === PayloadStates.DELETED;
  }

  const userAges = useConnect('userAge.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    include: include,
    exclude: exclude
  });
  const userActivityTypes = useConnect('userActivityType.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    include: include,
    exclude: exclude
  });
  const userExpertises = useConnect('userExpertise.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    include: include,
    exclude: exclude
  });
  const userSubjects = useConnect('userSubject.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    include: include,
    exclude: exclude
  });
  const userGrades = useConnect('userGrade.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    include: include,
    exclude: exclude
  });

  return {
    user,
    userAges,
    userActivityTypes,
    userExpertises,
    userSubjects,
    userGrades
  };
};
