import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    evaluation
  } = props;

  const currentUser = useUser();

  const reminders = useConnect('evaluationReminder.find', {
    where: {
      eager: {
        $where: {
          evaluationId: evaluation.id
        }
      }
    },
    sortBy: function(model) {
      return moment(model.data.createdAt).unix();
    },
    include: function(model) {
      return (
        model.data.evaluationId === evaluation.id &&
        model.state === PayloadStates.RESOLVED
      );
    }
  });

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  let _data = {
    evaluationId: evaluation.id,
    userId: user.id,
    type: evaluation.data.type
  };

  return (
    <FormTemplate
      title="Send reminder to complete evaluation"
      subtitle={(
        <span>
          This will send an email to {user.data.firstName} asking them to evaluate their experience as a partner.
        </span>
      )}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="mt-4">
          {reminders.data.length > 0 && (
            <div className="text-sm text-gray-700 mb-8">
              <h3 className="mb-2">
                Reminder history:
              </h3>
              <ul className="list-disc list-inside ml-4">
                {reminders.data.map(function (reminder, index) {
                  const typeMap = {
                    1: 'First',
                    2: 'Second',
                    3: 'Third'
                  }
                  return (
                    <li key={reminder.id || reminder.cid}>
                      {typeMap[index + 1]} reminder sent on {moment(reminder.data.createdAt).tz(currentUser.data.timezone).format('MMM Do YYYY, hh:mm a')}
                      <span className="text-sm uppercase font-bold text-accent ml-2">
                        <MessageDeliveryStatus message={reminder} />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {reminders.data.length >= 3 ? (
            <p className="text-sm text-gray-700 mb-6">
              All allowed reminders have been sent.
            </p>
          ) : (
            <p className="text-sm text-gray-700 mb-6">
              Would you like to send reminder #{reminders.data.length + 1}?
            </p>
          )}
        </div>
      )}
      footer={reminders.data.length >= 3 ? (
        <>
          <OutlineButton onClick={onCancel}>
            Close
          </OutlineButton>
        </>
      ) : (
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={() => onSubmit(_data)}>
            Send reminder
          </PrimaryButton>
        </>
      )}
    />
  );
}
