import React from 'react';
import PropTypes from 'prop-types';

SearchFilterLabel.propTypes = {
  children: PropTypes.node.isRequired
};

export default function SearchFilterLabel(props) {
  const { children } = props;

  return (
    <div className="mr-4 align-bottom">
      {children}
    </div>
  );
};
