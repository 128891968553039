import { extractArrayFromQuery } from '../../../utils/query';
import moment from 'moment-timezone';

export default {
  param: extractArrayFromQuery,
  query: function (values, timestamp) {
    if (!timestamp) {
      throw new Error('Required param (timestamp) is missing');
    }

    if (values.length > 0) {
      return {
        published: true,
        activityId: {
          $not: null
        },
        endDate: {
          $or: values.map(function(value) {
            if (value === 1) {
              return {
                $gte: moment(timestamp).toISOString()
              }
            }

            return {
              $lt: moment(timestamp).toISOString()
            }
          })
        }
      }
    }
  }
}
