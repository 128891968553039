import React from 'react';
import Id from './Table/Id';
import Request from './Table/Request';
import Educator from './Table/Educator';
import Partner from './Table/Partner';
import Date from './Table/Date';
import Status from './Table/Status';
import Place from './Table/Place';
import School from './Table/School';
import Community from './Table/Community';

export default [
  { id: 'id',               name: 'ID',               row: Id,        selected: false },
  { id: 'community',        name: 'Community',        row: Community, selected: false },
  { id: 'request',          name: 'Request',          row: Request,   selected: true },
  { id: 'educator',         name: 'Educator',         row: Educator,  selected: true },
  { id: 'school',           name: 'School',           row: School,    selected: false },
  { id: 'partner',          name: 'Partner',          row: Partner,   selected: true },
  { id: 'interaction_date', name: 'Start Date',       row: Date,      selected: true },
  { id: 'status',           name: 'Status',           row: Status,    selected: true },
  { id: 'place',            name: 'Place',            row: Place,     selected: true }
];
