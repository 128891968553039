import React from 'react';
import Id from '../explore-evaluations-educators/Table/Id';
import User from '../explore-evaluations-educators/Table/User';
import Request from '../explore-evaluations-educators/Table/Request';
import Date from '../explore-evaluations-educators/Table/Date';
import Status from '../explore-evaluations-educators/Table/Status';
import Training from '../explore-evaluations-educators/Table/Training';
import Served from '../explore-evaluations-educators/Table/Served';
import EducatorStatements from '../explore-evaluations-educators/Table/EducatorStatements';
import PartnerStatements from '../explore-evaluations-educators/Table/PartnerStatements';
import Experience from '../explore-evaluations-educators/Table/Experience';
import Recommend from '../explore-evaluations-educators/Table/Recommend';
import Contribution from '../explore-evaluations-educators/Table/Contribution';
import Occurrences from '../explore-evaluations-educators/Table/Occurrences';
import Hours from '../explore-evaluations-educators/Table/Hours';
import Platform from '../explore-evaluations-educators/Table/Platform';
import Testimonial from '../explore-evaluations-educators/Table/Testimonial';
import Support from '../explore-evaluations-educators/Table/Support';
import Educator from '../explore-evaluations-educators/Table/Educator';
import Partner from '../explore-evaluations-educators/Table/Partner';
import PartnerPhone from '../explore-evaluations-educators/Table/PartnerPhone';
import EducatorPhone from '../explore-evaluations-educators/Table/EducatorPhone';
import Community from '../explore-evaluations-educators/Table/Community';

export default [
  { id: 'id',                  name: 'ID',                   row: Id,                 selected: false },
  { id: 'community',           name: 'Community',            row: Community,          selected: false },
  { id: 'user',                name: 'Partner',              row: User,               selected: true },
  { id: 'partner_phone',       name: 'Partner phone',        row: PartnerPhone,       selected: false },
  { id: 'educator',            name: 'Educator',             row: Educator,           selected: false },
  { id: 'educator_phone',      name: 'Educator phone',       row: EducatorPhone,      selected: false },
  { id: 'request',             name: 'Request',              row: Request,            selected: true },
  { id: 'date',                name: 'Interaction date',     row: Date,               selected: false },
  { id: 'status',              name: 'Status',               row: Status,             selected: true },
  { id: 'training',            name: 'Training',             row: Training,           selected: false },
  // { id: 'served',              name: 'Number served',        row: Served,             selected: false },
  // { id: 'statements_educator', name: 'Statements (Educator)', row: EducatorStatements,  selected: false },
  { id: 'statements_partner',  name: 'Statements',           row: PartnerStatements,  selected: false },
  // { id: 'experience',          name: 'Experience',           row: Experience,         selected: false },
  { id: 'contribution',        name: 'In-kind contribution', row: Contribution,       selected: true },
  { id: 'recommend',           name: 'Recommend educator',   row: Recommend,          selected: true },
  { id: 'occurrences',         name: 'Number occurrences',   row: Occurrences,        selected: false },
  { id: 'hours',               name: 'Total hours',          row: Hours,              selected: false },
  { id: 'platform',            name: 'Recommend platform',   row: Platform,           selected: true },
  // { id: 'testimonial',          name: 'Testimonial',         row: Testimonial,        selected: false },
  { id: 'support',             name: 'Support',              row: Support,            selected: false },
];
