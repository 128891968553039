import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import getQueryUtils from '../queryUtils';
import StringField from '../../../forms/_fields/StringField';

export default function Title(props) {
  PropTypes.checkPropTypes({
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }, props);

  const { location, history } = props;
  const { params: searchParams } = getQueryUtils(parse(location.search), ['title']);

  function onChangeSearch(title) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        title: title,
        page: 1
      }))
    });
  }

  return (
    <StringField
      label="Offer title"
      placeholder="Enter title..."
      value={searchParams.title}
      onChange={onChangeSearch}
    />
  );
};
