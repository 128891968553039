import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classNames from '../../utils/classNames';
import avatar from '../../../assets/images/avatar.svg';

UserSelectField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.object.isRequired,
  label: PropTypes.node,
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

UserSelectField.defaultProps = {
  options: {
    data: []
  },
  placeholder: '',
  disabled: false
};

export default function UserSelectField(props) {
  const {
    value,
    options,
    label,
    optionLabel,
    placeholder,
    description,
    error,
    disabled,
    onChange
  } = props;

  const selected = value ? _.find(options.data, o => o.id === value) : null;

  return (
    <Listbox value={selected} onChange={function(person) {
      onChange(person ? person.id : null);
    }}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              {selected ? (
                <>
                  <span className="flex items-center">
                    <img src={selected.data.avatarUrl || avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                    <span className="ml-3 block truncate">
                      {selected.data.firstName} {selected.data.lastName}
                    </span>
                  </span>
                      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </>
              ) : (
                <>
                  <span className="flex items-center">
                    <img src={avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                    <span className="ml-3 block truncate">
                      Select a person...
                    </span>
                  </span>
                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </>
              )}
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.data.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img src={person.data.avatarUrl || avatar} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {person.data.firstName} {person.data.lastName}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
