import React from 'react';
import { stringify } from 'query-string';
import { UserAddIcon, BanIcon, ShareIcon, ClockIcon, UsersIcon } from '@heroicons/react/outline';
import useRouter from '../../../hooks/useRouter';
import View from '../../explore-educators/Views/View';

export default function Views(props) {
  const { history, location, timestamp } = useRouter();

  function navigate(search = {}) {
    history.push({
      pathname: location.pathname,
      search: stringify(search)
    })
  }

  return (
    <div className="flex flex-row space-x-2">
      <View
        icon={UserAddIcon}
        onClick={() => navigate({
          // createdDates: ['lte_1_weeks'].join(',')
          createdDates: [1].join(',')
        })}
      >
        New partners
      </View>
      <View
        icon={BanIcon}
        onClick={() => navigate({
          partnerInteractions: [1].join(',')
        })}
      >
        No interactions
      </View>
      <View
        icon={ShareIcon}
        onClick={() => navigate({
          partnerInteractions: [4].join(',')
        })}
      >
        3+ interactions
      </View>
      <View
        icon={ClockIcon}
        onClick={() => navigate({
          hours: [25].join(',')
        })}
      >
        25+ hours served
      </View>
      <View
        icon={UsersIcon}
        onClick={() => navigate({
          students: [50].join(',')
        })}
      >
        50+ students served
      </View>
    </div>
  )
}
