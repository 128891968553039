import React from 'react';
import { useConnect } from '@lore/connect';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Email(props) {
  const { conversation } = props;

  const user = useConnect('user.byId', {
    id: conversation.data.partnerId
  });

  return (
    <TextColumn>
      {user.data.email}
    </TextColumn>
  );
}
