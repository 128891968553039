import React  from 'react';
import moment from 'moment-timezone';

export default function Date(props) {
  const { interaction } = props;

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900 line-clamp-1">
        {moment(interaction.data.startDate).format('MMM Do, YYYY')}
      </div>
      <div className="text-sm text-gray-500">
        {moment(interaction.data.startTime).format('h:mm A')} - {moment(interaction.data.endTime).format('h:mm A')}
      </div>
    </td>
  );
}
