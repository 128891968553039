import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import People from '../../_components/sidePanel/interaction/People';
import Frequency from '../../_components/sidePanel/interaction/Frequency';
import DateTime from '../../_components/sidePanel/interaction/DateTime';
import Location from '../../_components/sidePanel/interaction/Location';
import Extra from '../../_components/sidePanel/interaction/Extra';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import { PayloadStates } from '@lore/utils';

export default function SidePanel(props) {
  const { interaction } = props;

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const conversation = useConnect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <Panel title="Interaction" onClose={props.onClose}>
        Loading...
      </Panel>
    )
  }

  const request = useConnect('request.byId', {
    id: conversation.data.requestId
  });

  if (request.state === PayloadStates.FETCHING) {
    return (
      <Panel title="Interaction" onClose={props.onClose}>
        Loading...
      </Panel>
    )
  }

  const activity = useConnect('activity.byId', {
    id: request.data.activityId
  });

  return (
    <Panel title="Interaction" onClose={props.onClose}>
      <div>
        <Header
          src={activity.data.imageUrl}
          title={request.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          {/*<Actions request={request} />*/}
        </Header>
        {/*<Tabs*/}
        {/*  tabs={tabs}*/}
        {/*  setTabs={setTabs}*/}
        {/*/>*/}
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <People interaction={interaction} />
            <Frequency interaction={interaction} />
            <DateTime interaction={interaction} />
            <Location interaction={interaction} />
            <Extra interaction={interaction} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <div className="px-6">
              <Notes request={request} />
            </div>
          </div>
        )}
      </div>
    </Panel>
  )
}
