import React from 'react';
import PropTypes from 'prop-types';
import { MailIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';
import CheckboxField from '../../../forms/_fields/CheckboxField';
import RadioButtonField from '../../../forms/_fields/RadioButtonField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    conversation
  } = props;

  return (
    <FormTemplate
      title="Would you like to decline the invitation?"
      subtitle={(
        <span>
          If yes, the educator will be notified that their invitation was declined for the reason you select below.
        </span>
      )}
      icon={MailIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="my-8">
          {conversation.data.declined ? (
            <div className="text-sm text-gray-700">
              Invitation has already been declined.
            </div>
          ) : (
            <FormField name="reason" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <>
                  <RadioButtonField
                    name="type"
                    // label="Reason*"
                    value={field.value}
                    error={field.error}
                    // placeholder="Select a role..."
                    options={{
                      data: [
                        `Email address for this partner no longer exists.`,
                        `Partner has not responded to invitation after ${moment().diff(conversation.data.createdAt, 'days')} days.`,
                        'Partner is not available for your request at this time.'
                      ].map((option) => {
                        return {
                          id: option,
                          data: {
                            name: option
                          }
                        }
                      })
                    }}
                    optionLabel="name"
                    onChange={field.onChange}
                  />
                </>
              )}
            </FormField>
          )}
        </div>
      )}
      footer={conversation.data.declined ? (
        <>
          <OutlineButton onClick={onCancel}>
            Close
          </OutlineButton>
        </>
      ) : (
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Decline invitation
          </PrimaryButton>
        </>
      )}
    />
  );
}
