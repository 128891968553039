/**
 * Configuration file for dialogs
 *
 * This file is where you define overrides for the default dialog behavior.
 */
import { getConfig } from '@lore/dialogs';

export default getConfig({

  /**
   * DOM Element ID that the dialogs should be mounted to. Should be located
   * outside the DOM element the application is mounted to.
   */

  // domElementId: 'dialog'

})
