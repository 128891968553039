import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

RadioButtonField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.object.isRequired,
  label: PropTypes.node,
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

RadioButtonField.defaultProps = {
  options: {
    data: []
  },
  placeholder: '',
  disabled: false
};

export default function RadioButtonField(props) {
  const {
    name,
    value,
    options,
    label,
    optionLabel,
    placeholder,
    description,
    error,
    disabled,
    onChange,
    className
  } = props;

  return (
    <div className={className}>
      {label && (
        <div>
          <legend className="block text-sm font-medium text-gray-700">
            {label}
          </legend>
          <p className="text-sm text-gray-500">
            {description}
          </p>
        </div>
      )}
      <div className="mt-4 space-y-4">
        {options.data.map((datum, index) => {
          return (
            <div key={datum.id || datum.cid} className="flex items-center">
              <input
                type="radio"
                id={`radio-${datum.id}`}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                name={`radio-${name}-${index}`}
                value={datum.id}
                checked={value === datum.id}
                onChange={(event) => {
                  const selectedOption = _.find(options.data, function(option) {
                    return String(option.id) === event.target.value;
                  });
                  onChange(selectedOption ? selectedOption.id : null);
                }}
              />
              <label htmlFor={`radio-${datum.id}`} className="ml-3 block text-sm font-medium text-gray-700">
                {_.isFunction(optionLabel) ? optionLabel(datum) : datum.data[optionLabel]}
              </label>
            </div>
          );
        })}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {error}
        </p>
      )}
    </div>
  );
}
