import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';

OutlineButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default function OutlineButton(props) {
  const {
    children,
    onClick,
    className,
    disabled,
    ...other
  } = props;

  return (
    <button
      type="button"
      className={classNames(
        `mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm`,
        disabled ? 'opacity-50 cursor-not-allowed' : '',
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
