import React from 'react';
import TextField from '../TextField';
import Section from '../Section';
import { useConnect } from '@lore/connect';

export default function People(props) {
  const { interaction } = props;

  const connect = useConnect();

  const place = interaction.data.placeId ? connect('place.byId', {
    id: interaction.data.placeId
  }) : {
    data: {
      name: ''
    }
  };

  return (
    <Section
      title="Location"
      subtitle="Where the interaction will take place."
    >
      <TextField label="Type">
        {interaction.data.location}
      </TextField>
      {interaction.data.location === 'Virtual' ? (
        <TextField label="Location">
          {interaction.data.locationDescription}
        </TextField>
      ) : (
        <TextField label="Location">
          {place.data.name}
        </TextField>
      )}
    </Section>
  );
}
