import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { useConnect } from '@lore/connect';
import { parse, stringify } from 'query-string';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import ParentLayout from '../troubleshoot/Layout';
import useRouter from '../../hooks/useRouter';
import Columns from '../explore-partners/Table/Columns';
import SidePanel from '../explore-evaluations-partners/SidePanel';
import defaultColumns from '../explore-evaluations-partners/columns';
import setSelectedColumns from '../../utils/setSelectedColumns';
import useQuery from './useQuery';

const initialColumns = setSelectedColumns(defaultColumns, [
  'user',
  'partner_phone',
  'request',
  'date',
  'status'
]);

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();
  const connect = useConnect();

  const show = useDialog();

  function onClick(evaluation) {
    show(
      <SidePanel
        evaluation={evaluation}
        primaryAction="evaluation_reminder"
        secondaryAction="contact"
        // extraActions={[
        //   'decline_invitation'
        // ]}
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Actions', 'Partners']}
          title="Pending Partner Evaluations"
          description={(
            <span>
              This is a list of partners who are presumed to have attended a request but have not
              completed their evaluations. Pending invitations will automatically
              be removed from this follow-up list after 2 months. You can always view all
              Evaluations (pending or completed) under the Explore tab.
            </span>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {/*<div className="px-2 py-4 sm:px-3 lg:px-4">*/}
        {/*  <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">*/}
        {/*    <Filters*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*    <SearchTags*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return useQuery({
              location,
              PAGE_SIZE,
              connect
            });
          }}
          row={function (evaluation) {
            return (
              <tr key={evaluation.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(evaluation)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      evaluation={evaluation}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
