import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import DialogContext from './DialogContext';

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired
};

let keyCounter = 0;

export function DialogProvider(props) {
  const {
    domElementId,
    templates,
    defaultTemplate = 'default',
    children
  } = props;

  const [dialogs, setDialogs] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);

  function show(dialog, options = {}) {
    const { template = defaultTemplate } = options;
    const Template = templates[template];

    keyCounter = keyCounter + 1;

    dialogs.push(
      <Template key={keyCounter}>
        {dialog}
      </Template>
    );

    // setDialogs([...dialogs]);
    setDialogs(dialogs);
    setUpdateFlag(!updateFlag);
  }

  function afterLeave(dialog, index) {
    // console.log('I left from provider!');
    dialogs.splice(index, 1);
    // setDialogs([...dialogs])
    setDialogs(dialogs);
    setUpdateFlag(!updateFlag);
  }

  function afterEnter(dialog, index) {
    // console.log('I entered from provider!');
  }

  const firstDialog = dialogs[0];

  const nestedDialogs = _.reduce(dialogs, function(result, dialog, index) {
    if (index === 0) {
      // return result;
      return React.cloneElement(result, {
        afterLeave: () => afterLeave(dialog, index),
        afterEnter: () => afterEnter(dialog, index)
      });
    }

    const _dialog = React.cloneElement(dialog, {
      afterLeave: () => afterLeave(dialog, index),
      afterEnter: () => afterEnter(dialog, index)
    });

    return React.cloneElement(result, {
      dialog: index === 0 ? undefined : _dialog,
      // afterLeave: () => afterLeave(dialog, index),
      // afterEnter: () => afterEnter(dialog, index)
    });
  }, firstDialog);

  return (
    <DialogContext.Provider value={show}>
      {/*{dialogs.map(function(dialog, index) {*/}
      {/*  return React.cloneElement(dialog, { */}
      {/*    key: index*/}
      {/*  });*/}
      {/*})}*/}
      {nestedDialogs}
      {false && dialogs.map(function(dialog, index) {
        // return dialog;
        return React.cloneElement(dialog, {
          afterLeave: () => afterLeave(dialog, index),
          afterEnter: () => afterEnter(dialog, index)
        });
        // return ReactDOM.createPortal(
        //   dialog,
        //   document.getElementById(domElementId)
        // );
      })}

      {children}
    </DialogContext.Provider>
  );
}

export default DialogProvider;
