import React from 'react';
import PropTypes from 'prop-types';
import ExpertisesFilter from './Expertises';
import AgesFilter from './Ages';
import ActivityTypesFilter from './ActivityTypes';
import ExperiencesFilter from './Experiences';
import GuidancesFilter from './Guidances';
import GroupsFilter from './Groups';
import InteractionFilter from './Interaction';
import LocationFilter from './Location';
import NameFilter from './Name';
import OrganizationFilter from './Organization';
import JoinedFilter from './Joined';
import SearchFilter from './Search';
import HoursFilter from './Hours';
import ContributionsFilter from './Contributions';
import StudentsFilter from './Students';
import CommunityFilter from './Community';

Filters.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Filters(props) {
  const { location, history, timestamp } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <ExpertisesFilter location={location} history={history} />
          <AgesFilter location={location} history={history} />
          <ActivityTypesFilter location={location} history={history} />
          <ExperiencesFilter location={location} history={history} />
          <GuidancesFilter location={location} history={history} />
          <GroupsFilter location={location} history={history} />
          <InteractionFilter location={location} history={history} />
          <JoinedFilter location={location} history={history} timestamp={timestamp}/>
          <HoursFilter location={location} history={history} />
          <ContributionsFilter location={location} history={history} />
          <StudentsFilter location={location} history={history} />
          <LocationFilter location={location} history={history} />
          <CommunityFilter location={location} history={history} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <NameFilter location={location} history={history} />
          <OrganizationFilter location={location} history={history} />
          <SearchFilter location={location} history={history} />
        </div>
      </div>
    </div>
  );
};
