import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Grade(props) {
  const { user } = props;

  const connect = useConnect();

  const userGrades = connect('userGrade.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (userGrades.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userGrades.data.map(function(userGrade) {
        const grade = connect('grade.byId', {
          id: userGrade.data.gradeId
        });

        return grade.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
