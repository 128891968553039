import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import { useDialog } from '@lore/dialogs';
import { useConnect } from '@lore/connect';
import { parse, stringify } from 'query-string';
import ParentLayout from '../troubleshoot/Layout';
import useRouter from '../../hooks/useRouter';
import Columns from '../explore-partners/Table/Columns';
import SidePanel from '../explore-partners/SidePanel';
import defaultColumns from '../explore-partners/columns';
import setSelectedColumns from '../../utils/setSelectedColumns';
import useQuery from './useQuery';

const initialColumns = setSelectedColumns(defaultColumns, [
  'name',
  'organization',
  'date_joined',
  'phone'
]);

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();
  const connect = useConnect();

  const show = useDialog();

  function onClick(user) {
    show(
      <SidePanel
        user={user}
        primaryAction="contact"
        secondaryAction="sleep_profile"
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected);

  const conversations = useConnect('conversation.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfMessages',
            relation: 'messages',
            $where: {
              type: {
                $in: [
                  'Invitation',
                  'PartnerInterested',
                  'Message',
                  'PartnerDeclined',
                  'EducatorPassed'
                ]
              }
            }
          }
        ],
        $where: {
          numberOfMessages: {
            $lte: 1
          },
          requestId: {
            $not: null
          },
          invited: true
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const map1 = _.reduce(conversations.data, function(result, conversation) {
    const { partnerId } = conversation.data;
    result[partnerId] = result[partnerId] || [];
    result[partnerId].push(conversation);
    return result;
  }, {});

  const map2 = {};

  _.keys(map1).map(function(key) {
    const value = map1[key];
    if (value.length > 1) {
      map2[key] = value;
    }
  });

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Actions', 'Partners']}
          title="Unresponsive Partners"
          description={(
            <span>
              This is a list of partners who have a history of not responding to invitations from
              educators. Partners will show up on this list after the second invitation they receive.
              We recommend contacting them directly to see if there's an issue you can help resolve; for
              example, they may be interested but not aware that emails are going to spam. If you can't
              contact them, we recommend putting their profile to sleep so that other educators don't
              reach out to someone who is unlikely to respond.
            </span>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {/*<div className="px-2 py-4 sm:px-3 lg:px-4">*/}
        {/*  <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">*/}
        {/*    <Filters*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*    <SearchTags*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return useQuery({
              location,
              PAGE_SIZE,
              connect
            });
          }}
          row={function (user) {
            return (
              <tr key={user.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(user)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      user={user}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
