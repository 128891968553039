import React from 'react';
import PropTypes from 'prop-types';
import PaginationLink from './PaginationLink';
import { ChevronLeftIcon } from '@heroicons/react/solid';

export default function PaginationLinks(props) {
  const { totalCount, pageSize, onNavigate } = props;
  const currentPage = Number(props.currentPage);

  function renderPaginationLink(pageNumber, currentPage) {
    if(pageNumber === currentPage) {
      return (
        <PaginationLink key={pageNumber} page={pageNumber} isActive={true} />
      );
    }

    return (
      <PaginationLink key={pageNumber} page={pageNumber} isActive={false} onNavigate={onNavigate}/>
    );
  }

  function renderPreviousPageLink(currentPage) {
    const previousPage = currentPage - 1;

    if(currentPage === 1) {
      return (
        <PaginationLink key="previous" previous={true} isDisabled={true} />
      );
    }

    return (
      <PaginationLink key="previous" previous={true} page={previousPage} onNavigate={onNavigate}/>
    );
  }

  function renderNextPageLink(currentPage, totalPages) {
    const nextPage = currentPage + 1;

    if (currentPage === totalPages) {
      return (
        <PaginationLink key="next" isDisabled={true} next={true}/>
      );
    }

    return (
      <PaginationLink key="next" page={nextPage} next={true} onNavigate={onNavigate}/>
    );
  }

  function renderDividerLink(key) {
    return (
      <PaginationLink key={key} text={'...'} isDivider={true} />
    );
  }

  function renderPageLinks(currentPage, totalPages) {
    const links = [];

    if (totalPages <= 5) {
      for(let i = 1; i <= totalPages; i++){
        links.push(renderPaginationLink(i, currentPage));
      }
      // links.push(renderPaginationLink(1, currentPage));
      // links.push(renderPaginationLink(2, currentPage));
      // links.push(renderPaginationLink(3, currentPage));
      // links.push(renderPaginationLink(4, currentPage));
      // links.push(renderPaginationLink(5, currentPage));
      return links;
    }

    if(currentPage < 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderPaginationLink(2, currentPage));
      links.push(renderPaginationLink(3, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderPaginationLink(2, currentPage));
      links.push(renderPaginationLink(3, currentPage));
      links.push(renderPaginationLink(4, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === 4) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderPaginationLink(2, currentPage));
      links.push(renderPaginationLink(3, currentPage));
      links.push(renderPaginationLink(4, currentPage));
      links.push(renderPaginationLink(5, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage < totalPages - 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(currentPage -1, currentPage));
      links.push(renderPaginationLink(currentPage, currentPage));
      links.push(renderPaginationLink(currentPage + 1, currentPage));
      links.push(renderDividerLink('divider-2'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === totalPages - 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages - 4, currentPage));
      links.push(renderPaginationLink(totalPages - 3, currentPage));
      links.push(renderPaginationLink(totalPages - 2, currentPage));
      links.push(renderPaginationLink(totalPages - 1, currentPage));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === totalPages - 2) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages - 3, currentPage));
      links.push(renderPaginationLink(totalPages - 2, currentPage));
      links.push(renderPaginationLink(totalPages - 1, currentPage));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages - 2, currentPage));
      links.push(renderPaginationLink(totalPages - 1, currentPage));
      links.push(renderPaginationLink(totalPages, currentPage));
    }

    return links;
  }

  // Create pagination links
  const totalPages = Math.ceil(totalCount/pageSize);
  const paginationLinks = renderPageLinks(currentPage, totalPages);

  if (totalCount === 0) {
    return null;
  }

  return (
    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
      {renderPreviousPageLink(currentPage)}
      {paginationLinks}
      {renderNextPageLink(currentPage, totalPages)}
    </nav>
  );
};
