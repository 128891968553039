import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import avatar from '../../../../assets/images/avatar.svg';

export function UserLoader() {
  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={avatar} alt=""/>
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            ...
          </div>
          <div className="text-sm text-gray-500">
            ...
          </div>
        </div>
      </div>
    </td>
  );
}

export default function User(props) {
  const { evaluation } = props;

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  if (user.state === PayloadStates.FETCHING) {
    return (
      <UserLoader />
    );
  }

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <span className="inline-block relative">
            <img className="h-10 w-10 rounded-full" src={user.data.avatarUrl || avatar} alt=""/>
            {/*{user.data.isEducator ? (*/}
            {/*  <span className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-purple-600" />*/}
            {/*) : (*/}
            {/*  <span className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-red-600" />*/}
            {/*)}*/}
          </span>
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {user.data.firstName} {user.data.lastName}
          </div>
          <div className="text-sm text-gray-500 line-clamp-1">
            {user.data.email}
          </div>
        </div>
      </div>
    </td>
  );
}
