import { mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import activities from './activities';
import ages from './ages';
import commitments from './commitments';
import costs from './costs';
import createdDates from './createdDates';
import days from './days';
import expertiseTypes from './expertiseTypes';
import groups from './groups';
import guidances from './guidances';
import instructions from './instructions';
import interactionTypes from './interactionTypes';
import name from './name';
import organization from './organization';
import participants from './participants';
import published from './published';
import searchOffer from './searchOffer';
import subjects from './subjects';
import supplies from './supplies';
import times from './times';
import title from './title';
import trainings from './trainings';

export default mapAttributes({
  accounts,
  activities,
  ages,
  commitments,
  costs,
  createdDates,
  days,
  expertiseTypes,
  groups,
  guidances,
  instructions,
  interactionTypes,
  name,
  organization,
  participants,
  published,
  searchOffer,
  subjects,
  supplies,
  times,
  title,
  trainings
});
