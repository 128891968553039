import React from 'react';
import { useDialog } from '@lore/dialogs';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import Actions from '../../_components/actions/Actions';
import PrimaryAction from '../../_components/actions/PrimaryAction';
import SecondaryAction from '../../_components/actions/SecondaryAction';
import ExtraActions from '../../_components/actions/ExtraActions';
import PlaceholderDialog from '../../../dialogs/Placeholder';
import ContactInformationDialog from '../../../dialogs/user/contact';
import EducatorReminderDialog from '../../../dialogs/evaluationReminder/educator';

export default function _Actions(props) {
  const {
    evaluation,
    primaryAction = '',
    secondaryAction = '',
    extraActions = []
  } = props;

  const show = useDialog();

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  function showPlaceholder(text) {
    show(
      <PlaceholderDialog>
        {text}
      </PlaceholderDialog>, { template: 'dialog' }
    );
  }

  const actionMap = {
    'evaluation_reminder': {
      label: 'Send reminder',
      callback: function() {
        show(
          <EducatorReminderDialog
            evaluation={evaluation}
          />, { template: 'dialog' }
        );
      }
    },
    'contact': {
      label: 'Contact',
      callback: function() {
        show(
          <ContactInformationDialog
            user={user}
          />, { template: 'dialog' }
        );
      }
    }
  }

  if (user.state === PayloadStates.FETCHING) {
    return null;
  }

  const actions = {
    primary: actionMap[primaryAction],
    secondary: actionMap[secondaryAction],
    extra: extraActions.map(function(extraAction) {
      return actionMap[extraAction];
    })
  }

  return (
    <Actions>
      {actions.primary && (
        <PrimaryAction onClick={actions.primary.callback}>
          {actions.primary.label}
        </PrimaryAction>
      )}
      {actions.secondary && (
        <SecondaryAction onClick={actions.secondary.callback}>
          {actions.secondary.label}
        </SecondaryAction>
      )}
      {actions.extra.length > 0 && (
        <ExtraActions
          actions={actions.extra.map(function(action) {
            return {
              name: action.label,
              onClick: action.callback
            }
          })}
        />
      )}
    </Actions>
  );
}
