import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';

PracticeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function PracticeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const practices = connect('practice.find', {
    pagination: {
      pageSize: 100,
      order: 'name asc'
    }
  });

  const users = function(practice) {
    return connect('user.find', {
      where: {
        eager: {
          $where: {
            "id": {
              $includes_any: {
                "userPractices.practiceId": [practice.id]
              }
            },
            isEducator: true
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Teaching practices"
      dropdownLabel1="They should be interested in"
      dropdownLabel2="of the following teaching practices:"
      queryUtils={getQueryUtils}
      filterName="practice"
      queryParamName="practices"
      tags={practices}
      related={users}
    />
  );
};
