import React  from 'react';
import classNames from '../../utils/classNames';
import _ from 'lodash';

export default function Tabs(props) {
  const { tabs, setTabs, onChange: _onChange } = props;

  function onChange(tab) {
    const nextTabs = [...tabs];
    _.mapKeys(tabs, tab => tab.selected = false);
    const selectedTab = _.find(nextTabs, { id: tab.id });
    selectedTab.selected = !selectedTab.selected;
    setTabs(nextTabs);
  }

  return (
    <div className="border-b border-gray-200 mb-6">
      <div className="px-6">
        <nav className="-mb-px flex space-x-6">
          {tabs.map((tab) => (
            <span
              key={tab.id}
              className={classNames(
                tab.selected
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
              )}
              onClick={() => _onChange ? _onChange(tab) : onChange(tab)}
            >
              {tab.name}
            </span>
          ))}
        </nav>
      </div>
    </div>
  )
}
