import React  from 'react';
import { useConnect } from '@lore/connect';

export default function Educators(props) {
  const { offer } = props;

  const invited = useConnect('conversation.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          stage: 'Invited'
        }
      }
    }
  });

  const interested = useConnect('conversation.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          stage: 'Interested'
        }
      }
    }
  });

  return (
    <td className="px-6 py-4 whitespace-nowrap space-x-2">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        {invited.data.length}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
        {interested.data.length}
      </span>
    </td>
  );
}
