import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import classNames from '../../utils/classNames';
import sidebarNavigation from '../_utils/primaryNavigationLinks';
import useRouter from '../../hooks/useRouter';
import { useUser } from '@lore/auth';
import avatar from '../../../assets/images/avatar.svg';
import { Menu, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import PrimaryMenuProfileItems from './PrimaryMenuProfileItems';

export default function PrimaryNavigation(props) {
  const currentUser = useUser();

  return (
    <Menu as="div" className="w-full px-2">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="group w-full text-sm text-left font-medium focus:outline-none focus:ring-2 focus:ring-orange-500 rounded-md">
              <div
                className={classNames(
                  // 'text-orange-100 hover:bg-accent hover:text-white',
                  'text-orange-100 hover:bg-accent hover:text-white cursor-pointer',
                  'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
                )}
              >
                <img
                  className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                  src={currentUser.data.avatarUrl || avatar}
                  alt=""
                />
                <span className="mt-2">
                  Profile
                </span>
              </div>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="w-48 z-10 mx-3 origin-top absolute bottom-12 left-20 right-0x left-0x mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
            >
              <PrimaryMenuProfileItems />
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
