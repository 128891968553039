import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import SidePanel from './SidePanel';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../explore/Layout';
import Filters from './Filters';
import useRouter from '../../hooks/useRouter';
import SearchTags from './SearchTags';
import initialColumns from './columns';
import Columns from '../explore-partners/Table/Columns';
import getQueryUtils from './queryUtils';
import Views from './Views';
import moment from 'moment-timezone';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const [timestamp] = useState(moment().toISOString());

  const show = useDialog();

  function onClick(request) {
    show(
      <SidePanel
        request={request}
        primaryAction="recommend_partner"
        secondaryAction="assign_request"
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore']}
          title="Requests"
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        <div className="px-2 py-4 sm:px-3 lg:px-4 space-y-4">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4">
            <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide mb-2">
              Frequently Used Views
            </h2>
            <Views timestamp={timestamp}/>
          </div>
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
            <Filters
              location={location}
              history={history}
              timestamp={timestamp}
            />
            <SearchTags
              location={location}
              history={history}
              timestamp={timestamp}
            />
          </div>
        </div>
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            const queryUtils = getQueryUtils(parse(location.search), [
              'accounts',
              'active',
              'activities',
              'activityTypes',
              'ages',
              'assignees',
              'commitments',
              'createdDates',
              'days',
              'expertiseTypes',
              'groups',
              'guidances',
              'interactionTypes',
              'name',
              'participants',
              'published',
              'school',
              'search',
              'stage',
              'subjects',
              'times',
              'title',
              'trainings'
            ], timestamp);

            return getState('request.find', {
              where: {
                eager: {
                  $aggregations: [
                    'Invited',
                    'Interested',
                    'Scheduling',
                    'Attending'
                  ].map(function(stage) {
                    return {
                      type: 'count',
                      alias: `numberOfConversations${stage}`,
                      relation: 'conversations',
                      $where: {
                        stage: stage
                      }
                    };
                  }),
                  $where: {
                    $and: [...queryUtils.where],
                    activityId: {
                      $not: null
                    }
                  }
                }
              },
              pagination: {
                page: parse(location.search).page || '1',
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              }
            });
          }}
          row={function (request) {
            return (
              <tr key={request.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(request)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      request={request}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
