import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Interactions(props) {
  const { user } = props;

  const connect = useConnect();

  const interactions = connect('interaction.find', {
    where: {
      eager: {
        $where: {
          [user.data.isEducator ? 'conversation.educatorId' : 'conversation.partnerId']: user.id
        }
      }
    }
  })

  if (interactions.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {interactions.data.length}
    </TextColumn>
  );
}
