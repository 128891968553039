import React  from 'react';
import { useConnect } from '@lore/connect';
import avatar from '../../../../assets/images/avatar.svg';

export default function Educator(props) {
  const { request } = props;

  const educator = useConnect('user.byId', {
    id: request.data.creatorId
  });

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={educator.data.avatarUrl || avatar} alt=""/>
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {educator.data.firstName} {educator.data.lastName}
          </div>
          <div className="text-sm text-gray-500">
            {educator.data.email}
          </div>
        </div>
      </div>
    </td>
  );
}
