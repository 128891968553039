import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Expertise(props) {
  const { user } = props;

  const connect = useConnect();

  const userExpertises = connect('userExpertise.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (userExpertises.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userExpertises.data.map(function(userExpertise) {
        const expertise = connect('expertise.byId', {
          id: userExpertise.data.expertiseId
        });

        return expertise.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
