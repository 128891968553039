import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();

  const supplies = connect('supply.find');

  const users = function(supply) {
    return connect('offer.find', {
      where: {
        eager: {
          $where: {
            "id": {
              $includes_any: {
                "offerSupplys.supplyId": [supply.id]
              }
            }
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Supplies"
      dropdownLabel1="It should require"
      dropdownLabel2="of the following supplies:"
      queryUtils={getQueryUtils}
      filterName="supply"
      queryParamName="supplies"
      tags={supplies}
      related={users}
    />
  );
};
