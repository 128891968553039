import React from 'react';
import classNames from '../../utils/classNames';

export default function Chart(props) {
  const {
    // icon,
    name,
    children,
    className
  } = props;

  const icon = null;

  return (
    <div className={classNames(
      'relative bg-white pt-2 px-4 sm:pt-2 sm:px-6 shadow rounded-lg overflow-hidden space-y-6',
      className
    )}>
      {name && (
        <dt>
          {icon && (
            <div className="absolute bg-indigo-500 rounded-md p-3">
              <props.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
          )}
          <p
            className={classNames(
              icon ? 'ml-16' : '',
              'text-sm font-medium text-gray-500 truncate'
            )}
          >
            {name}
          </p>
        </dt>
      )}
      <dd
        className={classNames(
          icon ? 'ml-16' : '',
          // 'pb-6 flex items-baseline sm:pb-7x',
          'py-2',
        )}
      >
        {/*<p className="text-2xl font-semibold text-gray-900">*/}
        {/*  {stat}*/}
        {/*</p>*/}
        {children}
      </dd>
    </div>
  )
}
