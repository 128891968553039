import React from 'react';
import { parse, stringify } from 'query-string';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  return connect('conversation.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfMessages',
            relation: 'messages'
          }
        ],
        $where: {
          numberOfMessages: {
            $lte: 1
          },
          stage: 'Invited',
          requestId: {
            $not: null
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });
}
