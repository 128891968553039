import _ from 'lodash';
// import currentUser from './currentUser';

function parse(data) {
  return {
    count: data.length,
    next: null,
    previous: null,
    results: data
  };
}

function query(model) {
  return {
    where: {
      eager: {
        $where: {
          userId: model.id
        }
      }
    },
    // pagination: {},
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  };
}

export default {

  attributes: {
    organizations: {
      type: 'collection',
      collection: 'organization',
      model: 'organization',
      parse: parse,
      query: query
    },
    // userAges: {
    //   type: 'models',
    //   model: 'userAge'
    // },
    userAges: {
      type: 'collection',
      collection: 'userAge',
      model: 'userAge',
      parse: parse,
      query: query
    },
    userGrades: {
      type: 'collection',
      collection: 'userGrade',
      model: 'userGrade',
      parse: parse,
      query: query
    },
    // userActivityTypes: {
    //   type: 'models',
    //   model: 'userActivityType'
    // },
    userActivityTypes: {
      type: 'collection',
      collection: 'userActivityType',
      model: 'userActivityType',
      parse: parse,
      query: query
    },
    // userSubjects: {
    //   type: 'models',
    //   model: 'userSubject'
    // },
    userSubjects: {
      type: 'collection',
      collection: 'userSubject',
      model: 'userSubject',
      parse: parse,
      query: (model) => _.merge(query(model), {
        where: {
          eager: {
            subject: {}
          }
        }
      })
    },
    subjects: {
      type: 'models',
      model: 'subject'
    },
    // userExpertises: {
    //   type: 'models',
    //   model: 'userExpertise'
    // },
    userExpertises: {
      type: 'collection',
      collection: 'userExpertise',
      model: 'userExpertise',
      parse: parse,
      query: (model) => _.merge(query(model), {
        where: {
          eager: {
            expertise: {}
          }
        }
      })
    },
    expertises: {
      type: 'models',
      model: 'expertise'
    },
  },

  // attributes: _.merge(_.pick(currentUser, [
  //   'userSubjects',
  //   'userAges',
  //   'userActivityTypes',
  //   'userExpertises',
  //   'subjects',
  //   'ages',
  //   'activityTypes',
  //   'expertises'
  // ]), {
  //   userAges: {
  //     query
  //   }
  // }),

  properties: {

    /**
     * Override the idAttribute if the primary key in the resource is named
     * anything other than 'id'. Doing so will allow the other methods to
     * behave as expected, such as composing the expected url for CRUD
     * operations and being able to retrieve the primary key by 'model.id'
     */

    // idAttribute: 'id'

    /**
     * Override the initialize method if you need to save data for use
     * in other functions. This is especially useful if you have a nested
     * URL for an API endpoint, like /authors/:userId/books. In that case,
     * you can save the author here and refer to it when creating the URL
     * in the url() or urlRoot() method.
     */

    // initialize: function(attributes, options) {
    //   return;
    // },

    /**
     * Override the urlRoot if your API endpoint does not match the default
     * conventions. For example, given a model named 'bookAuthor', and assuming
     * an apiRoot of 'http://localhost:1337' with pluralize set to true, the
     * endpoint for creating a model is assumed to be 'http://localhost:1337/bookAuthors'
     * If this model is on a different server (such as http://localhost:3001) or the
     * endpoint is named something different (such as book_authors or books/:id/authors)
     * you will need to set that here; urlRoot can be either a string or a function
     */

    // urlRoot: function() {
    //   return 'https://api.example.com/endpoint'
    // },

    /**
     * Override the url method if you need complete control over the final URL
     * endpoint. This is especially useful when you have an endpoint like /user
     * or /profile that returns a single resource with information about the current
     * user and an id is not required. You'll also need to override this method if
     * the route doesn't use the primary key of the resource.
     */

    // url: function() {
    //   return 'https://api.example.com/unconventional/endpoint/123'
    // },

    /**
     * Override the parse method if you need to modify data before using
     * it in the application, such as converting timestamps or adding
     * properties to absorb breaking API changes.
     */

    // parse: function(response, options) {
    //   return response;
    // },

    parse: function(response, options) {
      // remove dynamic fields created by aggregations as they will
      // interfere with updating user profiles
      // TODO: have server serialize valid fields so this isn't an issue
      [
        'numberOfRequests',
        'numberOfOffers',
        'numberOfSentMessages',
        'numberOfReceivedMessages',
        'numberOfConversations',
        'numberOfEducatorInteractions',
        'numberOfPartnerInteractions',
        'numberOfHoursServed',
        'numberOfStudentsServed',
        'totalOfContributionsProvided'
      ].forEach(function(field) {
        delete response[field];
      });

      return response;
    },

    /**
     * Override the sync method if you need to modify data before sending
     * it to the server.
     *
     * If you override this method the library will make no AJAX requests
     * for this model, so you'll need to make sure you implement the AJAX
     * call yourself or make a call to sync.apply(this, arguments).
     *
     * Use of 'sync' refers to sync method provided by the '@lore/backbone'
     * package, i.e. import { sync } from '@lore/backbone'
     */

    // sync: function() {
    //   return sync.apply(this, arguments);
    // }

  }

}
