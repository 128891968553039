import React from 'react';
import PropTypes from 'prop-types';
import GradeFilter from './Grade';
import SubjectFilter from './Subject';
import PracticeFilter from './Practice';
import InteractionFilter from './Interaction';
import RequestFilter from './Request';
import LocationFilter from '../../explore-partners/Filters/Location';
import NameFilter from './Name';
import OrganizationFilter from './Organization';
import SearchFilter from './Search';
import JoinedFilter from './Joined';
import CommunityFilter from './Community';

Filters.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Filters(props) {
  const { location, history, timestamp } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <GradeFilter location={location} history={history}/>
          <SubjectFilter location={location} history={history}/>
          <PracticeFilter location={location} history={history}/>
          <InteractionFilter location={location} history={history}/>
          <RequestFilter location={location} history={history}/>
          <JoinedFilter location={location} history={history} timestamp={timestamp}/>
          <LocationFilter location={location} history={history}/>
          <CommunityFilter location={location} history={history}/>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <NameFilter location={location} history={history} />
          <OrganizationFilter location={location} history={history} />
          <SearchFilter location={location} history={history} />
        </div>
      </div>
    </div>
  );
};
