import React from 'react';
import { useDialog } from '@lore/dialogs';
import Actions from '../../_components/actions/Actions';
import PrimaryAction from '../../_components/actions/PrimaryAction';
import SecondaryAction from '../../_components/actions/SecondaryAction';
import ExtraActions from '../../_components/actions/ExtraActions';
import PlaceholderDialog from '../../../dialogs/Placeholder';
import CompleteRequestReminderDialog from '../../../dialogs/request/CompleteRequestReminder';
import InvitePartnersReminderDialog from '../../../dialogs/request/InvitePartnersReminder';
import RecommendToPartnerDialog from '../../../dialogs/request/RecommendToPartner';
import DeleteRequestDialog from '../../../dialogs/request/Delete';
import PublishRequestDialog from '../../../dialogs/request/Publish';
import AssignRequestDialog from '../../../dialogs/request/Assign';

export default function _Actions(props) {
  const {
    request,
    primaryAction = '',
    secondaryAction = '',
    extraActions = [],
    onClose = () => {}
  } = props;

  const show = useDialog();

  function showPlaceholder(text) {
    show(
      <PlaceholderDialog>
        {text}
      </PlaceholderDialog>, { template: 'dialog' }
    );
  }

  const actionMap = {
    'complete_request': {
      label: 'Send reminder to complete request',
      callback: function() {
        show(
          <CompleteRequestReminderDialog
            request={request}
          />, { template: 'dialog' }
        );
      }
    },
    'invite_partners': {
      label: 'Send reminder to invite more partners',
      callback: function() {
        show(
          <InvitePartnersReminderDialog
            request={request}
          />, { template: 'dialog' }
        );
      }
    },
    'recommend_partner': {
      label: 'Recommend to partner',
      callback: function() {
        show(
          <RecommendToPartnerDialog
            request={request}
          />, { template: 'dialog' }
        );
      }
    },
    'delete_request': {
      label: 'Delete request',
      callback: function() {
        show(
          <DeleteRequestDialog
            request={request}
            onSubmit={() => onClose()}
          />, { template: 'dialog' }
        );
      }
    },
    'publish_request': {
      label: 'Publish request',
      callback: function() {
        show(
          <PublishRequestDialog
            request={request}
            onSubmit={() => onClose()}
          />, { template: 'dialog' }
        );
      }
    },
    'assign_request': {
      label: 'Assign request',
      callback: function() {
        show(
          <AssignRequestDialog
            request={request}
            onSubmit={() => onClose()}
          />, { template: 'dialog' }
        );
      }
    }
  }

  const actions = {
    primary: actionMap[primaryAction],
    secondary: actionMap[secondaryAction],
    extra: extraActions.map(function(extraAction) {
      return actionMap[extraAction];
    })
  }

  return (
    <Actions>
      {actions.primary && (
        <PrimaryAction onClick={actions.primary.callback}>
          {actions.primary.label}
        </PrimaryAction>
      )}
      {actions.secondary && (
        <SecondaryAction onClick={actions.secondary.callback}>
          {actions.secondary.label}
        </SecondaryAction>
      )}
      {actions.extra.length > 0 && (
        <ExtraActions
          actions={actions.extra.map(function(action) {
            return {
              name: action.label,
              onClick: action.callback
            }
          })}
        />
      )}
    </Actions>
  );
}
