import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Community(props) {
  const { conversation } = props;

  const account = useConnect('account.byId', {
    id: conversation.data.accountId
  });

  if (account.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {account.data.subdomain}
    </TextColumn>
  );
}
