import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRequest } from '@lore/request';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SuccessMessage from '../../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../_common/DialogTemplate';
import Confirmation from './Confirmation';

Dialog.propTypes = {
  conversation: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { conversation } = props;

  const [data, setData] = useState({
    reason: '',
    passed: true,
    passedByRole: 'Admin'
  });
  const [showErrors, setShowErrors] = useState(false);
  const [_request, setRequest] = useState(null);
  const [finished, setFinished] = useState(false);

  const { request, saving, success, error } = useRequest('conversation.', _request);

  useEffect(() => {
    if (success && !finished) {
      setFinished(true);
      // props.onClose();
      // props.onSubmit && props.onSubmit(request);
    }
  });

  function onSubmit() {
    setRequest(
      lore.actions.conversation.update(conversation, {
        passed: data.passed,
        passedMessage: data.reason,
        passedByRole: data.passedByRole
      }).payload
    );
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          reason: [validators.isRequired]
        }}>
          {({errors, hasError}) => {
            if (success) {
              return (
                <Confirmation
                  onCancel={props.onClose}
                />
              );
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onClose}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Idea created.
                  </SuccessMessage>
                ) : null}
                conversation={conversation}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
