import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from './_common/DialogTemplate';
import PrimaryButton from '../components/buttons/PrimaryButton';
import FormTemplate from './_common/FormTemplate';

Dialog.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node
};

export default function Dialog(props) {
  const { children } = props;

  return (
    <DialogTemplate>
      <FormTemplate
        title="Placeholder"
        // subtitle="This note will be visible to everyone on your team."
        onClose={props.onClose}
        // alert={alert}
        body={(
          <>
            {children}
          </>
        )}
        footer={(
          <>
            <PrimaryButton onClick={props.onClose}>
              Close
            </PrimaryButton>
          </>
        )}
      />
    </DialogTemplate>
  );
};
