import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';
import expiringRequests from '../troubleshoot-requests-expiring/useQuery';
import draftRequests from '../troubleshoot-requests-draft/useQuery';
import partnersRequests from '../troubleshoot-requests-partners/useQuery';
import draftOffers from '../troubleshoot-offers-draft/useQuery';
import incompletePartners from '../troubleshoot-partners-incomplete/useQuery';
import invitationsPartners from '../troubleshoot-partners-invitations/useQuery';
import unresponsivePartners from '../troubleshoot-partners-unresponsive/useQuery';
import evaluationsPartners from '../troubleshoot-partners-evaluations/useQuery';
import incompleteEducators from '../troubleshoot-educators-incomplete/useQuery';
import invitationsEducators from '../troubleshoot-educators-invitations/useQuery';
import unresponsiveEducators from '../troubleshoot-educators-unresponsive/useQuery';
import evaluationsEducators from '../troubleshoot-educators-evaluations/useQuery';

export default function getNavigationLinks() {
  const { location } = useRouter();
  const connect = useConnect();

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  return [
    {
      title: 'Requests',
      links: [
        { name: 'Needs partners', href: '/troubleshoot/requests/partners', count: getCount(partnersRequests) },
        { name: 'Expiring', href: '/troubleshoot/requests/expiring', count: getCount(expiringRequests) },
        { name: 'Draft', href: '/troubleshoot/requests/draft', count: getCount(draftRequests) }
      ]
    },
    {
      title: 'Offers',
      links: [
        { name: 'Draft', href: '/troubleshoot/offers/draft', count: getCount(draftOffers) }
      ]
    },
    {
      title: 'Partners',
      links: [
        { name: 'Incomplete Profile', href: '/troubleshoot/partners/incomplete', count: getCount(incompletePartners) },
        { name: 'Pending Invitations', href: '/troubleshoot/partners/invitations', count: getCount(invitationsPartners) },
        { name: 'Pending Evaluations', href: '/troubleshoot/partners/evaluations', count: getCount(evaluationsPartners) },
        { name: 'Unresponsive', href: '/troubleshoot/partners/unresponsive', count: getCount(unresponsivePartners) }
      ]
    },
    {
      title: 'Educators',
      links: [
        { name: 'Incomplete Profile', href: '/troubleshoot/educators/incomplete', count: getCount(incompleteEducators) },
        { name: 'Pending Invitations', href: '/troubleshoot/educators/invitations', count: getCount(invitationsEducators) },
        { name: 'Pending Evaluations', href: '/troubleshoot/educators/evaluations', count: getCount(evaluationsEducators) },
        { name: 'Unresponsive', href: '/troubleshoot/educators/unresponsive', count: getCount(unresponsiveEducators) }
      ]
    }
  ];
}
