import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const [timestamp] = useState(moment().toISOString())
  const [expireTimestamp] = useState(moment().add(21, 'days').toISOString())

  return connect('request.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfPartnersAttending',
            relation: 'conversations',
            $where: {
              'stage': 'Invited'
            }
          }
        ],
        $where: {
          activityId: {
            $not: null
          },
          numberOfPartnersAttending: {
            $lte: 0
          },
          endDate: {
            $gte: timestamp,
            $lte: expireTimestamp
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });
}
