import React, { useState } from 'react';
import Table from '../explore-partners/Table';
import SidePanel from './SidePanel';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../explore-evaluations/Layout';
import Filters from './Filters';
import useRouter from '../../hooks/useRouter';
import SearchTags from './SearchTags';
import defaultColumns from './columns';
import Columns from '../explore-partners/Table/Columns';
import getQueryUtils from './queryUtils';
import setSelectedColumns from '../../utils/setSelectedColumns';

const initialColumns = setSelectedColumns(defaultColumns, [
  // 'partner',
  // 'request',
  // 'days',
  // 'partner_email',
  // 'partner_phone'
]);

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const show = useDialog();

  function onClick(evaluation) {
    show(
      <SidePanel evaluation={evaluation}/>
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
      <div className="px-2 py-4 sm:px-3 lg:px-4">
        <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
          <Filters
            location={location}
            history={history}
          />
          <SearchTags
            location={location}
            history={history}
          />
        </div>
      </div>
      <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
        <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
          <div className="flex justify-end">
            <div className="w-1/4">
              <Columns
                columns={columns}
                setColumns={setColumns}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        pageSize={PAGE_SIZE}
        columns={selectedColumns}
        select={function (getState) {
          const queryUtils = getQueryUtils(parse(location.search), [
            'accounts',
            'educator',
            'evalExperiences',
            'partner',
            'recommendPartners',
            'school',
            'statuses',
            'testimonials',
            'title',
            'trainings'
          ]);

          return getState('evaluation.find', {
            where: {
              eager: {
                $where: {
                  $and: [...queryUtils.where],
                  type: 'EducatorEval'
                }
              }
            },
            pagination: {
              page: parse(location.search).page || '1',
              pageSize: PAGE_SIZE,
              order: 'createdAt desc'
            }
          });
        }}
        row={function (evaluation) {
          return (
            <tr key={evaluation.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(evaluation)}>
              {selectedColumns.map(function (column) {
                if (!column.row) {
                  return null;
                }

                return (
                  <column.row
                    key={column.id}
                    evaluation={evaluation}
                  />
                );
              })}
            </tr>
          );
        }}
      />
    </ParentLayout>
  );
}
