import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

export default function Dropdown(props) {
  const { title, children } = props;

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const node = useRef();

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", onClickOutside);
    } else {
      document.removeEventListener("mousedown", onClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [dropdownVisible]);

  function onShowDropdown() {
    setDropdownVisible(true);
  }

  function onHideDropdown() {
    setDropdownVisible(false);
  }

  function onClickOutside(e) {
    if (node.current.contains(e.target)) {
      return;
    }

    if (dropdownVisible) {
      onHideDropdown();
    }
  }

  return (
    <div className="border border-gray-300 rounded-lg pl-4 mr-4 whitespace-no-wrap mb-4" ref={node}>
      <div className="block py-2 text-gray-700 hover:text-gray-500 leading-tight cursor-pointer" onClick={dropdownVisible ? onHideDropdown : onShowDropdown}>
        {title}
        <i className="material-icons ml-1 align-middle text-3xl">arrow_drop_down</i>
      </div>
      <div className={`absolute shadow-card rounded-lg bg-white py-1 px-4 left-0 mx-2 z-20 ${dropdownVisible ? 'block' : 'hidden'}`}>
        {dropdownVisible && children}
      </div>
    </div>
  );
}
