import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function Social(props) {
  const { user } = props;

  return (
    <Section
      title="Personal information"
      subtitle="Contact info and demographics."
    >
      <TextField label="Email">
        {user.data.email}
      </TextField>
      <TextField label="Phone">
        {user.data.phoneNumber}
      </TextField>
      <TextField label="ZIP Code">
        {user.data.zipCode}
      </TextField>
      <TextField label="Gender">
        {user.data.gender}
      </TextField>
      <TextField label="Ethnicity">
        {user.data.ethnicity}
      </TextField>
      <TextField label="Year of birth">
        {user.data.yearOfBirth}
      </TextField>
    </Section>
  );
}
