import _ from 'lodash';
import moment from 'moment';

const timestamp = moment().toISOString(true);

// export const MISSING_EDUCATOR_FIELDS = _.omit(REQUIRED_FIELDS, [
//   'userActivityTypes',
//   'userAges',
//   'userExpertises'
// ])

// export const MISSING_PARTNER_FIELDS = _.omit(REQUIRED_FIELDS, [
//   'userSubjects',
//   'userGrades'
// ])

export const MISSING_EDUCATOR_FIELDS = {
  $or: [
    {
      firstName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      lastName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    // {
    //   biography: {
    //     $or: [
    //       {
    //         $equals: ''
    //       },
    //       {
    //         $equals: null
    //       }
    //     ]
    //   },
    // },
    {
      zipCode: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    // {
    //   userAges: {
    //     $count_lt: 1
    //   },
    // },
    {
      userGrades: {
        $count_lt: 1
      },
    },
    // {
    //   userActivityTypes: {
    //     $count_lt: 1
    //   },
    // },
    {
      userSubjects: {
        $count_lt: 1
      },
    },
    // {
    //   userExpertises: {
    //     $count_lt: 1
    //   },
    // }
  ]
}

export const MISSING_PARTNER_FIELDS = {
  $or: [
    {
      firstName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      lastName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      biography: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      zipCode: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      userAges: {
        $count_lt: 1
      },
    },
    // {
    //   userGrades: {
    //     $count_lt: 1
    //   },
    // },
    {
      userActivityTypes: {
        $count_lt: 1
      },
    },
    // {
    //   userSubjects: {
    //     $count_lt: 1
    //   },
    // },
    {
      userExpertises: {
        $count_lt: 1
      },
    }
  ]
}

export const MISSING_PARTNER_FIELDS_TUCSON = {
  $or: [
    {
      firstName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      lastName: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      biography: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      zipCode: {
        $or: [
          {
            $equals: ''
          },
          {
            $equals: null
          }
        ]
      },
    },
    {
      userAges: {
        $count_lt: 1
      },
    },
    // {
    //   userGrades: {
    //     $count_lt: 1
    //   },
    // },
    {
      userActivityTypes: {
        $count_lt: 1
      },
    },
    // {
    //   userSubjects: {
    //     $count_lt: 1
    //   },
    // },
    // {
    //   userExpertises: {
    //     $count_lt: 1
    //   },
    // }
  ]
}

export const SLEEPING_FIELDS = {
  $or: [
    {
      // both dates are in the future (not yet sleeping)
      $and: [
        { inactiveStartingAt: { $gt: timestamp } },
        { inactiveEndingAt: { $gt: timestamp } },
      ]
    },
    {
      // both dates are in the past (no longer sleeping)
      $and: [
        { inactiveStartingAt: { $lt: timestamp } },
        { inactiveEndingAt: { $lt: timestamp } },
      ]
    }
  ]
}
