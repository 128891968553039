import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, onChangeLocationCondition, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), [
    'accounts',
    'activityTypes',
    'createdDates',
    'educatorInteractions',
    'grades',
    'locationCondition',
    'name',
    'organization',
    'practices',
    'requests',
    'searchEducator',
    'subjects'
  ], timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      <Tags
        type="Community"
        query={searchParams.accounts}
        models={connect('account.find', params)}
        onChange={onChangeArray('accounts', { location, history })}
        label={tag => tag.data.subdomain}
      />
      <Tags
        type="Activity type"
        query={searchParams.activityTypes}
        models={connect('activityType.find', params)}
        onChange={onChangeArray('activityTypes', { location, history })}
      />
      <Tags
        type="Joined"
        query={searchParams.createdDates}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            // ['lte_1_weeks', 'Within 1 week'],
            // ['lte_2_weeks', 'Within 2 weeks'],
            // ['lte_4_weeks', 'Within 1 month'],
            // ['lte_8_weeks', 'Within 2 months'],
            // ['lte_13_week', 'Within 3 months'],
            // ['lte_26_week', 'Within 6 months'],
            // ['lte_52_week', 'Within the year']
            [1, 'Within 1 week'],
            [2, 'Within 2 weeks'],
            [4, 'Within 1 month'],
            [8, 'Within 2 months'],
            [13, 'Within 3 months'],
            [26, 'Within 6 months'],
            [52, 'Within the year']
          ].map(function(value) {
            // const weeks = Number(value[0].split('_')[1]);

            return {
              // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('createdDates', { location, history })}
      />
      <Tags
        type="Interactions created"
        query={searchParams.educatorInteractions}
        models={{
          data: [
            0,
            1,
            2,
            3
          ].map(function(value) {
            return {
              id: value + 1,
              data: {
                name: value === 3 ? `${value}+` :`${value}`
              }
            }
          })
        }}
        onChange={onChangeArray('educatorInteractions', { location, history })}
      />
      <Tags
        type="Grade"
        query={searchParams.grades}
        models={connect('grade.find', params)}
        onChange={onChangeArray('grades', { location, history })}
      />
      {searchParams.locationCondition.address && (
        <Tag
          type="Location"
          query={`${searchParams.locationCondition.radius} miles of ${searchParams.locationCondition.address}`}
          onChange={() => onChangeLocationCondition({}, { location, history })}
        />
      )}
      <Tag
        type="Name"
        query={searchParams.name}
        onChange={onChange('name', { location, history })}
      />
      <Tag
        type="Organization"
        query={searchParams.organization}
        onChange={onChange('organization', { location, history })}
      />
      <Tags
        type="Teaching practice"
        query={searchParams.practices}
        models={connect('practice.find', params)}
        onChange={onChangeArray('practices', { location, history })}
      />
      <Tags
        type="Requests created"
        query={searchParams.requests}
        models={mapArray([
          0,1,2,3,4,5,6,7,8,9,10
        ])}
        onChange={onChangeArray('requests', { location, history })}
      />
      <Tag
        type="Search"
        query={searchParams.searchEducator}
        onChange={onChange('searchEducator', { location, history })}
      />
      <Tags
        type="Subject"
        query={searchParams.subjects}
        models={connect('subject.find', params)}
        onChange={onChangeArray('subjects', { location, history })}
      />
    </div>
  );
};
