import React from 'react';
import { useConnect } from '@lore/connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../TextField';
import Section from '../Section';

export default function DateTime(props) {
  const { request } = props;

  const currentUser = useUser();

  const params = {
    where: {
      eager: {
        $where: {
          requestId: request.id
        }
      }
    }
  };

  const days = useConnect('day.find')
  const requestDays = useConnect('requestDay.find', params)

  const times = useConnect('time.find')
  const requestTimes = useConnect('requestTime.find', params)

  const commitments = useConnect('commitment.find')
  const requestCommitments = useConnect('requestCommitment.find', params)

  if (
    days.state === PayloadStates.FETCHING ||
    requestDays.state === PayloadStates.FETCHING ||
    times.state === PayloadStates.FETCHING ||
    requestTimes.state === PayloadStates.FETCHING ||
    commitments.state === PayloadStates.FETCHING ||
    requestCommitments.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <Section
      title="Time Commitment"
      subtitle="Date, time, and frequency."
    >
      {request.data.specificDate === 'Yes' ? (
        <TextField label="Date">
          {request.data.date && moment(request.data.date).format('MMM D, YYYY')}
        </TextField>
      ) : (
        <>
          <TextField label="Date Window">
            {(request.data.startDate && request.data.endDate) && (
              `${moment(request.data.startDate).format('MMM Do')} - ${moment(request.data.endDate).format('MMM Do')}`
            )}
          </TextField>
          <TextField label="Possible Days">
            {requestDays.data.map(function(requestDay) {
              const day = _.find(days.data, model => model.id === requestDay.data.dayId);

              return (
                <div key={day.id || day.cid}>
                  {day.data.name}
                </div>
              )
            })}
          </TextField>
        </>
      )}
      {(
        request.data.specificTime === 'Yes' &&
        request.data.startTime &&
        request.data.endTime
      ) && (
        <TextField label="Time">
          {`${moment(request.data.startTime).tz(currentUser.data.timezone).format('h:mm a')} - ${moment(request.data.endTime).tz(currentUser.data.timezone).format('h:mm a')}`}
        </TextField>
      )}
      {(
        request.data.specificTime === 'No' &&
        requestTimes.data.length > 0
      ) && (
        <TextField label="Possible Times of Day">
          {requestTimes.data.map(function(requestTime) {
            const time = _.find(times.data, model => model.id === requestTime.data.timeId);

            return (
              <div key={time.id || time.cid}>
                {time.data.name}
              </div>
            );
          })}
        </TextField>
      )}
      {requestCommitments.data.length > 0 && (
        <TextField label="Frequency">
          {requestCommitments.data.map(function(requestCommitment) {
            const commitment = _.find(commitments.data, model => model.id === requestCommitment.data.commitmentId);

            return (
              <div key={commitment.id || commitment.cid}>
                {commitment.data.name}
              </div>
            )
          })}
        </TextField>
      )}
    </Section>
  )
}
