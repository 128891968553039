import React from 'react';
import TextField from '../../TextField';
import Section from '../../Section';

export default function Interaction(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Interaction"
      // subtitle="As a community partner, please rate the following statements as they relate to your experience."
    >
      <TextField label="How was your experience interacting with the partner?">
        {evaluation.data.experience}
      </TextField>
    </Section>
  );
}
