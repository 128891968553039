import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Practice(props) {
  const { user } = props;

  const connect = useConnect();

  const userPractices = connect('userPractice.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (userPractices.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userPractices.data.map(function(userPractice) {
        const practice = connect('practice.byId', {
          id: userPractice.data.practiceId
        });

        return practice.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
