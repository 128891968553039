import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Testimonial(props) {
  const { evaluation } = props;

  return (
    <TextColumn>
      {evaluation.data.testimonial}
    </TextColumn>
  );
}
