import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Id(props) {
  const { offer } = props;

  return (
    <TextColumn>
      {offer.id}
    </TextColumn>
  );
}
