import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

StringField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node
};

StringField.defaultProps = {
  disabled: false
};

export default function StringField(props) {
  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    name,
    className
  } = props;

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ): null}
      <div className={classNames(
        label ? 'mt-1' : '',
        error ? 'relative rounded-md shadow-sm' : ''
      )}>
        <input
          // className={`form-control ${error ? 'is-invalid' : ''} ${inputClassName}`}
          type="text"
          name={name}
          className={error ?
            'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' :
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
          }
          // style={{ maxWidth: '100%', minWidth: '100%' }}
          value={value}
          placeholder={placeholder}
          readOnly={disabled}
          onChange={(event) => {
            onChange(event.target.value)
          }}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {error}
        </p>
      )}
      {description && (
        <p className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
    </div>
  );
}
