import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useDialog } from '@lore/dialogs';
import avatar from '../../../../assets/images/avatar.svg';
import Loader from '../../../components/Loader';
import SidePanel from '../../explore-conversations/SidePanel';

export default function Conversations(props) {
  const { offer } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const show = useDialog();

  function onClick(conversation) {
    show(
      <SidePanel
        conversation={conversation}
      />
    );
  }

  const conversations = connect('conversation.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      order: 'createdAt asc'
    }
  });

  if (conversations.state === PayloadStates.FETCHING) {
    return (
      <div className="px-6">
        <Loader />
      </div>
    );
  }

  const groups = _.groupBy(conversations.data, conversation => conversation.data.stage);
  const reduced = _.reduce(groups, function(result, value, key) {
    result.push({
      label: key,
      conversations: value
    });
    return result;
  }, []);
  const sortedGroups = _.sortBy(reduced, function(value) {
    return [
      'Attending',
      'Scheduling',
      'Interested',
      'Declined',
      'Passed'
    ].indexOf(value.label);
  });

  if (sortedGroups.length === 0) {
    return (
      <div className="px-6">
        There are no conversations.
      </div>
    );
  }

  return (
    <div className="px-6 pb-6 space-y-6">
      {sortedGroups.map(function(group, index) {
        return (
          <div key={index}>
            <div className="mb-2">
              {group.label}
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
              {group.conversations.map(function(conversation) {
                const educator = connect('user.byId', {
                  id: conversation.data.educatorId
                });

                const colorMap = {
                  'Invited': 'bg-blue-400',
                  'Interested': 'bg-yellow-400',
                  'Scheduling': 'bg-orange-400',
                  'Attending': 'bg-green-400'
                };

                const stageColor = colorMap[conversation.data.stage] || 'bg-red-400';

                return (
                  <div
                    key={conversation.id}
                    className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    onClick={() => onClick(conversation)}
                  >
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={educator.data.avatarUrl || avatar} alt="" />
                    </div>
                    <div className="flex-1 min-w-0">
                      <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">
                          {educator.data.firstName} {educator.data.lastName}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {conversation.data.stage}
                          <span className={`ml-2.5 ${stageColor} flex-shrink-0 inline-block h-2 w-2 rounded-full`}>
                            <span className="sr-only">{conversation.data.stage}</span>
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  );
}
