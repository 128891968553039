import React from 'react';
import TextField from '../TextField';
import Section from '../Section';

export default function About(props) {
  const { request } = props;

  return (
    <Section
      // title="Personal information"
      // subtitle="Contact info and demographics."
    >
      <TextField label="Desired outcome">
        {request.data.desiredOutcome || '-'}
      </TextField>
      <TextField label="Interaction description">
        {request.data.interactionDescription || '-'}
      </TextField>
    </Section>
  );
}
