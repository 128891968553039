import React from 'react';
import TextField from '../TextField';
import Section from '../Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import { PayloadStates } from '@lore/utils';
import EducatorSidePanel from '../../../explore-educators/SidePanel';
import PartnerSidePanel from '../../../explore-partners/SidePanel';
import RequestSidePanel from '../../../explore-requests/SidePanel';
import OfferSidePanel from '../../../explore-offers/SidePanel';
import InteractionSidePanel from '../../../explore-interactions/SidePanel';
import moment from 'moment-timezone';

function Loader() {
  return (
    <Section>
      <TextField label="Educator">
        ...
      </TextField>
      <TextField label="Partner">
        ...
      </TextField>
      <TextField label="Request">
        ...
      </TextField>
      <TextField label="Interaction">
        ...
      </TextField>
    </Section>
  );
}

export default function People(props) {
  const { conversation } = props;

  const connect = useConnect();
  const show = useDialog();

  const request_offer = conversation.data.requestId ? connect('request.byId', {
    id: conversation.data.requestId
  }) : connect('offer.byId', {
    id: conversation.data.offerId
  });

  const educator = connect('user.byId', {
    id: conversation.data.educatorId
  });

  const partner = connect('user.byId', {
    id: conversation.data.partnerId
  });

  if (
    request_offer.state === PayloadStates.FETCHING ||
    educator.state === PayloadStates.FETCHING ||
    partner.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const activity = connect('activity.byId', {
    id: request_offer.data.activityId
  });

  function onClickEducator() {
    show(
      <EducatorSidePanel user={educator} />
    );
  }

  function onClickPartner() {
    show(
      <PartnerSidePanel user={partner} />
    );
  }

  function onClickRequest() {
    show(
      <RequestSidePanel request={request_offer} />
    );
  }

  function onClickOffer() {
    show(
      <OfferSidePanel offer={request_offer} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Educator">
        <span onClick={onClickEducator} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {educator.data.firstName} {educator.data.lastName}
        </span>
      </TextField>
      <TextField label="Partner">
        <span onClick={onClickPartner} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {partner.data.firstName} {partner.data.lastName}
        </span>
      </TextField>
      {conversation.data.requestId ? (
        <TextField label="Request">
        <span onClick={onClickRequest} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {request_offer.data.title || activity.data.name}
        </span>
        </TextField>
      ) : (
        <TextField label="Offer">
        <span onClick={onClickOffer} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {request_offer.data.title || activity.data.name}
        </span>
        </TextField>
      )}
      <TextField label="Contact initiated by">
        {conversation.data.invited ? 'Educator' : 'Partner'}
      </TextField>
      <TextField label="Invite sent">
        {moment(conversation.data.createdAt).format('MMM Do, YYYY')}
      </TextField>
      <TextField label="Stage">
        {conversation.data.stage}
      </TextField>
    </Section>
  );
}
