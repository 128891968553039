import React, { Fragment, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import classNames from '../../utils/classNames';
import sidebarNavigation from '../_utils/primaryNavigationLinks';
import useRouter from '../../hooks/useRouter';
import { useUser } from '@lore/auth';
import avatar from '../../../assets/images/avatar.svg';
import { Menu, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';

export default function PrimaryMenuProfileItems(props) {
  const currentUser = useUser();

  const protocol = window.location.protocol;
  const host = window.location.host;
  const tokens = host.split('.');
  const subdomain = tokens[0];
  const tenant = subdomain.split('-')[0];
  const root = `${protocol}//${tenant}.${tokens[1]}.${tokens[2]}`

  const links = [
    // [
    //   { label: 'Switch to platform', to: `${root}` }
    // ],
    // [
    //   { label: 'View my profile', to: `${root}/users/${currentUser.id}` },
    //   { label: 'Edit my profile', to: `${root}/profile` },
    // ],
    [
      { label: 'Logout', to: `${root}/logout` }
    ]
  ];

  return links.map(function(container, index) {
    return (
      <div key={index} className="py-1">
        {container.map(function(link, index) {
          return (
            <Menu.Item key={index}>
              {({ active }) => (
                <a
                  href={link.to}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {link.label}
                </a>
              )}
            </Menu.Item>
          );
        })}
      </div>
    )
  });
}
