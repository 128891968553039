import React from 'react';
import { useConnect } from '@lore/connect';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';
import RequestSidePanel from '../../../explore-requests/SidePanel';
import { useDialog } from '@lore/dialogs';

export default function Requests(props) {
  const { user } = props;

  const connect = useConnect();

  const conversations = connect('conversation.find', {
    where: {
      eager: {
        $where: {
          partnerId: user.id,
          requestId: {
            $not: null
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  const show = useDialog();

  function onClick(request) {
    show(
      <RequestSidePanel request={request} />
    );
  }

  if (
    conversations.state === PayloadStates.FETCHING ||
    conversations.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Requests"
      subtitle="Past requests I’ve been involved with."
      className="bg-gray-50"
    >
      <div className="space-y-3">
        {conversations.data.map(function(conversation) {
          const request = connect('request.byId', {
            id: conversation.data.requestId
          });

          if (request.state === PayloadStates.FETCHING) {
            return (
              <div
                key={conversation.id}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                {/*<div className="flex-shrink-0">*/}
                {/*  <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />*/}
                {/*</div>*/}
                <div className="flex-1 min-w-0">
                  <a href="#" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">
                      ...
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      ...
                    </p>
                  </a>
                </div>
              </div>
            );
          }

          const activity = connect('activity.byId', {
            id: request.data.activityId
          });

          const colorMap = {
            'Invited': 'bg-blue-400',
            'Interested': 'bg-yellow-400',
            'Scheduling': 'bg-orange-400',
            'Attending': 'bg-green-400'
          };

          const stageColor = colorMap[conversation.data.stage] || 'bg-red-400';

          const byAdmin = (
            conversation.data.declinedByRole === 'Admin' ||
            conversation.data.passedByRole === 'Admin'
          );

          return (
            <div
              key={conversation.id}
              className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              onClick={() => onClick(request)}
            >
              {/*<div className="flex-shrink-0">*/}
              {/*  <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />*/}
              {/*</div>*/}
              <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {request.data.title || activity.data.name || '...'}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {conversation.data.stage}{byAdmin ? ' (by Admin)' : ''}
                    <span className={`ml-2.5 ${stageColor} flex-shrink-0 inline-block h-2 w-2 rounded-full`}>
                      <span className="sr-only">{conversation.data.stage}</span>
                    </span>
                  </p>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  )
}
