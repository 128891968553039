import React, { useState } from 'react';
import { parse, stringify } from 'query-string';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const conversations = connect('conversation.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfMessages',
            relation: 'messages',
            $where: {
              type: {
                $in: [
                  'Invitation',
                  'PartnerInterested',
                  'Message',
                  'PartnerDeclined',
                  'EducatorPassed'
                ]
              }
            }
          }
        ],
        $where: {
          numberOfMessages: {
            $lte: 1
          },
          requestId: {
            $not: null
          },
          invited: true
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const map1 = _.reduce(conversations.data, function(result, conversation) {
    const { partnerId } = conversation.data;
    result[partnerId] = result[partnerId] || [];
    result[partnerId].push(conversation);
    return result;
  }, {});

  const map2 = {};

  _.keys(map1).map(function(key) {
    const value = map1[key];
    if (value.length > 1) {
      map2[key] = value;
    }
  });

  return connect('user.find', {
    where: {
      eager: {
        $where: {
          id: {
            $in: _.keys(map2)
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });
}
