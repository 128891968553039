import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';

export default function OfferPublishedPartners(props) {
  const { account } = props;

  const accountQuery = account ? {
    accountId: account.id
  } : {};

  const users = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfOffers',
            relation: 'offers',
            $where: {
              'published': true,
              activityId: {
                $not: null
              },
              ...accountQuery
            }
          }
        ],
        $where: {
          numberOfOffers: {
            $gte: 1
          },
          isEducator: false,
          // ...REQUIRED_EDUCATOR_FIELDS
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Published an offer"
      stat={users.meta ? users.meta.totalCount : '...'}
      icon={UsersIcon}
    />
  );
}
