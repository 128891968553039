import React, { useEffect } from 'react';
import Auth0 from 'auth0-js';
import { useConfig } from '@lore/config';
import { Helmet } from 'react-helmet';

export default function Signup(props) {
  const config = useConfig();

  useEffect(() => {
    const auth0 = new Auth0.WebAuth(config.auth0);
    auth0.authorize({
      initial_screen: 'signUp'
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Signup</title>
      </Helmet>
      <div/>
    </>
  );
};
