import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), [
    'accounts',
    'frequencies',
    'locations',
    'school',
    'startDates',
    'statuses',
    'title'
  ], timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      <Tags
        type="Community"
        query={searchParams.accounts}
        models={connect('account.find', params)}
        onChange={onChangeArray('accounts', { location, history })}
        label={tag => tag.data.subdomain}
      />
      <Tags
        type="Frequency"
        query={searchParams.frequencies}
        models={mapArray([
          'One-Time',
          'Recurring'
        ])}
        onChange={onChangeArray('frequencies', { location, history })}
      />
      <Tags
        type="Location"
        query={searchParams.locations}
        models={mapArray([
          'In-Person',
          'Virtual'
        ])}
        onChange={onChangeArray('locations', { location, history })}
      />
      <Tag
        type="School"
        query={searchParams.school}
        onChange={onChange('school', { location, history })}
      />
      <Tags
        type="Start date"
        query={searchParams.startDates}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            [-4, 'Within past 1 month'],
            [-2, 'Within past 2 weeks'],
            [-1, 'Within past 1 week'],
            [1,  'Within 1 week'],
            [2,  'Within 2 weeks'],
            [4,  'Within 1 month']
          ].map(function(value) {
            return {
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('startDates', { location, history })}
      />
      <Tags
        type="Status"
        query={searchParams.statuses}
        models={mapArray([
          'Attending',
          'Pending'
        ])}
        onChange={onChangeArray('statuses', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
    </div>
  );
};
