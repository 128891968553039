import React from 'react';
import { useConnect } from '@lore/connect';
import TextField from '../TextField';
import Section from '../Section';
import { PayloadStates } from '@lore/utils';

export default function Guidances(props) {
  const { user } = props;

  const connect = useConnect();

  const userGuidances = connect('userGuidance.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (
    userGuidances.state === PayloadStates.FETCHING ||
    userGuidances.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Excited to advise about..."
    >
      <div>
        {userGuidances.data.map(function(userGuidance) {
          const guidance = connect('guidance.byId', {
            id: userGuidance.data.guidanceId
          });

          return (
            <TextField
              invert={true}
              showEmpty={true}
              key={userGuidance.id}
              label={guidance.data.name || '...'}
            >
              {userGuidance.data.description}
            </TextField>
          );
        })}
      </div>
    </Section>
  )
}
